////
/// Components / Viewport
///
/// @group Components
/// @author Heiko Pfefferkorn
////

[data-viewport] {
	position : relative;

	[data-viewport-layer] {
		background-color : rgba(color('white'), 0.95);
		height           : 100%;
		display          : block;
		left             : 0;
		position         : absolute;
		top              : 0;
		width            : 100%;
		z-index          : 10;

		&::after {
			@include font-size('s');

			animation   : rotate 2s linear infinite;
			content     : "\f110"; /* stylelint-disable-line string-quotes */
			font-family : 'Font Awesome 5 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
			font-size   : 2rem;
			font-weight : 300;
			height      : 1em;
			left        : 50%;
			line-height : 1;
			margin      : -0.5em 0 0 -0.5em;
			position    : absolute;
			top         : 50%;
			width       : 1em;
			z-index     : 11;
		}
	}

	&.-viewport-in [data-viewport-layer] {
		display : none;
	}
}

[data-viewport="in"] [data-viewport-layer] { /* stylelint-disable-line string-quotes */
	display : none;
}

// [data-viewport="out"] [data-viewport-layer] {
//	display : block;
// }
