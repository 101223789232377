////
/// Anwendungsweite Konfiguration.
///
/// Größenangaben mit Einheit haben innerhalb der Konfiguration immer per `px`
/// zu erfolgen!
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
/// @group config
////

/// Flag um bestimmte Zusatzdefinitionen gezielt zu integrieren, wegzulassen (Vererbung Bootstrap) oder anzupassen.</small>
///
/// @type bool
$bootstrap-enabled : true !default;

/// Relative oder absolute URL zum Verzeichnis was die benötigten Webresourcen beinhaltet.
///
/// @example scss - Bei Verwendung eines CDN
///   $base-url: 'http://cdn.example.com/assets/';
///
/// @type string
$base-url : '../' !default;

/// Relative URL, Basis ist `$base-url`, des Verzeichnisses was eigene Bilder beinhaltet.
///
/// @example scss
///   $base-url-vendors: 'images/';
///
/// @type string
$base-url-images : 'images/' !default;

/// Relative URL, Basis ist `$base-url`, des Verzeichnisse was eigene Schriften beinhaltet.
///
/// @example scss
///   $base-url-fonts: 'fonts/';
///
/// @type string
$base-url-fonts : 'fonts/' !default;

/// Relative URL, Basis ist `$base-url`, des Verzeichnisses was eigene Vendors beinhaltet.
///
/// @example scss
///   $base-url-vendors: 'vendors/';
///
/// @type string
$base-url-vendors : 'vendors/' !default;

/// Anführungszeichen bei URL-Angaben.
///
/// @example scss
///   $base-url-quotation: 'single';
///   // oder
///   $base-url-quotation: 'double';
///
/// @type string
$base-url-quotation : 'single' !default;

/// Basisschrifteinheit
///
/// @type length
$base-font-unit : rem !default; /* rem || em || px */

/// Wenn `$base-font-unit` = rem, dann REM-Fallback einbinden?
///
/// @type bool
$base-font-rem-fallback : false !default;

/// Basisschriftgröße
///
/// @type length
$base-font-size : 16px !default;

/// Basiszeilenhöhe basierend auf `$base-font-size`
///
/// @type Length
$base-line-height : 26px !default;

/// Schriftangaben bzgl. Mediaquerybreakpoints (`$breakpoints`)
///
/// @example scss
///   $base-font-sizing : (
///     'xl'  : (
///       'font-size'  : 18px
///     ),
///     'xxl'  : (
///       'font-size'  : 20px,
///       'line-height': 28px
///     )
///   );
///
/// @type map
/// @prop {string} keys
/// @prop {map} values - `font-size` und/oder `line-height`
$base-font-sizing : (
	// 'm' : (
	//	'font-size' : 15px
	// ),
	// 'l' : (
	//	'font-size' : 16px
	// )
) !default;

/// Basisschriftstil
///
/// @type length
$base-font-style : normal !default;

/// Basisschriftvariante
///
/// @type string
$base-font-variant : normal !default;

/// Basisschriftgewicht
///
/// @type length
$base-font-weight : 300 !default;

$outlineAccessibility : (
	'width'  : 2px,
	'style'  : solid,
	'color'  : 'primary',
	'offset' : -2px
);

/// Breakpoints
/// <small>_Im Gegensatz zu Bootstrap ('sm', 'md', 'lg') sind hier die Schlüssel
/// gekürzt!<br/>'s', 'm', 'l' (logischer nachvollziehbar da jeder die
/// Textilindustrie mit ihren Angaben kennt ;)._</small>
///
/// @type map
/// @prop {string} keys
/// @prop {length} values
$breakpoints : (
	'xxs'  : 0,
	'xs'   : 420px,
	's'    : 576px,
	'm'    : 768px,
	'ml'   : 992px,
	'l'    : 1024px,
	'xl'   : 1200px,
	'xxl'  : 1400px, // 1490px
) !default;

/// Margin und Padding auf 8-Punkt-Basis (nicht lineare Progression, exponentiell).
/// <br/>Der Schlüssel `m` ist gleichzusetzen mit `default`.
///
/// @link https://builttoadapt.io/intro-to-the-8-point-grid-system-d2573cde8632 Intro to The 8-Point Grid System (Build To Adapt)
/// @link https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62 Space in Design Systems (EightShapes)
/// @type map
/// @prop {string} keys
/// @prop {length} values
$spacing : (
	'xxxs' : 1px,
	'xxs'  : 2px,
	'xs'   : 4px,
	's'    : 8px,
	'm'    : 16px,
	'l'    : 32px,
	'xl'   : 64px,
	'xxl'  : 128px
) !default;

/// Umrechen der Angaben in `$spacing` in relative Werte.
///
/// @type bool
$spacing-relative : true !default;

/// Border-Radius auf 8-Punkt-Basis (nicht lineare Progression, exponentiell).<br/>
/// <small>_Der Schlüssel `m` ist gleichzusetzen mit `default`._</small>
///
/// @link https://builttoadapt.io/intro-to-the-8-point-grid-system-d2573cde8632 Intro to The 8-Point Grid System (Build To Adapt)
/// @link https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62 Space in Design Systems (EightShapes)
/// @type map
/// @prop {string} keys
/// @prop {length} values
$border-radius : (
	's'   : 0, // 2px
	'm'   : 0, // 4px
	'l'   : 0 // 8px
) !default;

$border-radius-relative : true !default;

/// Aspect Ratio.
///
/// @type map
/// @prop {string} keys
/// @prop {length} values
$aspect-ratio : (
	(16 9),
	(4 3),
	(3 4),
	(3 2),
	(2 3),
	(1 1)
);

/// Transitions
///
/// @type map
/// @prop {string} keys
/// @prop {string} values
$transitions : (
	'base'     : all 0.25s ease,
	'fade'     : opacity 0.35s ease,
	'collapse' : height 0.35s ease
) !default;

// -----------------------------------------------------------------------------
// @ Farbangaben
// -----------------------------------------------------------------------------

/// Basisfarbangaben.<br/>
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!_</small>
///
/// @type     map
/// @property {string} keys
/// @property {color | string} values
$colors-base : (
	'black'     : #000,
	'white'     : #fff,
	'text'      : #2e2c2d,
	'even'      : 'black-6',
	'odd'       : 'black-7',
	'primary'   : #e55f5f,
	'secondary' : #2e2c2d,
	'tertiary'  : 'secondary'
) !default;

/// Statusfarbangaben.<br/>
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!_</small>
///
/// @type     map
/// @property {string} keys
/// @property {color | string} values
$colors-state : (
	'danger'  : #c72b29, // #d8413e (low contrast with bg color #f0f0f0)
	'info'    : #8ac2d1,
	'success' : #318735, // #37993c (low contrast with font color #ffffff)
	'warning' : #eec557
) !default;

/// ´Custom´-Farbangaben.<br/>
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!_</small>
///
/// @type     map
/// @property {string} keys
/// @property {color | string} values
$colors-custom : (
	'grey-light'        : #f5f5f5,
	'grey'              : #dbdbdb,
	'grey-dark'         : #7f7f7f,
	'grey-darker'       : #575757,
	'selection-bg-color': 'secondary',
	'selection-color'   : 'white'
) !default;

/// Farbenauszüge per `lighten()` in bestimmten Schritte generieren.<br/>
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!<br/>_</small>
/// [ident] [color] [step] (Zugriff per `color('black-[5|10|15]')`)
///
/// @type     map
/// @property {string} keys
/// @property {map | string} values
$colors-graduate : (
	'black' : ('black', 10)
) !default;

// -----------------------------------------------------------------------------
// @ Seitenangaben
// -----------------------------------------------------------------------------

/// Seitenbreite.
///
/// @type length
$page-width : 100% !default;

/// Maximale Seitenbreite.
///
/// @type length
$page-max-width : 1600px !default; /* 1680px, 1440px */

/// Seitenhintergrundfarbe.<br/>
/// <small>_Wenn `String`, dann bezieht sich diese Angabe auf einen Schlüssel in
/// der globalen Variable `$colors`!_</small>
///
/// @type color | string
$page-bg-color : 'white' !default;

/// Seitenkopf sticky?
///
/// @type bool
$page-sticky-header : true !default;

/// Seitenfuss sticky?
/// TODO: `$page-sticky-footer`, Nutzung per Konfig. prüfen
///
/// @type bool
// $page-sticky-footer : true !default;

/// Breite Randspalte(n).
///
/// @type length
// $page-aside-width : 30% !default;

/// Textfarbe für `<body/>`
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!_</small>
///
/// @type color
$body-bg-color : 'white' !default;

/// Hintergrundfarbe für `<body/>`
/// <small>_Wertangaben als String beziehen sich auf die globale Variable
/// `$colors` (Map aller Farben) und werden beim Farbabruf mit `color()`
/// dynamisch durch den entsprechenden Mapeintrag ersetzt!_</small>
///
/// @type color
$body-color : 'black' !default;

// Seitenlogo ------------------------------------------------------------------

/// Dateiname des Logo´s
/// <small>_Bild-Src bezieht sich hierbei auf `$base-url-images`!_</small>
///
/// @type string
$page-logo-path : 'logo.svg' !default;

/// Originalhöhe des Logo´s
/// <small>_Wird für flexible Größendarstellung zur Berechnung benötigt!_</small>
///
/// @type length
$page-logo-height : 176px !default;

/// Originalbreite des Logo´s
/// <small>_Wird für flexible Größendarstellung zur Berechnung benötigt!_</small>
///
/// @type length
$page-logo-width  : 500px !default;

// -----------------------------------------------------------------------------
// @ Linkstandard
// -----------------------------------------------------------------------------

/// Link
///
/// @type     map
/// @property {color | string} <status>.color [siehe oben] - Key aus `$colors-...` möglich
/// @property {string} <status>.text-decoration [siehe oben]
/// @property {map} <status>.on-event [siehe oben] - `:hover, :active, :focus` etc.
$link-color                    : inherit !default;
$link-text-decoration          : underline !default;
$link-hover-color              : inherit !default;
$link-hover-text-decoration    : underline !default;
$link-disabled-color           : 'black' !default;
$link-disabled-text-decoration : line-through !default;

// -----------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------

/// Schriftfamilien.
///
/// @type     map
/// @property {string} keys
/// @property {string} values
$font-stack : (
	'text'      : "'Ubuntu', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
	'heading'   : "'Open Sans', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
	'monospace' : "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
) !default;

/// Schriftgrößen<br/>
/// <small>_Angabe von verschiedenen Schriftgrößen bzgl. Typografie.
/// Die Schlüssel sind hierbei frei wählbar, sollten jedoch selbsterklärend
/// sein._</small>
///
/// @type map
/// @prop {string} keys
/// @prop {length} values
$font-size : (
	'xxs' : 12px,
	'xs'  : 14px,
	's'   : 14px,
	// 'm'   : 16px,
	'l'   : 18px,
	'xl'  : 24px
	// 'xxl' : 0,
	// 'xxl'  : 20px,
) !default;

/// Schriftgewichtungen.
///
/// @type     map
/// @property {string} keys
/// @property {length | string} values
$font-weight : (
	'lighter' : 300,
	'light'   : 300,
	'normal'  : 400,
	'bold'    : 700,
	'bolder'  : 700
) !default;

// Überschriften ---------------------------------------------------------------

/// Key aus `$colors(-...)` möglich.
///
/// @type {color | string}
$heading-color : currentColor !default;

/// Key aus `$font-stack` möglich.
///
/// @type {string}
$heading-font-family : 'heading' !default;

/// Key aus `$font-size` möglich.
///
/// @type {length}
$heading-font-size : 16px !default;

/// Key aus `$font-weight` möglich.
///
/// @type {string}
$heading-font-weight : 'light' !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h1-font-size : 28px !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h2-font-size : 22px !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h3-font-size : 18px !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h4-font-size : null !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h5-font-size : null !default;

/// Key aus `$font-size` möglich.<br/><small>_`null` für nicht verwendet, automatische Vererbung seitens `$heading-...`!_</small>
///
/// @type {length | string}
$h6-font-size : null !default;

// Emphasis --------------------------------------------------------------------

/// Key aus `$font-size` möglich.
///
/// @type {length | string}
$small-font-size: 's' !default;

/// Key aus `$colors(-...)` möglich (*1).
///
/// @type {color | string}
$mark-bg-color: #eee679 !default;

/// Key aus `$colors(-...)` möglich (*1).
///
/// @type {color | string}
$mark-color: 'text' !default;

// Horizontal rule -------------------------------------------------------------

/// @type {color | string} - Key aus `$colors(-...)` möglich
$hr-border-color : 'black' !default;

/// @type {length | string}
$hr-border-width : 1px !default;

/// @type {length | string} - Key aus `$spacing` möglich
$hr-margin : 'm' !default;

// Absatz ----------------------------------------------------------------------

/// @type {length | string} - Key aus `$spacing` möglich
$paragraph-margin : 'l' !default;

// Blockquote ------------------------------------------------------------------

/// @type {length | string} - Key aus `$font-size` möglich
$blockquote-font-size : 18px !default;

/// @type {length | string} - Key aus `$spacing` möglich
$blockquote-margin : 'm' !default;

// Code ------------------------------------------------------------------------

/// @type {color | string} - Key aus `$colors(-...)` möglich
$code-bg-color : 'info';

/// @type {color | string} - Key aus `$colors(-...)` möglich
$code-color : 'white' !default;

/// @type {length | string} - Key aus `$font-size` möglich
$code-font-size : 'xs' !default;

/// @type {color | string} - Key aus `$colors(-...)` möglich
$pre-bg-color : 'black-10' !default;

/// @type {color | string} - Key aus `$colors(-...)` möglich
$pre-color : 'black-60' !default;

/// @type {length | string} - Key aus `$font-size` möglich
$pre-font-size : 'xs' !default;

/// @type {length}
$pre-scrollable-max-height : 320px !default;

// List ------------------------------------------------------------------------

/// @type {length | string} - Key aus`$spacing` möglich.
$list-margin-x : 20px !default;

/// @type {length | string} - Key aus`$spacing` möglich
$list-margin-y : 'm' !default;

// -----------------------------------------------------------------------------
// @ Komponenten (Standardkonfigurationen)
// -----------------------------------------------------------------------------

/// Festlegung von zu integrierenden Komponentendefinitionen.<br/>
/// <small>_Nur hier aufgelistete Komponenten sind global deaktivierbar (CSS wird nicht integriert). Zu jeder der nachfolgenden Komponenten gibt es eine Variablendeklaration `$c-[name]-...`. Eine separate Auflistung aller verfügbaren Komponentenvariablen steht an dieser Stelle nicht zu Verfügung!_</small>
///
/// <small>Legende der Variablenkennzeichnungen:<br/>
/// (\*1) optional, auf Vorhandensein wird separat geprüft<br/>
/// (\*2) Key aus Map [`$border-radius`](#config-variable-border-radius) kann verwendet werden<br/>
/// (\*3) Key aus Map [`$breakpoints`](#config-variable-breakpoints) kann verwendet werden<br/>
/// (\*4) Key aus globaler Map `$colors` (siehe [`$colors-base`](#config-variable-colors-base), `$colors-...`) kann verwendet werden<br/>
/// (\*5) Key aus Map [`$font-stack`](#config-variable-font-stack) kann verwendet werden<br/>
/// (\*6) Key aus Map [`$font-size`](#config-variable-font-size) kann verwendet werden<br/>
/// (\*7) Key aus Map [`$font-weight`](#config-variable-font-weight) kann verwendet werden<br/>
/// (\*8) Key aus Map [`$spacing`](#config-variable-spacing) kann verwendet werden</small>
///
/// @group    components
/// @type     map
/// @property {string} keys - Schlüssel der Komponente
/// @property {bool} values - On/Off
$components-in-use: (
	'accordion'     : true,
	'alert'         : true,
	'anchors'       : true,
	'breadcrumb'    : true,
	'button'        : true,
	'card'          : true,
	'contact'       : true,
	'deck'          : true,
	'dropdown'      : true,
	'figure'        : true,
	'filelist'      : true,
	'form'          : true,
	'grid'          : true,
	'header'        : true,
	'image'         : true,
	'input'         : true,
	'login'         : true,
	'media'         : true,
	'nav'           : true,
	'overlay-panel' : true,
	'page-blocker'  : true,
	'pagination'    : true,
	'publication'   : true,
	'slider'        : true,
	'tab'           : true,
	'textblock'     : true,
	'top-of-page'   : true
) !default;

/// Komponentenstandard `border-radius`.
///
/// @type     map
/// @property {string} keys
/// @property {string} values - Key aus`$border-radius` möglich
$components-border-radius: (
	's' : 's',
	'm' : 'm',
	'l' : 'l',
) !default; // (*2)

/// Komponentenstandard, `.disabled, :disabled`.
///
/// @type length
$components-disabled-opacity : 0.4 !default;

/// Komponentenstandard, `box-shadow`.
///
/// @type bool
$components-use-boxshadow: false !default;

// Alert (* optional) ----------------------------------------------------------

// $alert-font-family   : 'secondary' !default; // (*1, *5)
// $alert-font-size     : 16px !default; // (*1, *6)
// $alert-font-weight   : 'normal' !default; // (*1, *7)
$alert-variant       : (
	'primary': (
		'bg'          : 'primary',
		'color'       : 'white',
		'border-color': null
	),
	'secondary': (
		'bg'           : 'secondary',
		'color'        : 'white',
		'border-color' : null
	),
	'success': (
		'bg'           : 'success',
		'color'        : 'white',
		'border-color' : null
	),
	'info': (
		'bg'           : 'info',
		'color'        : 'white',
		'border-color' : null
	),
	'warning': (
		'bg'           : 'warning',
		'color'        : 'white',
		'border-color' : null
	),
	'danger': (
		'bg'           : 'danger',
		'color'        : 'white',
		'border-color' : null
	)
) !default;  // (*1, *4)

// Breadcrumb ------------------------------------------------------------------

$breadcrumb-color         : currentColor !default; // (*4)
$breadcrumb-hover-color   : currentColor !default; // (*4)
$breadcrumb-current-color : 'primary' !default; // (*4)

// Pagination ------------------------------------------------------------------

$pagination-color        : 'tertiary' !default; // (*4)
$pagination-hover-color  : 'black' !default; // (*4)
$pagination-current-color : 'primary' !default; // (*4)

// Button ----------------------------------------------------------------------

$c-button-border-radius : 'm' !default; // (*2)
$c-button-border-width  : 0 !default;
$c-button-font-family   : 'secondary' !default; // (*1, *5)
$c-button-font-size     : 16px !default; // (*1, *6)
$c-button-font-weight   : 'bold' !default; // (*1, *7)
$c-button-line-height   : 22px !default; // (*1)
$c-button-padding-x     : 14px !default; // (*8)
$c-button-padding-y     : 14px !default; // (*8)

$c-button-font-sizing : (
	'xs': 14px,
	's' : 14px,
	'l' : 18px
) !default; // (*1, *3)

$c-button-variant : (
	'primary': (
		'bg'          : 'black',
		'color'       : 'white',
		'border-color': null,
		'on-hover'    : (
			'bg'           : 'black',
			'border-color' : null,
			'color'        : null
		),
		'on-active' : (
			'bg'           : 'black',
			'border-color' : null,
			'color'        : null
		)
	),
	'secondary': (
		'bg'           : 'secondary',
		'color'        : 'white',
		'border-color' : null,
		'on-hover'     : (
			'bg'           : null,
			'border-color' : null,
			'color'        : null
		)
	),
	'success': (
		'bg'           : 'success',
		'color'        : 'white',
		'border-color' : null,
		'on-hover'     : (
			'bg'           : null,
			'border-color' : null,
			'color'        : 'white'
		)
	),
	'info': (
		'bg'           : 'info',
		'color'        : 'white',
		'border-color' : null,
		'on-hover'     : (
			'bg'           : null,
			'border-color' : null,
			'color'        : 'white'
		)
	),
	'warning': (
		'bg'           : 'warning',
		'color'        : 'white',
		'border-color' : null,
		'on-hover'     : (
			'bg'           : null,
			'border-color' : null,
			'color'        : 'white'
		)
	),
	'danger': (
		'bg'           : 'danger',
		'color'        : 'white',
		'border-color' : null,
		'on-hover'     : (
			'bg'           : null,
			'border-color' : null,
			'color'        : 'white'
		)
	)
) !default; // (*4)

// Collapse --------------------------------------------------------------------

$c-collapse-toggle-bg     : transparent !default; // (*4)
$c-collapse-toggle-color  : 'black' !default; // (*4)
$c-collapse-font-weight   : 'normal' !default; // (*1, *7)

// Dropdown --------------------------------------------------------------------

$c-dropdown-bg            : 'white' !default; // (*4)
$c-dropdown-border-color  : 'black' !default; // (*4)
$c-dropdown-border-radius : 'm' !default; // (*2)
$c-dropdown-border-width  : 1px !default;
$c-dropdown-font-size     : 's' !default; // (*6)
$c-dropdown-font-family   : 'secondary' !default; // (*4)
$c-dropdown-font-weight   : 'normal' !default; // (*7)
$c-dropdown-padding-x     : 'm' !default; // (*8)
$c-dropdown-padding-y     : 'xs' !default; // (*8)
$c-dropdown-min-width     : 160px !default;

$c-dropdown-link-color          : 'black' !default; // (*4)
$c-dropdown-link-hover-color    : 'black' !default; // (*4)
$c-dropdown-link-hover-bg       : 'black' !default; // (*4)
$c-dropdown-link-active-color   : 'black' !default; // (*4)
$c-dropdown-link-active-bg      : 'tertiary' !default; // (*4)
$c-dropdown-link-disabled-color : 'black' !default; // (*4)

// Grid ------------------------------------------------------------------------

$c-grid-gutter : 'm' !default; // (*8)

// Form ------------------------------------------------------------------------



$input-placeholder-color : 'black-30'; // (*1, *4)

$c-input-bg            : 'white' !default; // (*4)
$c-input-border-color  : 'black' !default; // (*4)
$c-input-border-radius : 'm' !default; // (*2)
$c-input-border-width  : 1px !default;
$c-input-color         : 'black' !default; // (*4)
// $c-input-font-family   : 'secondary' !default; // (*1, *5)
// $c-input-font-size     : 's' !default; // (*1, *6)
$c-input-font-weight   : 'normal' !default; // (*1, *7)
$c-input-line-height   : 26px !default;
$c-input-padding-x     : 14px !default; // (*8)
$c-input-padding-y     : 12px !default; // (*8)

$c-input-focus-bg           : 'white' !default; // (*4)
$c-input-focus-border-color : 'primary' !default; // (*4)
$c-input-focus-color        : 'black' !default; // (*4)

$c-input-font-sizing : (
	// 's': 14px,
	// 'l': 18px
) !default; // (*1, *5)

$c-textarea-min-height : 128px !default;

//
// Label
//

$label-color     : 'grey-dark' !default; // (*4)
$label-font-size : 's' !default; // (*1, *6)

//
// Legend
//

$legend-color     : 'grey-dark' !default; // (*1, *4)
$legend-font-size : 's' !default; // (*1, *6)

//
// Form control (Input, Select)
//

$form-control-bg-color     : 'white' !default; // (*4)
$form-control-border-color : 'grey' !default; // (*4)
$form-control-color        : 'black' !default; // (*4)
// $form-control-font-size    : '' !default; // (*1, *6)
// $form-control-font-weight  : '' !default; // (*1, *7)

$form-control-focus-bg-color     : 'white' !default; // (*4)
$form-control-focus-border-color : 'primary' !default; // (*4)
$form-control-focus-color        : 'black' !default; // (*4)

$form-control-disabled-color        : 'grey' !default; // (*4)
$form-control-disabled-bg-color     : 'grey-light' !default; // (*4)
$form-control-disabled-border-color : 'grey' !default; // (*4)

$form-control-placeholder-color : 'black-30'; // (*4)

//
// Select
//

$form-select-bg-color     : 'white' !default; // (*4)
$form-select-border-color : 'grey' !default; // (*4)
$form-select-color        : 'black' !default; // (*4)
// $form-select-font-size    : '' !default; // (*1, *6)
// $form-select-font-weight  : '' !default; // (*1, *7)

$form-select-focus-bg-color     : 'white' !default; // (*4)
$form-select-focus-border-color : 'primary' !default; // (*4)
$form-select-focus-color        : 'black' !default; // (*4)

$form-select-disabled-color        : 'grey' !default; // (*4)
$form-select-disabled-bg-color     : 'grey-light' !default; // (*4)
$form-select-disabled-border-color : 'grey' !default; // (*4)

//
// Radiobutton
//

$use-radiobutton : true; // Definitionen integrieren?

//
// Checkbox
//

$use-checkbox : true; // Definitionen integrieren?

//
// Input
//

$use-input : true; // Definitionen integrieren?



//
// Textarea
//

$use-textarea : true; // Definitionen integrieren?

// Figure ----------------------------------------------------------------------

$c-figure-caption-color        : 'black' !default; // (*4)
$c-figure-caption-font-family : 'secondary' !default; // (*1, *5)
$c-figure-caption-font-size    : 'xs' !default; // (*1, *6)

// Header ----------------------------------------------------------------------

$c-header-margin             : 'm' !default; // (*8)
$c-header-title-margin       : 0 !default; // (*1, *8)
// $c-header-title-font-size    :  !default; // (*1, *6)
$c-header-subtitle-font-size : 0 !default; // (*1, *6)

// Card ------------------------------------------------------------------------

$c-card-font-family : 'secondary' !default; // (*1, *5)
$c-card-font-size   : 'xs' !default; // (*1, *6)
$c-card-thumb-size  : 74px !default;

// Dateiliste ------------------------------------------------------------------

$c-filelist-font-family : 'secondary' !default; // (*1, *5)
$c-filelist-font-size   : 'xs' !default; // (*1, *6)

// Media -----------------------------------------------------------------------

$c-media-font-family : 'secondary' !default; // (*1, *5)
$c-media-font-size   : 's' !default; // (*1, *6)
$c-media-thumb-size  : 80px !default;

// Nav -------------------------------------------------------------------------

$nav-color          : currentColor !default; // (*4)
$nav-hover-color    : 'black' !default; // (*4)
$nav-active-color   : 'primary' !default; // (*4)
$nav-disabled-color : 'black-20' !default; // (*4)

// Overlay-Panel ----------------------------------------------------------------

$c-overlaypanel-bg       : 'white' !default;
$c-overlaypanel-duration : 0.35s !default;
$c-overlaypanel-width    : 450px !default;
$c-overlaypanel-zindex   : 122 !default;

// Page Blocker ----------------------------------------------------------------

$c-pageblocker-bg      : 'primary' !default; // (*4)
$c-pageblocker-opacity : 0.7 !default;
$c-pageblocker-zindex  : 120 !default;

// Textblock -------------------------------------------------------------------

$textblock-media-width : 58%;
$textblock-gutter      : 'l';

// Tab -------------------------------------------------------------------------

$c-tab-toggle-bg     : 'tertiary' !default; // (*4)
$c-tab-toggle-color  : 'black' !default; // (*4)
$c-tab-font-size     : 's' !default; // (*1, *6)
$c-tab-font-family   : 'secondary' !default; // (*1, *4)
$c-tab-font-weight   : 'normal' !default; // (*1, *7)

// (Komponente _Headings_) Gewichtungsbezogene, auf den Standardeigenschaften
// von `$headings` aufbauende, abweichende Eigenschaften von Überschriften.
//
// <small>Auskommentieren der Map `$c-headings`, einzelner oder aller
// Eigenschaften verhindert die entsprechende Integration (CSS-Vererbung tritt
// in Kraft)!<br/>
// **Prüfung auf Integrierung von Eigenschaften vorhanden (siehe ´Map structure´)!**</small>
//
// @group    components
// @type     map
// @property {color | string} <level>.color [siehe oben] - Key aus `$colors-...` möglich
// @property {string} <level>.font-family [siehe oben] - Key aus `$font-family` möglich
// @property {length} <level>.font-size [siehe oben] - in `px`
// @property {length | string} <level>.font-weight [siehe oben] - Key aus `$font-weight` möglich
// @property {length} <level>.line-height [siehe oben] - in `px`
// @property {length | string} <level>.margin [siehe oben] - in `px`, Key aus`$spacing` möglich
// $c-headings : (
// 	'h1' : (
// 		'color'     : 'primary',
// 		'font-size' : 32px
// 	),
// 	'h2' : (
// 		'font-size' : 24px
// 	),
// 	'h3' : (
// 		'font-size' : 22px
// 	)
// ) !default;

/// Komponente ´Collapse´ - Auskommentieren der Map `$c-collapse`, einzelner
/// oder aller Eigenschaften verhindert die entsprechende Integration
/// (Vererbung tritt in Kraft!).
///
/// **Prüfung auf Integrierung für:**
///
/// * color
/// * background-color
/// * font-family
///
/// @group    components
/// @type     map
/// @property {string} keys
/// @property {string | length | map} values
$c-collapse : (
	'background-color' : transparent,
	'color'            : 'black',
	'font-family'      : 'heading',
	'on'               : (
		'hover' : (
			'color'            : 'black',
			// 'background-color' : 'black'
		),
		'active': (
			'color'            : 'primary',
			'background-color' : 'white'
		)
	)
) !default;

/// Komponente ´Brands´
///
/// @group    components
/// @type     map
/// @property {string} keys
/// @property {map} values
$c-brands : (
	'image'  : 'logo.svg',
	'height' : 512px,
	'width'  : 512px
) !default;

// -----------------------------------------------------------------------------
// @ Custom (under development)
// -----------------------------------------------------------------------------

/// Vom Standard abweichende, projektbezogene, Konfigurationen!
///
/// @type     map
/// @property {string} keys
/// @property {map} values
$custom : (
	'color-primaries' : (
		'100' : 'primary'
	),
	'color-secondaries' : (
		'100' : 'secondary'
	)
) !default;

// -----------------------------------------------------------------------------
// @ Global
// Finger weg wenn man nicht genau weiß was man macht ;)!!!
// -----------------------------------------------------------------------------

/// Map mit sämtlichen Farbdefinitionen.<br/>
/// <small>_Nicht modifizieren ... wird zur Laufzeit automatisch
/// zusammengestellt/aktualisiert! Zugriff darauf mit `color('key')`_</small>
///
/// @since  1.0.0
/// @group  config
/// @type   map
$global-colors : () !global;

/// Map mit Angaben zur Bereitstellung in `:root`.<br/>
/// <small>_Nicht modifizieren ... wird zur Laufzeit automatisch
/// zusammengestellt/aktualisiert!_</small>
///
/// @since  1.0.0
/// @group  config
/// @type   map
$global-root : () !global;
