////
/// Components / ogin
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'login') {
	//
	// Loginformular
	//

	.login-form {
		display      : block;
		margin-left  : auto;
		margin-right : auto;
		max-width    : 50rem;

		input[type="text"],
		input[type="password"] {
			@include font-size('l');

			background-color : color('white');
			border           : 2px solid color('black-10');
			border-radius    : 0;
			color            : color('secondary');

			@include on-hover-focus() {
				background-color : transparent;
			}
		}

		label {
			@include font-size('s');

			display : block;
			padding : 0;
		}

		button {
			border-radius : 0;
		}

		&__inner {
			display               : grid;
			grid-template-areas   : "user" "pass" "perma" "submit" "forgot";
			grid-template-columns : auto;
			grid-template-rows    : auto auto auto auto auto;

			> * {
				align-self : center;
				margin     : 0;

				+ * {
					margin-top : 1rem;
				}
			}
		}

		&__u {
			grid-area : user;
		}

		&__p {
			grid-area : pass;
		}

		&__forgot {
			grid-area  : forgot;
			text-align : center;
		}

		&__perma {
			grid-area  : perma;
			text-align : center;
		}

		&__submit {
			grid-area  : submit;
			text-align : right;

			> button {
				display    : block;
				text-align : center;
				width      : 100%;
			}
		}

		@include media-breakpoint-up('m') {
			&__inner {
				grid-template-areas   : "user user pass pass" "forgot perma perma submit";
				grid-template-columns : 1fr auto auto 1fr;
				grid-template-rows    : auto auto;

				> * {
					margin-top : 0;
				}
			}

			&__u,
			&__p {
				margin-bottom : 1rem;
			}

			&__u {
				margin-right : 1rem;
			}

			&__p {
				margin-left : 1rem;
			}

			&__forgot {
				text-align : left;
			}

			&__perma {
				text-align : left;
			}

			&__submit {
				text-align : right;

				> button {
					display : inline-block;
					width   : auto;
				}
			}
		}
	}

	//
	// Logoutformular
	//

	.logout-form {
		display      : block;
		margin-left  : auto;
		margin-right : auto;
		max-width    : 50rem;

		button {
			display    : block;
			text-align : center;
			width      : 100%;
		}
	}
}
