////
/// Components / Active Filters
///
/// @group Components
/// @author Heiko Pfefferkorn
////

[data-ajax-content] {
	> * {
		transition: opacity 0.125s;
	}

	&.-is-waiting {
		position : relative;

		> *:not(nav) {
			opacity : 0.25;
		}

		&::before {
			content  : ""; /* stylelint-disable-line string-quotes */
			display  : block;
			height   : 100%;
			left     : 0;
			position : absolute;
			top      : 0;
			width    : 100%;
			z-index  : 4;
		}

		&::after {
			animation     : rotate 2s linear infinite;
			border-radius : 50%;
			// Dots ´16´ per Box-Shadow (4 je ´oben nach rechts´, ´rechts nach unten´, ´unten nach links´, ´links nach oben´)
			// box-shadow    : 0 -3em 0 0, 1.125em -2.75em 0 0, 2.125em -2.125em 0 0, 2.75em -1.125em 0 0, 3em 0 0 0, 2.75em 1.125em 0 0, 2.125em 2.125em 0 0, 1.125em 2.75em 0 0, 0 3em 0 0, -1.125em 2.75em 0 0, -2.125em 2.125em 0 0, -2.75em 1.125em 0 0, -3em 0 0 0, -2.75em -1.125em 0 0, -2.125em -2.125em 0 0, -1.125em -2.75em 0 0;
			// Dots ´12´ per Box-Shadow (3 je ´oben nach rechts´, ´rechts nach unten´, ´unten nach links´, ´links nach oben´)
			//box-shadow : 0 -3em 0 0, 1.5em -2.5em 0 0, 2.5em -1.5em 0 0, 3em 0 0 0, 2.5em 1.5em 0 0, 1.5em 2.5em 0 0, 0 3em 0 0, -1.5em 2.5em 0 0, -2.5em 1.5em 0 0, -3em 0 0 0, -2.5em -1.5em 0 0, -1.5em -2.5em 0 0;
			// Dots ´8´ per Box-Shadow (2 je ´oben nach rechts´, ´rechts nach unten´, ´unten nach links´, ´links nach oben´)
			box-shadow    : 0 -1.125em 0 0, 0.8125em -0.8125em 0 0, 1.125em 0 0 0, 0.8125em 0.8125em 0 0, 0 1.125em 0 0, -0.8125em 0.8125em 0 0, -1.125em 0 0 0, -0.8125em -0.81255em 0 0;
			color         : color('primary-2');
			content       : ""; /* stylelint-disable-line string-quotes */
			font-size     : 0.75rem;
			height        : 0.5em;
			left          : 50%;
			margin        : -0.25em auto auto -0.25em;
			position      : absolute;
			text-indent   : -9999em;
			bottom        : 1rem;
			transform     : translateZ(0);
			width         : 0.5em;
			z-index       : 5;
		}
	}
}
