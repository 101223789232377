////
/// Mixins / Miscellaneous
////

/// Kontextbasierten Selektor etwas vereinfachen ;).
///
/// @example scss
///   .container {
///     display : flex;
///
///     @include when-inside('.no-flexbox') {
///       display: table;
///     }
///   }
///
/// @example css - Result
///   .container {
///     display : flex;
///   }
///
///   .no-flexbox .container {
///     display : table;
///   }
///
/// @author    [Hugo Giraudel](https://hugogiraudel.com/)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {String | List} $context
@mixin when-inside($context) {
	#{$context} & {
		@content;
	}
}

/// Event `:hover`
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-hover() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-hover(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:hover,
///   .element-a.-hover{
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:hover,
///   .element-b.-hover{
///     color: #eee;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-hover($self: false) {
	@if $self {
		&,
		&:hover,
		&.-hover {
			@content;
		}
	} @else {
		&:hover,
		&.-hover {
			@content;
		}
	}
}

/// Event `:active`
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-active() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-active(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:active,
///   .element-b.-active {
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:active,
///   .element-b.-active {
///     color: #eee;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-active($self: false) {
	@if $self {
		&,
		&:active,
		&.-active {
			@content;
		}
	} @else {
		&:active,
		&.-active {
			@content;
		}
	}
}

/// Event `:focus`
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-focus() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-focus(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:focus,
///   .element-a.-focus {
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:focus,
///   .element-b.-focus {
///     color: #eee;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-focus($self: false) {
	@if $self {
		&,
		&:focus,
		&.-focus {
			@content;
		}
	} @else {
		&:focus,
		&.-focus {
			@content;
		}
	}
}

/// Event `:hover, :focus`
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-hover-focus() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-hover-focus(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:hover,
///   .element-a.-hover,
///   .element-a:focus,
///   .element-a.-focus {
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:hover,
///   .element-b.-hover,
///   .element-b:focus,
///   .element-b.-focus {
///     color: #eee;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-hover-focus($self: false) {
	@if $self {
		&,
		&:hover,
		&.-hover,
		&.-focus {
			@content;
		}
	} @else {
		&:hover,
		&.-hover,
		&:focus,
		&.-focus {
			@content;
		}
	}
}

/// Event wrapper
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-event() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-event(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:hover,
///   .element-a.-hover,
///   .element-a:active,
///   .element-a.-active,
///   .element-a:focus,
///   .element-a.-focus {
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:hover,
///   .element-b.-hover,
///   .element-b:active,
///   .element-b.-active,
///   .element-b:focus,
///   .element-b.-focus {
///     color: #eee;
///   }
///
/// @author    Harry Roberts
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @link      https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&.-hover,
		&:active,
		&.-active,
		&:focus,
		&.-focus {
			@content;
		}
	} @else {
		&:hover,
		&.-hover,
		&:active,
		&.-active,
		&:focus,
		&.-focus {
			@content;
		}
	}
}

/// Event `:focus`
///
/// @example scss
///   .element-a {
///     color : #ccc;
///
///     @include on-disabled() {
///       color : #000;
///     }
///   }
///
///   .element-b {
///     @include on-disabled(true) {
///       color : #eee;
///     }
///   }
///
/// @example css - Result
///   .element-a {
///     color : #ccc;
///   }
///
///   .element-a:disabled,
///   .element-a.-disabled {
///     color: #000;
///   }
///
///   .element-b,
///   .element-b:disabled,
///   .element-b.-disabled {
///     color: #eee;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $self [false] - aktuellen Selektor mit einbeziehen
@mixin on-disabled($self: false) {
	@if $self {
		&,
		&:disabled,
		&.-disabled {
			@content;
		}
	} @else {
		&:disabled,
		&.-disabled {
			@content;
		}
	}
}
