////
/// Placeholders / Presentations
////

/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @require {mixin} clearfix
%clearfix {
	@include clearfix($extend : false);
}

/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @require {mixin} visually-hidden
%visually-hidden {
	@include visually-hidden($extend : false);
}
