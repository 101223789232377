////
/// Components / Header
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'header') {
	//
	// Standard.
	//

	.header {
		@include margin(0 auto);

		$_self : &;

		color : color('secondary');


		&:not(:last-child) {
			margin-bottom : get-spacing('l');
		}

		> * {
			color         : currentColor;
			margin-bottom : 0;

			+ * {
				@include margin('top', get-spacing('m'));
			}
		}

		// Titel
		&__title {
			// Außenabstand
			@if variable-exists(c-header-title-margin) {
				@include margin(bottom, get-spacing($c-header-title-margin));
			}
		}

		// Untertitel
		&__subtitle {
			@include font-size('l');
			@include font-weight('bold');
		}

		@include media-breakpoint-up('xxl') {
			max-width  : none;
			text-align : left;
		}
	}
}
