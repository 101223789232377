////
/// Components / Form / Legend
///
/// @group Components
/// @author Heiko Pfefferkorn
////

legend {
	@if variable-exists(legend-font-size) {
		font-size : em($legend-font-size, $base-font-size);
	}

	@if variable-exists(legend-color) {
		color : color($legend-color);
	}

	// @include font-weight('bold');
	// @include margin(0 0 get-spacing('s'));
}
