////
/// Module / Account
///
/// @group Components
/// @author Heiko Pfefferkorn
////

//
// Account Navigation
//

.account-nav {
	a.active {
		color : color('primary');
	}
}

//
// Artikel
//

.account .article {
	//
	// Handling der Accountnavigation.
	//

	// `.account-nav` als direktes Kindelement von `.article` als Box
	// stylen.
	> .account-nav {
		@include padding(get-spacing('m') get-spacing('m'));

		background-color : color('white');
		display          : block;

		+ * {
			margin-top : get-spacing('l');
		}
	}

	// Randspalte ausblenden. Accountnavigation sollte am Seitenanfang
	// mit vorhanden sein.
	.content + .aside {
		display : none;
	}

	@include media-breakpoint-up('m') {
		// Spaltigkeit von `.account-nav` (als direktes Kindelement von
		// `.article`) definieren.
		> .account-nav {
			> ul {
				column-count : 2;
				column-gap   : 2rem;
			}
		}
	}

	@include media-breakpoint-up('l') {
		// `.account-nav` als direktes Kindelement von `.article`
		// ausblenden.
		> .account-nav {
			display : none;

			+ * {
				margin-top : 0;
			}
		}

		// Randspalte wieder einblenden.
		.content + .aside {
			display : block;
		}
	}
}
