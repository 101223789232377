////
/// Layout / Sections / Header
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.page-main .section-header {
	background-color : color('tertiary');
	border           : 0 none;
	color            : color('white');

	* {
		color : currentColor;
	}

	+ .section {
		border-top : 0 none;
	}

	@include media-breakpoint-up('xs') {
		@include padding((
			bottom : 1em,
			top    : 1rem
		));
	}

	@include media-breakpoint-up('l') {
		@include padding((
			bottom : 2em,
			top    : 2rem
		));
	}
}

