////
/// Components / Contact
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'contact') {
	//
	// Standard.
	//
	// - Header
	// - Avatar
	// - Body
	// - Footer
	//

	.contact {
		@include margin(0 0 get-spacing('m'));

		background-color      : color('white');
		display               : grid;
		grid-template-areas   : 'media' 'header' 'body' 'footer';
		grid-template-columns : auto;
		grid-template-rows    : auto auto 1fr auto;
		text-align            : center;
		width                 : 100%;
		word-wrap             : break-word;

		a {
			text-decoration : none;
		}

		p {
			margin : 0;
		}

		.figure {
			$-size        : rem(120px);

			border-radius : 100%;
			height        : $-size;
			margin        : 0 auto;
			overflow      : hidden;
			position      : relative;
			width         : $-size;

			img {
				border   : 0;
				display  : block;
				height   : auto;
				left     : 0;
				position : absolute;
				top      : 0;
				width    : 100%;
			}
		}

		&__media {
			@include padding(0 0 get-spacing('m'));

			grid-area : media;
		}

		&__header {
			grid-area : header;

			.title {
				font-size      : em(20px, 15px);
				margin         : 0;
				text-transform : none;

				+ * {
					margin-top : 0.5rem;
				}
			}
		}

		&__body {
			@include padding(get-spacing('m') 0 0);

			grid-area : body;

			> *:first-child {
				margin-top : 0;
			}

			> *:last-child {
				margin-bottom : 0;
			}
		}

		&__footer {
			grid-area : footer;
		}

		@include media-breakpoint-up('l') {
			grid-template-areas   : 'media header' 'media body' 'media footer';
			grid-template-columns : auto 1fr;
			grid-template-rows    : auto 1fr auto;
			text-align            : left;

			&__media {
				padding-bottom : 0;
				padding-right  : get-spacing('l');
			}
		}
	}

	.contact-collection {
		$-gap      : 2rem;
		$-gap-half : ($-gap / 2);

		&.-has-pagination {
			padding-bottom : 2rem;
			position       : relative;

			.pagination-nav {
				bottom   : 0;
				left     : 0;
				margin   : 0;
				position : absolute;
				width    : 100%;
			}
		}

		.contact {
			height : 100%;

			&:not(:last-child) {
				margin-bottom : 1rem;
			}

			@include media-breakpoint-up('xs') {
				&:not(:last-child) {
					margin-bottom : 2rem;
				}
			}
		}

		// 2-spaltige Darstellung
		@include media-breakpoint-up('m') {
			align-content   : stretch;
			align-items     : stretch;
			display         : flex;
			flex-direction  : row;
			flex-wrap       : wrap;
			justify-content : flex-start;
			margin-left     : -#{$-gap-half};
			margin-right    : -#{$-gap-half};

			.contact {
				@include margin($-gap $-gap-half 0);

				flex       : 0 1 calc(50% - #{$-gap});
				width      : calc(50% - #{$-gap});
				align-self : auto;

				// `margin-top` der ersten beiden `.card` zurücksetzen
				&:nth-child(-n+2) {
					margin-top : 0;
				}
			}
		}

		// 3-spaltige Darstellung
		@include media-breakpoint-up('l') {
			.contact {
				flex  : 0 1 calc(33.3333333333% - #{$-gap});
				width : calc(33.3333333333% - #{$-gap});

				// `margin-top` der ersten drei `.card` zurücksetzen
				&:nth-child(-n+3) {
					margin-top : 0;
				}
			}

			/*
			// 3-Spalter
			grid-template-columns : 1fr 1fr 1fr;
			*/
		}

		// 4-spaltige Darstellung
		@include media-breakpoint-up('xl') {
			.card {
				flex  : 0 1 calc(25% - #{$-gap});
				width : calc(25% - #{$-gap});

				// `margin-top` der ersten vier `.card` zurücksetzen
				&:nth-child(-n+4) {
					margin-top : 0;
				}
			}
		}
	}
}
