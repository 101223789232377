////
/// Components / Form / Select
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.custom-select {
	@if variable-exists(form-select-font-size) {
		@include font-size($form-select-font-size);
	}

	@if variable-exists(form-select-font-weight) {
		@include font-size($form-select-font-weight);
	}

	background-color : color($form-select-bg-color);
	border-color     : color($form-select-border-color);
	border-radius    : 0;
	color            : color($form-select-color);
	height           : calc(#{$base-line-height} + 1rem);
	line-height      : $base-line-height;

	&:focus {
		background-color : color($form-select-focus-bg-color);
		border-color     : color($form-select-focus-border-color);
		color            : color($form-select-focus-color);
		box-shadow       : none;

		&::-ms-value {
			background-color : color($form-select-bg-color);
			color            : color($form-select-color);
		}
	}

	// &[multiple],
	// &[size]:not([size="1"]) {}

	&:disabled {
		background-color : color($form-select-disabled-bg-color);
		border-color     : color($form-select-disabled-bg-color);
		color            : color($form-select-disabled-color);
	}

	&:-moz-focusring {
		text-shadow : 0 0 0 color($form-select-color);
	}
}
