////
/// Functions / Typography
////

/// Zeilenhöhe berechnen<br/>
/// <small>Wertrückgabe erfolgt ohne Einheit. Bei Übergabe eines Wertes ohne Einheit wird der Wert 1:1 zurückgegeben.</small>
///
/// @example
///   $base-font-size : 16px;
///
///   $var : line-height(24px);
///   // = 1.5
///
///   $var : line-height(24px, 18px);
///   // = 1.3333333333
///
///   $var : line-height(1.75em);
///   // = 1.75
///
///   $var : line-height(1.25rem);
///   // = 1.25
///
///   $var : line-height(24);
///   // = 24
///
///   $var : line-height(1.375);
///   // = 1.375
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {length} $line-height - Zeilenhöhe in `px`
/// @parameter {length} $context [$base-font-size] - Kontextschriftgröße
/// @require   {function} strip-unit
/// @require   {variable} $base-font-size
/// @return    {number}
@function line-height($line-height, $context : $base-font-size) {
	$unit : unit($line-height);

	@if $unit == '' or $unit == 'rem' or $unit == 'em' {
		@return strip-unit($line-height);
	} @else {
		@return strip-unit($line-height / $context);
	}
}

/// Schriftfamilie auslesen
///
/// @example
///   $font-stack : (
///     'text'    : "'Helvetic Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI'",
///     'heading' : "Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI'"
///   );
///
///   $var : font-family('text');
///   // = 'Helvetic Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI'
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-stack]
/// @require   {variable} $font-stack
/// @require   {function} is-empty
/// @return    {string}
@function font-family($key, $stack : $font-stack) {
	$val : map-get($stack, $key) or '';

	@if not is-empty($val) {
		// Prüfe ob ev. `$val` als Schlüssel in `$stack` existiert!
		$val2 : map-get($stack, $val) or '';

		@if not is-empty($val2) {
			$val : unquote($val2);
		} @else {
			$val : unquote($val);
		}
	}

	@return $val;
}

/// Schriftgewicht
///
/// @example
///   $font-weight : (
///     'light'   : 300,
///     'regular' : 400,
///     'bold'    : 700
///   );
///
///   $var : font-weight('regular');
///   // = 400
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-weight]
/// @require   {variable} $font-weight
/// @require   {function} is-empty
/// @require   {function} is-string
/// @return    {string}
@function font-weight($key, $stack : $font-weight) {
	$val : map-get($stack, $key) or '';

	@if not is-empty($val) {
		$val : if(is-string($val), unquote($val), $val);
	} @else {
		$val : $key;
		// @warn 'Schlüssel `#{$key}` ist in `$stack` nicht vorhanden.';
	}

	@return $val;
}

/// Schriftgröße
///
/// @example
///   $font-size : (
///     's' : 14px,
///     'm' : 16px,
///     'l' : 18px
///   );
///
///   $var : font-size('s');
///   // = 14px
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-size]
/// @require   {variable} $font-size
/// @require   {function} is-empty
/// @require   {function} is-string
/// @return    {string}
@function font-size($key, $stack : $font-size) {
	$val : map-get($stack, $key) or null;

	@if not is-empty($val) {
		$val : if(is-string($val), unquote($val), $val);
	} @else {
		$val : $key;
		// @warn 'Schlüssel `#{$key}` ist in `$stack` nicht vorhanden.';
	}

	@return $val;
}

/// Basisschriftgröße berechnen<br/>
/// <small>_(inspiriert von [Pierre Burel`s Mixin](https://github.com/pierreburel/sass-rem))_</small>
///
/// @example
///   $base-font-size : 16px;
///
///   $var : font-basesize();
///   // = 100%
///
///   $var : font-basesize(75%);
///   // = 75%
///
///   $var : font-basesize(100%, 14px);
///   // = 87.5%
///
///   $var : font-basesize(75%, 14px);
///   // = 65.625%
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {length} $zoom [100%] - prozentuale Anpassung (responsive Typografie)
/// @parameter {length} $base [$base-font-size] - Basis-, Kontextschriftgröße
/// @require   {variable} $base-font-size
@function font-basesize($zoom: 100%, $base : $base-font-size) {
	@return $zoom / 16px * $base;
}

/// Überschriftselektoren zusammenstellen<br/>
///
/// @example scss
///   $var : heading-selectors();
///   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
///
///   $var : heading-selectors(1, 6, false);
///   // = h1, h2, h3, h4, h5, h6
///
///   $var : heading-selectors(3, 4);
///   // = h3, .h3, h4, .h4
///
///   // Abfangen von nicht korrekten Angaben (`< 1`, `> 6`).
///   $var : heading-selectors(0, 0);
///   $var : heading-selectors(-1, 0);
///   $var : heading-selectors(7, -15);
///   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
///
///   $var : heading-selectors(0, 2);
///   // = h1, .h1, h2, .h2
///
///   // Vertauschte Werte.
///   $var : heading-selectors(3, 1);
///   // = h3, .h3, h4, .h4, h5, .h5, h6, .h6
///
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {number} $min [1] - zwischen 1 und 6
/// @parameter {number} $max [6] - zwischen 1 und 6
/// @parameter {bool} $classname - `h[n]` auch als `.h[n]` generieren
/// @return    {string}
@function build-heading-selectors($min : 1, $max : 6, $classname : true) {
	$selectors : ();
	$min       : if($min < 1 or $min > 6, 1, $min);
	$-min      : $min;
	$max       : if($max < 1 or $max > 6, 6, $max);
	$-max      : $max;

	@if $min > $max {
		$min : $-max;
		$max : $-min;
	}

	@for $i from $min through $max {
		$selectors : append($selectors, unquote('h#{$i}'), 'comma');

		@if $classname {
			$selectors : append($selectors, unquote('.h#{$i}'), 'comma');
		}
	}

	@return if(length($selectors) == 1, nth($selectors, 1), $selectors);
}
