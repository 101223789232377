////
/// Layout / Contents / Sections
///
/// @author Heiko Pfefferkorn
////

.page-main > * {
	width : 100%;
}

// `<section/>` als direktes Kindelement.
.page-main > .section {
	+ .section {
		border-top : rem(2px) solid rgba(color('black'), 0.05);
	}

	@include padding((
		bottom : get-spacing('m'),
		top    : get-spacing('m')
	));

	@include media-breakpoint-up('xs') {
		@include padding((
			bottom : 2rem,
			top    : 2rem
		));
	}

	@include media-breakpoint-up('xxl') {
		@include padding((
			bottom : 4rem,
			top    : 4rem
		));
	}
}

