////
/// Components / Button
///
/// @group Components
/// @author Heiko Pfefferkorn
////

button,
.btn {
	display        : block;
	position       : relative;
	text-align     : center;
	transition     : all 0.25s;

	&:focus {
		@include outlineAccessibility();
	}
}

@if map-get($components-in-use, 'button') {
	//
	// Standarddefinitionen für alle Buttongrößen auf relative Wertangaben
	// umswitchen!
	//
	//.btn,
	//.btn-xs,
	//.btn-s,
	//.btn-sm,
	//.btn-l,
	//.btn-lg {
	//	@include padding(get-spacing($c-button-padding-y, true, 'em') get-spacing($c-button-padding-x, true, 'em'));
	//
	//	// Schriftfamilie?
	//	@if variable-exists(c-button-font-family) {
	//		@include font-family($c-button-font-family);
	//	}
	//
	//	// Schriftgröße?
	//	@if variable-exists(c-button-font-size) {
	//		@include font-size($c-button-font-size);
	//	}
	//
	//	// Schriftstärke?
	//	@if variable-exists(c-button-font-weight) {
	//		@include font-weight($c-button-font-weight);
	//	}
	//
	//	// Zeilenhöhe?
	//	@if variable-exists(c-button-line-height) {
	//		@include line-height($c-button-line-height);
	//	}
	//
	//	border-radius   : get-border-radius($c-button-border-radius, true, 'em');
	//	border-width    : $c-button-border-width;
	//	text-decoration : none;
	//}
	//
	//// TODO: projektspezifische Modifikation `.btn-sm`
	//.btn-xs {
	//	$-py : em(get-spacing($c-button-padding-y, false) - 4px, $base-font-size);
	//	$-px : em(get-spacing($c-button-padding-y, false) - 2px, $base-font-size);
	//
	//	padding : $-py $-px;
	//}

	//.btn {
	//	margin-left  : auto;
	//	margin-right : auto;
	//	max-width    : 365px;
	//
	//	// Box shadow entfernen?
	//	@if not is-bool($components-use-boxshadow) {
	//		&:not(:disabled):not(.disabled) {
	//			&,
	//			&:focus,
	//			&.focus,
	//			&:active,
	//			&:active:focus,
	//			&.active,
	//			&.active:focus {
	//				box-shadow : none;
	//			}
	//		}
	//	}
	//
	//	// Buttonicon
	//	// Der Text eines ButtonsButtontext mit Icon `<i/>`
	//	i {
	//		@include margin(right, get-spacing('s', true, em));
	//	}
	//
	//	&__text {
	//		display: inline-block;
	//
	//		+ i {
	//			@include margin((
	//				left : get-spacing('s', true, em),
	//				right: 0
	//			));
	//		}
	//	}
	//
	//	// deaktivierter Button
	//	&:disabled,
	//	&.disabled {
	//		cursor          : not-allowed;
	//		opacity         : $components-disabled-opacity;
	//		text-decoration : none;
	//	}
	//
	//	// Buttongrößen
	//	@include font-size-variant($c-button-font-sizing, true, '-');
	//}

	//
	// Buttontypen.
	//

	//@if variable-exists(c-button-variant) {
	//	@each $type, $map in $c-button-variant {
	//		$-hover : map-get($map, 'on-hover');
	//
	//		@if $type == 'primary' {
	//			.btn,
	//			.btn-#{$type} {
	//				@include button-variant(map-get($map, 'color'), map-get($map, 'bg'), map-get($map, 'border-color'), map-get($-hover, 'color'), map-get($-hover, 'bg'), map-get($-hover, 'border-color'));
	//			}
	//
	//			.btn-outline,
	//			.btn-outline-#{$type} {
	//				@include button-outline-variant(map-get($map, 'color'), map-get($map, 'bg'), map-get($map, 'border-color'), map-get($-hover, 'bg'), map-get($-hover, 'bg'), map-get($-hover, 'border-color'));
	//			}
	//		} @else {
	//			.btn-#{$type} {
	//				@include button-variant(map-get($map, 'color'), map-get($map, 'bg'), map-get($map, 'border-color'), map-get($-hover, 'color'), map-get($-hover, 'bg'), map-get($-hover, 'border-color'));
	//			}
	//
	//			.btn-outline-#{$type} {
	//				@include button-outline-variant(map-get($map, 'color'), map-get($map, 'bg'), map-get($map, 'border-color'), map-get($map, 'bg'), map-get($-hover, 'bg'), map-get($-hover, 'border-color'));
	//			}
	//		}
	//	}
	//}

	// Button-Icons
	//.btn[class*="-icon-"] { /* stylelint-disable-line string-quotes */
	//	padding-right : 2.5rem;
	//
	//	&::after {
	//		@extend %icon-fontawesome;
	//
	//		font-size   : 1.375em;
	//		font-weight : 900;
	//		position    : absolute;
	//		right       : 0.8125em;
	//		top         : 50%;
	//		transform   : translateY(-50%);
	//	}
	//}

	//.btn[class*="-icon-arrow-right"] { /* stylelint-disable-line string-quotes */
	//	&::after {
	//		content    : "\f105"; /* stylelint-disable-line string-quotes */
	//		transition : right 0.25s;
	//	}
	//
	//	@include on-hover-focus() {
	//		&::after {
	//			right : 0.25em;
	//		}
	//	}
	//}

	//.btn[class*="-icon-times"] { /* stylelint-disable-line string-quotes */
	//	&::after {
	//		content    : "\f00d"; /* stylelint-disable-line string-quotes */
	//		transition : all 0.25s;
	//	}
	//
	//	@include on-hover-focus() {
	//		&::after {
	//			transform : translateY(-50%) rotate(90deg);
	//		}
	//	}
	//}

	//[class*="btn-outline"] { /* stylelint-disable-line string-quotes */
	//	@include padding(em(12px, $base-font-size));
	//
	//	border-width : 2px;
	//
	//	&[class*="-icon-"] { /* stylelint-disable-line string-quotes */
	//		padding-right : 2.5rem;
	//
	//		// &::after {
	//		//	background-image : image('icons/arrow_right.svg');
	//		// }
	//	}
	//}
}
