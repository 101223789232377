////
/// Base / Utilities / Visibility
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.hidden { display : none; }

.visually-hidden {
	border   : 0;
	clip     : rect(0 0 0 0);
	height   : 1px;
	margin   : -1px;
	overflow : hidden;
	padding  : 0;
	position : absolute;
	width    : 1px;
}
