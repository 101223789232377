////
/// Layout / Navigation
///
/// 1. Definitionen analag `.page-header` da ein visuelles Übereinstimmen
///    des Layout´s bzgl. ´Header reduced´ und Breakpoints gegeben sein sollte.
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

//
// Seitennavigation
//

.main-nav {
	@include font-size('l');
	@include font-weight('normal');

	@include media-breakpoint-up('xxl') {
		@include font-size('xl');
	}

	color : color('black-50');

	> .nav {
		//
		// Erste Ebene
		//

		justify-content: center;

		> .nav__item  {
			> .nav__link {
				align-items     : center;
				display         : flex;
				flex-flow       : column;
				height          : rem(70px);
				justify-content : center;

				> .nav__link-icon {
					display : block;
					opacity : 0.5;
					width   : .875em;
				}
			}

			@include media-breakpoint-up('l') {
				> .nav__link {
					@include padding(0 get-spacing('m'));

					height : rem(90px);
				}
			}

			@include media-breakpoint-up('xxl') {
				min-width : 13%;

				> .nav__link {
					@include padding((
						left  : get-spacing('l'),
						right : get-spacing('l')
					));

					height : rem(110px);
				}
			}
		}

		//
		// Zweite Ebene
		//

		.nav {
			max-height : calc(100vh - var(--header-height));
			overflow-x : hidden;
			overflow-y : auto;
			text-align : center;

			.nav__link {
				@include padding(get-spacing('s') get-spacing('m'));

				@include media-breakpoint-up('xxl') {
					@include padding((
						left  : get-spacing('l'),
						right : get-spacing('l')
					));
				}
			}

			.nav__item {
				background-color : color('white');
				border-left      : 1px solid color('black-10');
				border-right     : 1px solid color('black-10');
				border-top       : 1px solid color('white');

				+ .nav__item {
					border-top : 0 none;
				}

				&:first-child {
					padding-top : get-spacing('s');
				}

				&:last-child {
					border-bottom  : 1px solid color('black-10');
					padding-bottom : get-spacing('s');
				}
			}
		}
	}
}

.meta-nav {
	color : color('white');

	.nav {
		align-items     : center;
		color           : currentColor;
		flex-direction  : column;
		justify-content : center;

		.nav {
			display : none;
		}

		&__link {
			&-icon {
				display : none;
			}

			&:hover,
			&:focus,
			&.-touched {
				color           : color('primary');
				text-decoration : none;
			}

			@include on-active() {
				color           : color('primary');
				text-decoration : none;
			}
		}

		@include media-breakpoint-up('s') {
			flex-direction : row;
		}
	}
}

// .page-menu {
//	> .main-nav {
//		flex : 1 0 auto;
//	}
//
//	> .meta-nav {
//		flex : 0 0 auto;
//	}
// }

