////
/// Mixins / Size
////

/// Breite und Höhe eines Elementes setzen.
///
/// @example scss
///   .container-a {
///     @include size(200px);
///   }
///
///   .container-b {
///     @include size(200px, 50px);
///   }
///
/// @example css - Result
///   .container-a {
///     height : 200px;
///     width  : 200px;
///   }
///
///   .container-b {
///     height : 50px;
///     width  : 200px;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {length} $width
/// @parameter {length} $height [$width]
@mixin size($width, $height: $width) {
	height : $height;
	width  : $width;
}
