////
/// Converts
////

/// Wertkonvertierungen zu REM<br/>
/// <small>_(inspiriert von [Pierre Burel](https://github.com/pierreburel/sass-em/))_</small>
///
/// @example scss
///   $var : rem(24px); // einzelner Wert
///   // = 1.5rem
///
///   $var : rem(4px 8px); // mehrere Werte
///   // = 0.25rem 0.5rem
///
///   $var : rem(2px solid #000); // mehrere gemischte Werte
///   // = 0.125rem solid #000
///
///   $var : rem(0 0 2px #e4e4e4, inset 0 0 4px #f1f1f1); // mehrere und gemischte Werte (kommasepariert)
///   // = 0 0 0.125rem #e4e4e4, inset 0 0 0.25rem #f1f1f1
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {length | list...} $values
/// @require   {variable} $base-font-size
/// @require   {function} unit-separator
/// @require   {function} is-numeric
/// @require   {function} contains
/// @require   {function} strip-unit
/// @return    {length | list}
@function rem($values...) {
	$result    : ();
	$separator : unit-separator($values);

	@each $value in $values {
		@if is-numeric($value) and unit($value) == 'px' {
			$result : append($result, $value / $base-font-size * 1rem, $separator); /* stylelint-disable-line length-zero-no-unit */
		} @else if is-numeric($value) and contains('rem' 'em', unit($value)) {
			$result : append($result, strip-unit($value) * 1rem, $separator); /* stylelint-disable-line length-zero-no-unit */
		} @else if is-list($value) {
			// Wert ist eine Liste ... Listeneinträge konvertieren (rekursiv).
			// $-separator : unit-separator($value);
			$value : rem($value...);
			// $value : unit-separator($value, $-separator);
			$result : append($result, $value, $separator);
		} @else if is-numeric($value) and unitless($value) {
			@if $value == 0 {
				// `0` Wert als `0` belassen
				$result : append($result, $value, $separator);
			} @else {
				// Wert ungleich `0` als `rem` zurückgeben.
				$result : append($result, $value + 0rem, $separator); /* stylelint-disable-line length-zero-no-unit */
			}
		} @else {
			$result : append($result, $value, $separator);
		}
	}

	@return if(length($result) == 1, nth($result, 1), $result);
}

/// Wertkonvertierungen zu EM<br/>
/// <small>Als letzter zu übergebender Funktionsparameter fungiert hier immer ein Context auf dessen Basis die Umrechnung erfolgt!<br>
/// _(inspiriert von [Pierre Burel](https://github.com/pierreburel/sass-em/))_</small>
///
/// @example scss
///   $var : em(24px, 16px) // einzelner Wert
///   // = 1.5em
///
///   $var : em(1px solid #000, 16px); // mehrere gemischte Werte
///   // = 0.0625em solid #000
///
///   $var : em(0 0 2px #e4e4e4, inset 0 0 5px #f1f1f1, 16px); // mehrere und gemischte Werte (kommasepariert)
///   // = 0 0 0.125em #e4e4e4, inset 0 0 0.3125em #f1f1f1
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de) Anpassungen
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string | list...} $values
/// @require   {function} unit-separator
/// @require   {function} is-list
/// @return    {string | list}
@function em($values...) {
	$context   : nth($values, length($values));
	$result    : ();
	$separator : unit-separator($values);

	@for $i from 1 through length($values) - 1 {
		$value : nth($values, $i);

		@if type-of($value) == 'number' and unit($value) == 'px' {
			$result : append($result, $value / $context * 1em, $separator);
		} @else if is-list($value) {
			$value  : append($value, $context);
			$result : append($result, em($value...), $separator);
		} @else {
			$result : append($result, $value, $separator);
		}
	}

	@return if(length($result) == 1, nth($result, 1), $result);
}
