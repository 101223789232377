////
/// Components / Video
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.video {
	$-root : &;

	position : relative;

	&[data-privacy] {
		#{$-root}__media {
			display : none;
		}
	}

	&.-is-initialized [data-viewport-layer] {
		display : none;
	}
}
