////
/// Components / Pagination
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'pagination') {
	.pagination {
		@include font-weight('normal');

		$self : &;

		border-radius   : 0;
		display         : flex;
		flex-wrap       : wrap;
		font-size       : inherit;
		justify-content : center;
		margin          : 0;
		padding         : 0;

		&__link {
			@include padding(0 get-spacing('s'));

			background-color : color('white');
			display : block;
			color           : currentColor;
			line-height     : inherit;
			text-decoration : none;
			padding         : 0.375rem 0.75rem;

			@include on-hover-focus() {
				color               : color($pagination-hover-color);
				text-decoration     : none;
				border-bottom-color : color('black-1');
			}

			&[aria-current="true"] {
				color : color($pagination-current-color);
			}

			&[disabled] {
				cursor          : default;
				opacity         : 0.3;
				text-decoration : none;
			}
		}

		&__page {
			margin  : 0;
			padding : 0;

			&::before {
				display : none;
			}
		}

		// Pagination-Wrapper-Element `<nav/>`.
		@at-root &-nav {
			@include margin(get-spacing('l') 0);

			font-size : 1rem;
			color     : color($pagination-color);

			&:last-child {
				margin-bottom : 0;
			}

			&:first-child {
				margin-top : 0;
			}

			@include media-breakpoint-up('xl') {
				@include font-size('l');
			}
		}
	}
}
