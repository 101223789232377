////
/// Mixins / Presentation
////

/// Floats aufheben
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $extend [true] - Platzhalter erweitern oder nicht
@mixin clearfix($extend: true) {
	@if $extend {
		@extend %clearfix;
	} @else {
		&::after {
			clear   : both;
			content : ""; /* stylelint-disable-line string-quotes */
			display : table;
		}
	}
}

/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @require   {function} get-outline-accessibility
@mixin outlineAccessibility() {
	$params : get-outline-accessibility();

	outline        : map-get($params, 'width') map_get($params, 'style')  map_get($params, 'color');
	outline-offset : map-get($params, 'offset');
}

/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {number} $opacity - Level, zwischen 0 und 1
/// @require   {function} clamp
@mixin opacity($opacity) {
	$opacity : clamp($opacity, 0, 1);

	// -ms-filter : 'progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})';
	opacity : $opacity;
}

/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {bool} $extend [true] - Platzhalter erweitern oder nicht
/// @require   {mixin} size
@mixin visually-hidden($extend: true) {
	@if $extend {
		@extend %visually-hidden;
	} @else {
		@include size(1px);

		border   : 0;
		clip     : rect(0 0 0 0);
		margin   : -1px;
		overflow : hidden;
		padding  : 0;
		position : absolute;
	}
}
