////
/// Mixins / Typography
////

/// Integration der Wertangabe erfolgt ohne Einheit. Bei Übergabe eines Wertes
/// ohne Einheit wird der Wert 1:1 integriert (*)!
///
/// @example scss
///   $base-font-size: 16px;
///
///   .container-a {
///     @include line-height(24px);
///   }
///
///   .container-b {
///     @include line-height(1.75em);
///   }
///
///   .container-c {
///     @include line-height(24);
///   }
///
///   .container-d {
///      @include line-height(24px, 18px);
///   }
///
/// @example css - Result
///   .container-a {
///     line-height : 1.5;
///   }
///
///   .container-b {
///     line-height : 1.75;
///   }
///
///   .container-c {
///     line-height : 24;
///   }
///
///   .container-d {
///     line-height : 1.3333333333;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {length} $line-height - Zeilenhöhe
/// @parameter {length} $context [$base-font-size] - Kontextschriftgröße
/// @require   {function} line-height
@mixin line-height($line-height, $context : $base-font-size) {
	@if not is-empty($line-height) {
		line-height : line-height($line-height, $context);
	}
}

/// Fork von [Pierre Burel`s Mixin](https://github.com/pierreburel/sass-rem).
///
/// @example scss
///   $base-font-size: 16px;
///
///   .container-a {
///     @include font-basesize();
///   }
///
///   .container-b {
///     @include font-basesize(75%, 14px);
///   }
///
/// @example css - Result
///   .container-a {
///     font-size : 100%;
///   }
///
///   .container-b {
///     font-size : 65.625%;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {length} $zoom [100%] - prozentuale Anpassung (responsive Typografie)
/// @parameter {length} $base [$base-font-size] - Basis-, Zielschriftgröße
/// @require   {function} font-basesize
@mixin font-basesize($zoom: 100%, $base : $base-font-size) {
	font-size : font-basesize($zoom, $base);
}

/// @example scss
///   $stack : (
///     'text'    : "'Helvetic Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI'",
///     'heading' : "Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI'"
///   );
///
///   .container {
///     @include font-family('text', $stack);
///   }
///
/// @example css - Result
///   .container {
///     font-family : 'Helvetic Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI';
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-stack]
/// @require   {function} font-family
/// @require   {function} is-empty
@mixin font-family($key, $stack : $font-stack) {
	$res : font-family($key, $stack);

	@if not is-empty($res) {
		font-family : $res;
	}
}

/// @example scss
///   $stack : (
///     'lighter' : 200,
///     'light'   : 300,
///     'normal'  : 400,
///     'bold'    : 700,
///     'bolder'  : 900
///   );
///
///   .container {
///     @include font-weight('light', $stack);
///   }
///
/// @example css - Result
///   .container {
///     font-weight : 300;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-weight]
/// @require   {function} font-weight
/// @require   {function} is-empty
@mixin font-weight($key, $stack : $font-weight) {
	$res : font-weight($key, $stack);

	@if not is-empty($res) {
		font-weight : $res;
	}
}

/// Eigenschaft `font-size` integrieren.<br/>
/// <small>_Sind relative Größenangaben aktiviert, dann wird die Schriftgröße
/// auf Basis von `$base-font-size` berechnet!_<br/></small>
///
/// @example scss
///   $base-font-size   : 16px;
///   $spacing-relative : true;
///   $base-font-unit   : rem;
///   $font-size        : (
///     's' : 14px,
///     'm' : 16px,
///     'l' : 18px
///   );
///
///   .container {
///     @include font-size('s');
///   }
///
///   $base-font-size   : 18px;
///   $base-font-unit   : em;
///
///   .container-em {
///     @include font-size('s');
///   }
///
///   $base-font-unit : px;
///
///   .container-px {
///     @include font-size('s');
///   }
///
/// @example css - Result
///   .container-rem {
///     font-size : .875rem;
///   }
///
///   .container-em {
///     font-size : 0.888889em;
///   }
///
///   .container-px {
///     font-size : 14px;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $key
/// @parameter {map} $stack [$font-size]
/// @require   {function} font-size
/// @require   {function} is-empty
/// @require   {function} rem
/// @require   {function} em
/// @require   {variable} $spacing-relative
/// @require   {variable} $base-font-unit
@mixin font-size($key, $stack : $font-size) {
	$res : font-size($key, $stack);

	@if not is-empty($res) {
		@if $spacing-relative == true {
			@if $base-font-unit == rem {
				$res : rem($res);
			} @else if($base-font-unit == em) {
				$res : em($res);
			}
		}

		font-size : $res;
	}
}

/// Eigenschaft `font-size` von Selektoren auf Basis einer Map erweitern.<br/>
/// <small>_Sind relative Größenangaben aktiviert, dann wird die Schriftgröße
/// auf Basis von `$base-font-size` berechnet!_<br/></small>
///
/// @example scss
///   $base-font-size   : 16px;
///   $spacing-relative : true;
///   $base-font-unit   : rem;
///   $font-sizing      : (
///     's' : 14px,
///     'l' : 18px
///   );
///
///   .container {
///     @include font-size-variant($font-sizing);
///   }
///
///   .container-2 {
///     @include font-size-variant($font-sizing, true);
///   }
///
///   .container-3 {
///     @include font-size-variant($font-sizing, true, '__');
///   }
///
/// @example css - Result
///   .container.s {
///     font-size : 0.875rem;
///   }
///   .container.l {
///     font-size : 1.125rem;
///   }
///
///   .container-2s {
///     font-size : 0.875rem;
///   }
///   .container-2l {
///     font-size : 1.125rem;
///   }
///
///   .container-3__s {
///     font-size : 0.875rem;
///   }
///   .container-3__l {
///     font-size : 1.125rem;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {map} $stack
/// @parameter {boolean} $append-to-parent [null] Eigenständigen neuen Selektor generieren
/// @parameter {string} $delimiter ['-'] Trennzeichen wird vor den generierten Selektor eingesetzt
/// @require   {function} is-map
/// @require   {mixin} font-size
@mixin font-size-variant($map, $append-to-parent: false, $delimiter: '-') {
	@if is-map($map) {
		$-e : '.';

		@if $append-to-parent == true {
			$-e : '&#{$delimiter}';
		}

		@each $key, $val in $map {
			$-s : (
				unquote('#{$-e}#{$key}')
			);

			// Bootstrap-Selektor für ´sm´ hinzufügen?
			@if $key == 's' {
				$-s : append($-s, unquote('#{$-e}#{$key}m'), 'comma');
			}

			// Bootstrap-Selektor für ´md´ hinzufügen?
			@if $key == 'm' {
				$-s : append($-s, unquote('#{$-e}#{$key}d'), 'comma');
			}

			// Bootstrap-Selektor für ´lg´ hinzufügen?
			@if $key == 'l' {
				$-s : append($-s, unquote('#{$-e}#{$key}g'), 'comma');
			}

			#{$-s} {
				@include font-size($val);
			}
		}
	}
}

/// Webfonts mit `@font-face` integrieren.<br/>
/// <small>_Bzgl. moderner Browser (Stand Anfang 2019) wird normalerweise nur `woff2` und `woff` benötigt. Eine Prüfung auf das Schriftformat bzgl. einer Erweiterung der URL wird nur für `eot` und `ttf` durchgeführt!_<br/><br/>
/// `woff2` moderne Browser<br/>
/// `woff` IE 11<br/>
/// `eot` IE6-IE8<br/>
/// `ttf` Safari, Android, iOS<br/></small>
///
/// @example scss
///   @include font-face(
///     'FleischmanBTW01-Regular',
///     'fleischmann-bt/1118443/',
///     (
///       woff2 : 'cd1263c8-9aac-4138-af59-45f3d9a35526.woff2',
///       woff  : '46ae5566-b510-4a0e-b0c2-ae0246dad4b5.woff',
///       eot   : 'a9d32d06-7953-49c4-975a-7e6130045f22.eot',
///       ttf   : '0b8d9c13-c5be-4346-b64b-7e5136da6a24.ttf'
///     )
///   );
///
/// @example css - Result
///   @font-face {
///     font-family : 'FleischmanBTW01-Regular';
///     src         : url('../fleischmann-bt/1118443/cd1263c8-9aac-4138-af59-45f3d9a35526.woff2') format('woff2'),
///                   url('../fleischmann-bt/1118443/46ae5566-b510-4a0e-b0c2-ae0246dad4b5.woff') format('woff'),
///                   url('../fleischmann-bt/1118443/a9d32d06-7953-49c4-975a-7e6130045f22.eot?#iefix') format('embedded-opentype'),
///                   url('../fleischmann-bt/1118443/0b8d9c13-c5be-4346-b64b-7e5136da6a24.ttf') format('truetype');
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {string} $name Schriftname
/// @parameter {string} $path [] Pfad zum Schriftverzeichnis
/// @parameter {map} $fonts [()] Map der Schrifttypen
/// @parameter {size} $weight [null] Schriftgewicht
/// @parameter {string} $style [null] Schriftstil
/// @parameter {string} $display [swap] Schriftanzeige während dem Laden
/// @parameter {string} $base [$base-url + $base-url-fonts] Root-Verzeichnis der lokalen Schriften
/// @require   {function} is-null
/// @require   {function} is-empty
/// @require   {function} is-map
/// @require   {function} url-quotation
/// @require   {function} font
/// @require   {mixin} font-weight
@mixin font-face($name, $path : '', $fonts: (), $weight: null, $style: null, $display: swap, $base: ($base-url + $base-url-fonts)) {
	// Anpassungen für entspr. Formate.
	$formats: (
		eot : 'embedded-opentype',
		ttf : 'truetype'
	);

	@if str-slice($path, 1, 2) == '//' or str-slice($path, 1, 4) == 'http' {
		$base : '';
	}

	// Beinhaltet alle Source-Eigenschaften.
	$src : null;

	@if not is-empty($name) and is-map($fonts) {
		$name  : url-quotation($name);
		$types : map_keys($fonts);

		// Schriftformate zusammenstellen.
		@each $type in $types {
			// Schriftpfad (Modifizierung Anführungszeichen schon enthalten).
			$-url : $path + map-get($fonts, $type);

			$-urlMod : '';

			@if $type == eot and is-null(str-index('?', $-url)) {
				$-urlMod : '?#iefix';
			}

			$-url : font($-url + $-urlMod, $base);

			// Typ modifizieren (Anführungszeichen und ggfs. Format)
			$-type : url-quotation(if(map-has-key($formats, $type), map-get($formats, $type), $type));
			$-type : unquote("#{$-type}"); /* stylelint-disable-line string-quotes */

			// Schrift-Aufruf bereitstellen.
			$src: append($src, $-url format($-type), 'comma');
		}

		// Schriftdeklaration integrieren.
		@font-face {
			@include font-weight($weight);

			font-display : $display;
			font-family  : unquote("#{$name}"); /* stylelint-disable-line string-quotes */
			font-style   : $style;
			src          : $src;
		}
	}
}

/// (Root)Sschriftgröße(n) und Zeilenhöhe(n) auf Basis von Breakpoints setzen.<br/>
/// <small>_Beachtete Eigenschaften siehe Beispiel!_</small>
///
/// @example scss
///   $stack : (
///     'lighter' : 200,
///     'light'   : 300,
///     'normal'  : 400,
///     'bold'    : 700,
///     'bolder'  : 900
///   );
///
///   .container {
///     @include font-weight('light', $stack);
///   }
///
/// @example css - Result
///   .container {
///     font-weight : 300;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     typography
/// @parameter {map} $map - Map mit Breakpoints (siehe `$breakpoints`) und den entsprechenden Angaben für `font-size` und/oder `line-height`
/// @parameter {string} $only - Bei Angabe von `font-size` wird nur `font-size`, bei Angabe von `line-height` nur `line-height` integriert.
/// @require   {function} is-map
/// @require   {function} is-null
/// @require   {function} line-height
/// @require   {mixin} media-breakpoint-up
@mixin base-font-sizing($map, $only: null, $mobileFirst: true) {
	@if is-map($map) {
		// Breakpointabhängige Schriftgrößen setzen
		@each $breakpoint, $data in $map {
			$add-fs : true;
			$add-lh : true;
			$fs : map-get($data, 'font-size') or null;
			$lh : map-get($data, 'line-height') or null;

			// `$only` kann bestimmen welche Eigenschaft integriert wird!
			@if not is-null($only) {
				@if $only != 'font-size' {
					$add-fs : false;
				}

				@if $only != 'line-height' {
					$add-lh : false;
				}
			}

			// Schriftgröße und/oder Zeilenhöhe integrieren
			@if $add-fs = true or $add-lh == true {
				@include media-breakpoint($breakpoint, $mobileFirst) {
					// Schriftgröße integrieren
					@if not is-null($fs) and $add-fs == true {
						font-size : $fs;
					}

					// Zeilenhöhe integrieren
					@if not is-null($lh) and $add-lh == true {
						// Zeilenhöhe auf Basis der Schriftgröße?
						@if not is-null($fs) {
							line-height : line-height($lh, $fs);
						} @else {
							line-height : line-height($lh);
						}
					}
				}
			}
		}
	}
}
