////
/// Components / Alert
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.alert {
	@include padding(get-spacing('s', true, 'em') get-spacing('m', true, 'em'));

	@if variable-exists(alert-font-family) {
		@include font-family($alert-font-family);
	}

	@if variable-exists(alert-font-size) {
		font-size : rem($alert-font-size);
	}

	@if variable-exists(alert-font-weight) {
		@include font-weight($alert-font-weight);
	}

	border        : 0 none;
	border-radius : 0;

	// `<a/>` in `.alert`
	a,
	&-link {
		color       : currentColor;
		font-weight : inherit;
	}

	> p:last-of-type {
		margin-bottom : 0;
	}

	// ausblendebar
	&-dismissible {
		overflow      : hidden;
		padding-right : (get-spacing('m', true, 'em') * 3);

		// Adjust close link position
		.close {
			@include padding(get-spacing('m', true, 'em') get-spacing('m', true, 'em'));

			color     : inherit;
			font-size : inherit;

			> * {
				font-size: 1.25em;
			}
		}
	}

	@include media-breakpoint-up('m') {
		@include padding(get-spacing('m', true, 'em') get-spacing('l', true, 'em'));
	}
}

// Alerttypen
@if variable-exists(alert-variant) {
	@each $type, $map in $alert-variant {
		.alert-#{$type} {
			@include alert-variant(map-get($map, 'color'), map-get($map, 'bg'), map-get($map, 'border-color'), 0.3);
		}
	}
}
