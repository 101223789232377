////
/// Components / Tab
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'tab') {
	.tab {
		// Navigation
		&-nav {
			display    : flex;
			flex-wrap  : nowrap;
			font-size  : 1rem;
			list-style : none;
			margin     : 0 0 -1px;
			padding    : 0;

			&__item {
				margin  : 0;
				padding : 0;

				&::before {
					display : none;
				}
			}
		}

		// Container der einzelnen Panels
		&-panels {
			border : 1px solid color('black-10');

			> .tab__panel {
				display : none;
			}

			> .active {
				display : block;
			}
		}

		&__toggle {
			@include font-weight('normal');
			@include font-size('l');
			@include margin(0);
			@include padding(get-spacing('m'));

			background-color  : transparent;
			border            : 1px solid transparent;
			color             : inherit;
			display           : block;
			position          : relative;
			scroll-margin-top : var(--offset-scroll-top);
			text-align        : left;
			text-transform    : none;
			transition        : all 0.25s;
			width             : 100%;

			&-title {
				display : block;
			}

			@include on-hover-focus() {
				color : color('tertiary');
			}

			&[aria-selected="true"] { /* stylelint-disable-line string-quotes */
				border-color : color('black-10') color('black-10') color('white') color('black-10');
				color        : color('tertiary');
			}
		}

		&__panel {
			&-content {
				@include padding(get-spacing('m'));

				> :first-child {
					margin-top : 0;
				}

				> :last-child {
					margin-bottom : 0;
				}
			}
		}
	}
}
