////
/// Mixins / Breakpoint
////

/// Anwendung von Definitionen (`@content`) ab dem Breakpoint `$name` und höher.<br/>
/// <small>Mobile first ;)</small>
///
/// @example scss
///   $breakpoints : (
///     'xxs' : 0,
///     'xs'  : 420px,
///     's'   : 576px,
///     'm'   : 768px,
///     'l'   : 992px
///   );
///
///   .container {
///     background : #fff;
///
///     @include media-breakpoint-up('s') {
///       background : #ccc;
///     }
///   }
///
/// @example css - Result
///   .container {
///     background : #fff;
///   }
///
///   @media (min-width: 576px) {
///     .container {
///       background : #ccc;
///     }
///   }
///
/// @author    [Bootstrap](http://getbootstrap.com/)
/// @author    [Heiko Pfefferkorn](https://ifabrik.de) (Anpassungen)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @link      https://getbootstrap.com/docs/ Bootstrap Dokumentation
/// @link      https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_breakpoints.scss Bootstrap GIT
/// @parameter {string} $name - Breakpointschlüssel (Key)
/// @parameter {map} $map [$breakpoints] - verfügbare Breakpoints
/// @require   {function} breakpoint-min
@mixin media-breakpoint-up($name, $map: $breakpoints) {
	$min : breakpoint-min($name, $map);

	@if $min {
		@media (min-width : $min) {
			@content;
		}
	} @else {
		// @content;
		@warn 'No value found for Breakpoint `#{$name}`.';
	}
}

/// Anwendung von Definitionen (`@content`) ab der Maximalbreite des Breakpoint `$name` und kleiner.<br/>
/// <small>Desktop first ;)</small>
///
/// @example scss
///   $breakpoints : (
///     'xxs' : 0,
///     'xs'  : 420px,
///     's'   : 576px,
///     'm'   : 768px,
///     'l'   : 992px
///   );
///
///   .container {
///     background : #fff;
///
///     @include media-breakpoint-down('s') {
///       background : #ccc;
///     }
///   }
///
/// @example css - Result
///   .container {
///     background : #fff;
///   }
///
///   @media (max-width: 767px) {
///     .container {
///       background : #ccc;
///     }
///   }
///
/// @author    [Bootstrap](http://getbootstrap.com/)
/// @author    [Heiko Pfefferkorn](https://ifabrik.de) (Anpassungen)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @link      https://getbootstrap.com/docs/ Bootstrap Dokumentation
/// @link      https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_breakpoints.scss Bootstrap GIT
/// @parameter {string} $name - Breakpointschlüssel (Key)
/// @parameter {map} $map [$breakpoints] - verfügbare Breakpoints
/// @require   {function} breakpoint-max
@mixin media-breakpoint-down($name, $map: $breakpoints) {
	$max : breakpoint-max($name, $map);

	@if $max {
		@media (max-width : $max) {
			@content;
		}
	} @else {
		// @content;
		@warn 'No value found for Breakpoint `#{$name}`.';
	}
}

// TODO: Function documentation
@mixin media-breakpoint($name, $mobileFirst: true, $map: $breakpoints) {
	@if $mobileFirst == true {
		@include media-breakpoint-up($name, $map: $breakpoints) {
			@content;
		}
	} @else {
		@include media-breakpoint-down($name, $map: $breakpoints) {
			@content;
		}
	}
}

/// Anwendung von Definitionen (`@content`) zwischen Minimumbreite `$lower` und Maximumbreite `$upper`.<br/>
///
/// @example scss
///   $breakpoints : (
///     'xxs' : 0,
///     'xs'  : 420px,
///     's'   : 576px,
///     'm'   : 768px,
///     'l'   : 992px
///   );
///
///   .container {
///     background : #fff;
///
///     @include media-breakpoint-between('xs', 'm') {
///       background : #ccc;
///     }
///   }
///
/// @example css - Result
///   .container {
///     background : #fff;
///   }
///
///   @media (min-width : 420px) and (max-width : 991px) {
///     .container {
///       background : #ccc;
///     }
///   }
///
/// @author    [Bootstrap](http://getbootstrap.com/)
/// @author    [Heiko Pfefferkorn](https://ifabrik.de) (Anpassungen)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @link      https://getbootstrap.com/docs/ Bootstrap Dokumentation
/// @link      https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_breakpoints.scss Bootstrap GIT
/// @parameter {string} $lower - kleinster Breakpointschlüssel (Key)
/// @parameter {string} $upper - größter Breakpointschlüssel (Key)
/// @parameter {map} $map [$breakpoints] - verfügbare Breakpoints
/// @require   {function} breakpoint-min
/// @require   {function} breakpoint-max
/// @require   {mixin} media-breakpoint-down
/// @require   {mixin} media-breakpoint-up
@mixin media-breakpoint-between($lower, $upper, $map: $breakpoints) {
	$min : breakpoint-min(inspect($lower), $map);
	$max : breakpoint-max(inspect($upper), $map);

	@if $min != null and $max != null {
		@media (min-width : $min) and (max-width : $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($lower, $map) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($upper, $map) {
			@content;
		}
	}
}

/// Anwendung von Definitionen (`@content`) nur innerhalb der Minimum- und Maxiamlbreite des Breakpoints `$name`.<br/>
/// <small>_Desktop first ;)_</small>
///
/// @example scss
///   $breakpoints : (
///     'xxs' : 0,
///     'xs'  : 420px,
///     's'   : 576px,
///     'm'   : 768px,
///     'l'   : 992px
///   );
///
///   .container {
///     background : #fff;
///
///     @include media-breakpoint-only('s') {
///       background : #ccc;
///     }
///   }
///
/// @example css - Result
///   .container {
///     background : #fff;
///   }
///
///   @media (min-width: 576px) and (max-width: 767px) {
///     .container {
///       background : #ccc;
///     }
///   }
///
/// @author    [Bootstrap](http://getbootstrap.com/)
/// @author    [Heiko Pfefferkorn](https://ifabrik.de) (Anpassungen)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @link      https://getbootstrap.com/docs/ Bootstrap Dokumentation
/// @link      https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_breakpoints.scss Bootstrap GIT
/// @parameter {string} $name - Breakpointschlüssel (Key)
/// @parameter {map} $map [$breakpoints] - verfügbare Breakpoints
/// @require   {function} breakpoint-min
/// @require   {function} breakpoint-max
/// @require   {mixin} media-breakpoint-up
/// @require   {mixin} media-breakpoint-down
@mixin media-breakpoint-only($name, $map: $breakpoints) {
	$min : breakpoint-min($name, $map);
	$max : breakpoint-max($name, $map);

	@if $min != null and $max != null {
		@media (min-width : $min) and (max-width : $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $map) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $map) {
			@content;
		}
	}
}
