////
/// Functions / Miscellaneous
////

@function to-string($list, $glue: '', $is-nested: false) {
	$result : null;

	@for $i from 1 through length($list) {
		$e : nth($list, $i);

		@if type-of($e) == list {
			$result : $result#{to-string($e, $glue, true)};
		} @else {
			$result : if(
				$i != length($list) or $is-nested,
				$result #{$e}#{$glue},
				$result#{$e}
			);
		}
	}

	@return $result;
}

/// Werte aus Map bestimmen/holen und gegebenfalls in relative Angabe konvertieren.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {String} $key - Schlüssel
/// @parameter {map} $map - Map mit vorgegebenen Wertepaaren
/// @parameter {bool} $relative [true] - Konvertierung zu relativen Maßangaben durchführen
/// @parameter {string} $unit [$base-font-unit] - zu verwendende Maßangabe
/// @parameter {length} $context [$base-font-size] - bei `$unit == 'em'` ist die Angabe einer kontextbezogenen Größenangabe erforderlich
/// @require   {function} is-measure
/// @require   {function} is-string
/// @require   {function} is-null
/// @require   {function} is-bool
/// @require   {function} rem
/// @require   {function} em
/// @return    {length}
@function get-measurement($key, $map, $relative : true, $unit : $base-font-unit, $context : $base-font-size) {
	$res : 0;

	@if is-measure($key) {
		// Maßangabe wurde übergeben.
		$res : $key;
	} @else if is-string($key) {
		// MapKey wurde übergeben.
		$get : map-get($map, $key);

		@if not is-null($get) {
			$res : $get;
		}
	}

	// Maßangabe in relative Maßangabe konvertieren?
	@if $res != 0 and is-bool($relative) {
		@if $unit == 'rem' {
			$res : rem($res);
		} @else if $unit == 'em' {
			$res : em($res, $context);
		}
	}

	@return $res;
}

/// Abstand aus Map `$spacing` holen
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string} $key - Schlüssel in `$spacing`
/// @parameter {bool} $relative [$spacing-relative] - Maßangabe in relative Werte konvertieren
/// @parameter {bool} $unit [$base-font-unit]
/// @require   {variable} $spacing
/// @require   {function} get-measurement
/// @return    {length}
@function get-spacing($key, $relative: $spacing-relative, $unit : $base-font-unit) {
	@return if(variable-exists(spacing), get-measurement($key, $spacing, $relative, $unit), 0);
}

/// Border-Radius aus Map `$border-radius`
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string} $key - Schlüssel in `$border-radius`
/// @parameter {bool} $relative [$border-radius-relative] - Maßangabe in relative Werte konvertieren
/// @parameter {bool} $unit [$base-font-unit]
/// @require   {variable} $border-radius
/// @require   {function} get-measurement
/// @return    {length}
@function get-border-radius($key, $relative: $border-radius-relative, $unit : $base-font-unit) {
	@return if(variable-exists(border-radius), get-measurement($key, $border-radius, $relative, $unit), 0);
}

/// Aspect Ratio (Prozentwert) bzgl. Breite & Höhe berchnen
///
/// @example
///
///   aspect-ratio(16, 9);
///   = 56.25%
///
///   aspect-ratio(4, 3);
///   = 75%
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {length} $x
/// @parameter {length} $y
@function aspect-ratio($x, $y) {
	$x     : strip-unit($x);
	$y     : strip-unit($y);
	$error : '';
	$res   : 0;

	@if ($x <= 0 or $y <= 0) {
		$error : 'Der oder die angegeben Parameter dürfen nicht `<= 0` sein';
	}

	@if ($error == '') {
		$res : ($y / $x) * 100%;
	} @else {
		@warn $error;
	}

	@return $res;
}

/// Durch Angabe einer Originalbreite und -höhe und einer Zielbreite oder -höhe wird per Berechnung des Seitenverhältnisses die fehlende Zielbreite- oder -höhe ermittelt.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {length} $x - Numerator 1 (reale Breite)
/// @parameter {length} $y - Denominator 1 (reale Höhe)
/// @parameter {length} $x2 - Numerator 2 (neue Breite)
/// @parameter {length} $y2 - Denominator 2 (neue Höhe)
/// @require   {function} strip-unit
/// @return    {length}
@function calc-aspect-ratio($x : 0, $y: 0, $x2 : 0, $y2 : 0) {
	$error : '';
	$res   : 0;
	$x     : strip-unit($x);
	$y     : strip-unit($y);
	$x2    : strip-unit($x2);
	$y2    : strip-unit($y2);

	@if ($x <= 0 or $y <= 0) {
		$error : 'Die Originalbreite und -höhe muss angegeben werden (calc-aspect-ratio())';
	}

	@if ($x2 <= 0 and $y2 <= 0) {
		$error : 'Es muss eine Zielbreite oder -höhe angegeben werden (calc-aspect-ratio())';
	}

	@if ($error == '') {
		// Zielhoehe berechnen
		@if ($x2 > 0) {
			$res : round($x2 / ($x / $y));
		}

		// Zielbreite berechnen
		@if ($y2 > 0) {
			$res : round($y2 * ($x / $y));
		}
	} @else {
		@warn $error;
	}

	@return $res;
}

/// Replace `$search` with `$replace` in `$string`
///
/// @author    Hugo Giraudel
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string} $string
/// @parameter {string} $search
/// @parameter {string} $replace ('')
/// @return    {string} - Updated string
@function str-replace($string, $search, $replace: '') {
	$index : str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/// SVG-XML-String kodieren
///
/// @author    ?
/// @parameter {string} $svg
/// @parameter {string} $search
/// @parameter {string} $replace ('')
/// @return    {string} - aktualisierte `$svg`
@function svg-to-data-uri($svg) {
	/* stylelint-disable string-quotes */
	$svg : str-replace($svg, "<svg", "<svg xmlns=\"http://www.w3.org/2000/svg\" ");
	$svg : str-replace($svg, "<", "%3C");
	$svg : str-replace($svg, ">", "%3E");

	// `"` ersetzen durch `'` IE Issue!
	$svg : str-replace($svg, "\"", "'");

	$svg : str-replace($svg, "#", "%23");
	$svg : str-replace($svg, "&", "%26");
	$svg : 'data:image/svg+xml,#{$svg}';
	/* stylelint-enable */

	@return url($svg);
}

/// @require   {variable} $outlineAccessibility
@function get-outline-accessibility($map: $outlineAccessibility) {
	$width  : 1px;
	$style  : dotted;
	$color  : #0c0;
	$offset : 1px;

	@if is-map($map) {
		@if map_has_key($map, 'width') {
			$width : map-get($map, 'width');
		}

		@if map_has_key($map, 'style') {
			$style : map-get($map, 'style');
		}

		@if map_has_key($map, 'color') {
			$color : color(map-get($map, 'color'));
		}

		@if map_has_key($map, 'offset') {
			$offset : map-get($map, 'offset');
		}
	}

	@return (
		'width'  : $width,
		'style'  : $style,
		'color'  : $color,
		'offset' : $offset
	);
}
