////
/// Mixins / Positions
////

/// Element positionieren.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {string} $position - `absolute`, `fixed`, `relative`
/// @parameter {length} $top [null] - oben
/// @parameter {length} $right [null] - rechts
/// @parameter {length} $bottom [null] - unten
/// @parameter {length} $left [null] - links
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
	@if contains('absolute' 'fixed' 'relative', $position) {
		bottom   : $bottom;
		left     : $left;
		position : $position;
		right    : $right;
		top      : $top;
	}
}

/// Element zentriert positionieren.
///
/// @example scss
///   .container {
///     @include position-centered(absolute);
///
///     height : 200px;
///     width  : 300px;
///   }
///
///   .container-2 {
///     @include position-centered(fixed);
///
///     height : 200px;
///     width  : 300px;
///   }
///
/// @example css - Result
///   .container {
///     bottom    : 0;
///     left      : 0;
///     position  : absolute;
///     right     : 0;
///     top       : 0;
///     transform : translate(-50%, -50%);
///     height    : 200px;
///     width     : 300px;
///   }
///
///   .container-2 {
///     bottom    : 0;
///     left      : 0;
///     position  : absolute;
///     right     : 0;
///     top       : 0;
///     transform : translate(-50%, -50%);
///     height    : 200px;
///     width     : 300px;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {string} $position - `absolute`, `fixed`
@mixin position-centered($position) {
	@if contains('absolute' 'fixed', $position) {
		@include position($position, $top: 50%, $left: 50%);

		transform : translate(-50%, -50%);
	}
}

/// Kurzform für `position(absolute, ...)`
///
/// @example scss
///   .container {
///     @include absolute($top: 0, $left: 0);
///   }
///
///   .container-2 {
///     @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
///   }
///
/// @example css - Result
///   .container {
///     left     : 0;
///     position : absolute;
///     top      : 0;
///   }
///
///   .container-2 {
///     bottom   : 0;
///     left     : 0;
///     position : absolute;
///     right    : 0;
///     top      : 0;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {arglist} $args
/// @require   {mixin} position
@mixin absolute($args...) {
	@include position(absolute, $args...);
}

/// Kurzform für `position(fixed, ...)`
///
/// @example scss
///   .container {
///     @include fixed($top: 0, $left: 0);
///   }
///
///   .container-2 {
///     @include fixed($top: 0, $right: 0, $bottom: 0, $left: 0);
///   }
///
/// @example css - Result
///   .container {
///     left     : 0;
///     position : fixed;
///     top      : 0;
///   }
///
///   .container-2 {
///     bottom   : 0;
///     left     : 0;
///     position : fixed;
///     right    : 0;
///     top      : 0;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {arglist} $args
/// @require   {mixin} position
@mixin fixed($args...) {
	@include position(fixed, $args...);
}

/// Kurzform für `position(relative, ...)`
///
/// @example scss
///   .container {
///     @include relative();
///   }
///
///   .container-2 {
///     @include relative($top: auto, $left: auto);
///   }
///
///   .container-3 {
///     @include relative($top: auto, $right: auto, $bottom: auto, $left: auto);
///   }
///
/// @example css - Result
///   .container-2 {
///     position : relative;
///   }
///
///   .container-2 {
///     left     : auto;
///     position : relative;
///     top      : auto;
///   }
///
///   .container-3 {
///     bottom   : auto;
///     left     : auto;
///     position : relative;
///     right    : auto;
///     top      : auto;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {arglist} $args
/// @require   {mixin} position
@mixin relative($args...) {
	@include position(relative, $args...);
}

/// Kurzform für `position-centered(absolute)`
///
/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @group   layout
/// @require {mixin} position-centered
@mixin centered-absolute() {
	@include position-centered(absolute);
}

/// Kurzform für `position-centered(fixed)`
///
/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @group   helpers
/// @require {mixin} position-centered
@mixin centered-fixed() {
	@include position-centered(fixed);
}
