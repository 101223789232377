
/* rem || em || px */
/* 1680px, 1440px */
@import url(../fonts/fontawesome-pro/css/all.css);
[data-trigger="top-jump"]::before, a.hashtag::before, a.hashtag::after, a.back::before, a.back::after, a.external::before, a.external::after, a.interested::before, a.interested::after, a.readon::before, a.readon::after, a.cancel::before, a.cancel::after, a.feedback::before, a.feedback::after, a.share::before, a.share::after, a.ics::before, a.ics::after, a.filelink .fileinfo__text::after, .page-main .link-web::after, .figure a.figure__media::after, .top-of-page > a span::before, .jump-to-top::before, .breadcrumb__link::before, .accordion__toggle::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-style: normal;
  font-variant: normal;
  text-rendering: auto; }

.clearfix::after {
  clear: both;
  content: "";
  /* stylelint-disable-line string-quotes */
  display: table; }

.breadcrumb__item.-root .breadcrumb__link > span {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 300;
  font-display: swap;
  font-family: 'Ubuntu';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/ubuntu/ubuntu-light.woff2') format('woff2'), url('../fonts/ubuntu/ubuntu-light.woff') format('woff'); }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 400;
  font-display: swap;
  font-family: 'Ubuntu';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/ubuntu/ubuntu-regular.woff2') format('woff2'), url('../fonts/ubuntu/ubuntu-regular.woff') format('woff'); }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 700;
  font-display: swap;
  font-family: 'Ubuntu';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/ubuntu/ubuntu-bold.woff2') format('woff2'), url('../fonts/ubuntu/ubuntu-bold.woff') format('woff'); }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 300;
  font-display: swap;
  font-family: 'Open Sans';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/open-sans/open-sans-light.woff2') format('woff2'), url('../fonts/open-sans/open-sans-light.woff') format('woff'); }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 400;
  font-display: swap;
  font-family: 'Open Sans';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/open-sans/open-sans-regular.woff2') format('woff2'), url('../fonts/open-sans/open-sans-regular.woff') format('woff'); }

/* stylelint-disable-line string-quotes */
/* stylelint-disable-line string-quotes */
@font-face {
  font-weight: 700;
  font-display: swap;
  font-family: 'Open Sans';
  /* stylelint-disable-line string-quotes */
  src: url('../fonts/open-sans/open-sans-bold.woff2') format('woff2'), url('../fonts/open-sans/open-sans-bold.woff') format('woff'); }

:root {
  --black: #000;
  --white: #fff;
  --text: #2e2c2d;
  --even: inherit;
  --odd: inherit;
  --primary: #e55f5f;
  --secondary: #2e2c2d;
  --tertiary: #2e2c2d;
  --danger: #c72b29;
  --info: #8ac2d1;
  --success: #318735;
  --warning: #eec557;
  --grey-light: #f5f5f5;
  --grey: #dbdbdb;
  --grey-dark: #7f7f7f;
  --grey-darker: #575757;
  --selection-bg-color: #2e2c2d;
  --selection-color: #fff;
  --black-10: #e6e6e6;
  --black-20: #cccccc;
  --black-30: #b3b3b3;
  --black-40: #999999;
  --black-50: gray;
  --black-60: #666666;
  --black-70: #4d4d4d;
  --black-80: #333333;
  --black-90: #1a1a1a;
  --font-family-text: 'Ubuntu', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-heading: 'Open Sans', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --header-height: 1px;
  --footer-height: 1px;
  --spacing: 1rem;
  --offset-scroll-top: calc(var(--header-height) + var(--spacing)); }
  @media (min-width: 420px) {
    :root {
      --spacing: 2rem; } }

::selection {
  background-color: #2e2c2d;
  color: #fff; }

html {
  box-sizing: border-box;
  font-size: 16px;
  min-height: 100%;
  scroll-snap-type: y mandatory; }

body {
  font-family: 'Ubuntu', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  color: #2e2c2d;
  font-size: 1rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 1.625;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative; }

*,
* *::before, *::after {
  box-sizing: inherit; }

:focus,
button:focus {
  outline: 2px solid #e55f5f;
  outline-offset: -2px; }

svg:not(:root) {
  overflow: hidden; }

.limit-max-width {
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1600px;
  width: 100%; }
  @media (min-width: 1024px) {
    .limit-max-width {
      padding-left: 2rem;
      padding-right: 2rem; } }

a,
.link {
  color: inherit;
  text-decoration: underline;
  transition: all 0.25s; }
  a:hover, a.-hover, a:active, a.-active, a:focus, a.-focus,
  .link:hover,
  .link.-hover,
  .link:active,
  .link.-active,
  .link:focus,
  .link.-focus {
    color: inherit;
    text-decoration: underline; }
  a:disabled, a.-disabled,
  .link:disabled,
  .link.-disabled {
    color: #000;
    cursor: not-allowed;
    outline: 0;
    text-decoration: line-through; }

[data-trigger="top-jump"] {
  /* stylelint-disable-line string-quotes */
  background-color: inherit;
  border: 2px solid #fff;
  bottom: 1rem;
  color: #fff;
  display: none;
  height: 3.125em;
  overflow: hidden;
  position: fixed;
  right: 1rem;
  text-decoration: none;
  width: 3.125em;
  z-index: 550;
  transition: none; }
  [data-trigger="top-jump"]::before {
    content: "\f077";
    /* stylelint-disable-line string-quotes */
    font-size: 1.25em;
    font-weight: 900;
    left: 50%;
    position: absolute;
    text-indent: initial;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }
  [data-trigger="top-jump"]:hover, [data-trigger="top-jump"].-hover, [data-trigger="top-jump"]:active, [data-trigger="top-jump"].-active, [data-trigger="top-jump"]:focus, [data-trigger="top-jump"].-focus {
    background-color: #000;
    color: #fff; }

hr {
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #000; }

p {
  margin-bottom: 2rem; }

.datetime__date + .datetime__time::before {
  content: ",\002008";
  /* stylelint-disable-line string-quotes */ }

.datetime a[class] {
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
  padding-right: 1.5em;
  text-transform: none; }

table {
  width: 100%; }

img {
  border: 0;
  display: block;
  height: auto;
  max-width: 100%; }

.close {
  opacity: 0.6; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled).-hover, .close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled).-focus {
    opacity: 1; }

[data-c] + [data-c] {
  margin-top: 1rem; }
  @media (min-width: 768px) {
    [data-c] + [data-c] {
      margin-top: 2rem; } }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Open Sans', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 300;
  margin: 0 0 2rem;
  clear: both;
  color: inherit;
  display: block;
  font-size: 1em;
  position: relative;
  width: 100%; }

h1,
.h1 {
  font-size: 1.75em; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 2.125em; } }
  @media (min-width: 1024px) {
    h1,
    .h1 {
      font-size: 2.5em; } }

h2,
.h2 {
  font-size: 1.375em; }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 1.625em; } }
  @media (min-width: 1024px) {
    h2,
    .h2 {
      font-size: 1.75em; } }

h3,
.h3 {
  font-size: 1.125em; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 1.1875em; } }
  @media (min-width: 1024px) {
    h3,
    .h3 {
      font-size: 1.25em; } }

em,
.em {
  font-style: italic; }

small,
.small {
  font-size: 0.875rem; }

mark,
.mark {
  padding: 0.0625rem 0.25rem;
  background-color: #eee679;
  color: #2e2c2d; }

b,
strong,
.strong {
  font-weight: 700; }

.text-light {
  color: #f5f5f5; }

.text-danger {
  color: #c72b29; }

.text-info {
  color: #8ac2d1; }

.text-success {
  color: #318735; }

.text-warning {
  color: #eec557; }

.required-sign {
  color: #c72b29; }

.subtext {
  font-weight: 400;
  font-size: 1.125em;
  margin: 0; }

.blockquotes {
  margin: 0 0 1rem;
  font-size: 1.125rem; }

code {
  font-size: 0.875rem;
  background-color: #8ac2d1;
  color: #fff;
  padding-left: 0.25em;
  padding-right: 0.25em; }

pre {
  font-size: 0.875rem;
  padding: 1rem;
  background-color: #e6e6e6;
  color: #666666;
  max-height: 320px;
  padding: 1rem;
  width: calc(100vw - 2rem); }
  pre code {
    font-size: inherit;
    color: inherit; }
  @media (min-width: 420px) {
    pre {
      width: calc(100vw - 4rem); } }
  @media (min-width: 1024px) {
    pre {
      padding: 2rem; } }

[data-aspect-ratio]:not([data-aspect-ratio='']) {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%; }
  [data-aspect-ratio]:not([data-aspect-ratio=''])::before {
    content: "";
    /* stylelint-disable-line string-quotes */
    display: block; }
  [data-aspect-ratio]:not([data-aspect-ratio='']) > * {
    border: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  [data-aspect-ratio]:not([data-aspect-ratio='']) > img {
    height: auto; }
  [data-aspect-ratio]:not([data-aspect-ratio='']) > video,
  [data-aspect-ratio]:not([data-aspect-ratio='']) > iframe,
  [data-aspect-ratio]:not([data-aspect-ratio='']) > div {
    height: 100%; }

[data-aspect-ratio='16by9']::before {
  padding-top: 56.25%; }

[data-aspect-ratio='4by3']::before {
  padding-top: 75%; }

[data-aspect-ratio='3by4']::before {
  padding-top: 133.33333%; }

[data-aspect-ratio='3by2']::before {
  padding-top: 66.66667%; }

[data-aspect-ratio='2by3']::before {
  padding-top: 150%; }

[data-aspect-ratio='1by1']::before {
  padding-top: 100%; }

.hidden {
  display: none; }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@keyframes rotate {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(360deg); } }

a.hashtag, a.back, a.external, a.interested, a.readon, a.cancel, a.feedback, a.share, a.ics {
  font-weight: 700;
  display: inline-block;
  letter-spacing: 1px;
  position: relative; }
  a.hashtag::before, a.hashtag::after, a.back::before, a.back::after, a.external::before, a.external::after, a.interested::before, a.interested::after, a.readon::before, a.readon::after, a.cancel::before, a.cancel::after, a.feedback::before, a.feedback::after, a.share::before, a.share::after, a.ics::before, a.ics::after {
    color: inherit;
    font-size: 1.3125rem;
    font-weight: 900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.25s; }
  a.hashtag::before, a.back::before, a.external::before, a.interested::before, a.readon::before, a.cancel::before, a.feedback::before, a.share::before, a.ics::before {
    left: 0; }
  a.hashtag::after, a.back::after, a.external::after, a.interested::after, a.readon::after, a.cancel::after, a.feedback::after, a.share::after, a.ics::after {
    right: 0; }
  a.hashtag:hover::before, a.hashtag:hover::after, a.hashtag.-hover::before, a.hashtag.-hover::after, a.hashtag:focus::before, a.hashtag:focus::after, a.hashtag.-focus::before, a.hashtag.-focus::after, a.back:hover::before, a.back:hover::after, a.back.-hover::before, a.back.-hover::after, a.back:focus::before, a.back:focus::after, a.back.-focus::before, a.back.-focus::after, a.external:hover::before, a.external:hover::after, a.external.-hover::before, a.external.-hover::after, a.external:focus::before, a.external:focus::after, a.external.-focus::before, a.external.-focus::after, a.interested:hover::before, a.interested:hover::after, a.interested.-hover::before, a.interested.-hover::after, a.interested:focus::before, a.interested:focus::after, a.interested.-focus::before, a.interested.-focus::after, a.readon:hover::before, a.readon:hover::after, a.readon.-hover::before, a.readon.-hover::after, a.readon:focus::before, a.readon:focus::after, a.readon.-focus::before, a.readon.-focus::after, a.cancel:hover::before, a.cancel:hover::after, a.cancel.-hover::before, a.cancel.-hover::after, a.cancel:focus::before, a.cancel:focus::after, a.cancel.-focus::before, a.cancel.-focus::after, a.feedback:hover::before, a.feedback:hover::after, a.feedback.-hover::before, a.feedback.-hover::after, a.feedback:focus::before, a.feedback:focus::after, a.feedback.-focus::before, a.feedback.-focus::after, a.share:hover::before, a.share:hover::after, a.share.-hover::before, a.share.-hover::after, a.share:focus::before, a.share:focus::after, a.share.-focus::before, a.share.-focus::after, a.ics:hover::before, a.ics:hover::after, a.ics.-hover::before, a.ics.-hover::after, a.ics:focus::before, a.ics:focus::after, a.ics.-focus::before, a.ics.-focus::after {
    color: inherit;
    transition: none; }

a.external, a.interested, a.readon, a.cancel, a.feedback, a.share, a.back, a.ics {
  padding-right: 1.75em;
  text-decoration: none;
  text-transform: uppercase; }

a.external::after {
  content: "\f08e";
  /* stylelint-disable-line string-quotes */
  font-size: 1rem;
  margin-top: -0.0625rem; }

a.interested::after {
  content: "\f005";
  /* stylelint-disable-line string-quotes */
  font-size: 1rem;
  font-weight: 400;
  margin-top: -0.0625rem; }

a.readon {
  padding-right: 1.375em; }
  a.readon::after {
    content: "\f105";
    /* stylelint-disable-line string-quotes */ }

a.feedback::after {
  content: "\f075";
  /* stylelint-disable-line string-quotes */
  font-weight: 400; }

a.share::after {
  content: "\f064";
  /* stylelint-disable-line string-quotes */
  font-size: 0.9375rem;
  font-weight: 400; }

a.cancel {
  color: inherit;
  padding-right: 1.375em; }
  a.cancel::after {
    content: "\f00d";
    /* stylelint-disable-line string-quotes */ }
  a.cancel:hover, a.cancel.-hover, a.cancel:focus, a.cancel.-focus {
    color: inherit; }

a.back {
  padding-left: 1.375em; }
  a.back::before {
    content: "\f104";
    /* stylelint-disable-line string-quotes */ }

a.ics::after {
  content: "\f33d";
  /* stylelint-disable-line string-quotes */
  font-weight: 400; }

a.filelink {
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  overflow-wrap: break-word;
  padding-right: 0;
  text-decoration: none;
  word-wrap: break-word; }
  a.filelink::after {
    display: none; }
  a.filelink .fileinfo {
    background: transparent url('../images/icons/publications.svg') no-repeat left 0.25rem;
    background-size: auto 2.5rem;
    display: block;
    line-height: 1.5;
    min-height: 3rem;
    padding-left: 3.75rem;
    padding-right: 0;
    position: relative;
    text-decoration: none; }
    a.filelink .fileinfo::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      /* stylelint-disable-line string-quotes */ }
    a.filelink .fileinfo__text {
      text-transform: uppercase;
      display: inline-block;
      padding-right: 2.5rem;
      position: relative;
      -webkit-hyphens: manual;
      -ms-hyphens: manual;
      hyphens: manual;
      overflow-wrap: break-word;
      word-wrap: break-word; }
      a.filelink .fileinfo__text::after {
        color: inherit;
        content: "\f105";
        /* stylelint-disable-line string-quotes */
        font-size: 1.375em;
        font-weight: 900;
        position: absolute;
        right: 0.8125em;
        top: 50%;
        transform: translateY(-50%);
        transition: color 0.25s; }
    a.filelink .fileinfo__size {
      font-weight: 400;
      color: inherit;
      display: block; }
  a.filelink:hover .fileinfo__text::after, a.filelink.-hover .fileinfo__text::after, a.filelink:focus .fileinfo__text::after, a.filelink.-focus .fileinfo__text::after {
    color: inherit; }

a.readon:hover, a.readon.-hover, a.readon:focus, a.readon.-focus, a.filelink:hover, a.filelink.-hover, a.filelink:focus, a.filelink.-focus {
  text-decoration: none; }

.page-main .link-web::after {
  color: inherit;
  content: "\f08e";
  /* stylelint-disable-line string-quotes */
  font-size: 0.625em;
  font-weight: 400;
  margin-left: 0.5em;
  position: relative;
  top: -0.125em; }

.alert {
  padding: 0.5em 1em;
  border: 0 none;
  border-radius: 0; }
  .alert a, .alert-link {
    color: currentColor;
    font-weight: inherit; }
  .alert > p:last-of-type {
    margin-bottom: 0; }
  .alert-dismissible {
    overflow: hidden;
    padding-right: 3em; }
    .alert-dismissible .close {
      padding: 1em 1em;
      color: inherit;
      font-size: inherit; }
      .alert-dismissible .close > * {
        font-size: 1.25em; }
  @media (min-width: 768px) {
    .alert {
      padding: 1em 2em; } }

.alert-primary {
  background-color: #e55f5f;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-primary hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-primary a,
  .alert-primary .alert-link {
    color: inherit; }

.alert-secondary {
  background-color: #2e2c2d;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-secondary hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-secondary a,
  .alert-secondary .alert-link {
    color: inherit; }

.alert-success {
  background-color: #318735;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-success hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-success a,
  .alert-success .alert-link {
    color: inherit; }

.alert-info {
  background-color: #8ac2d1;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-info hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-info a,
  .alert-info .alert-link {
    color: inherit; }

.alert-warning {
  background-color: #eec557;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-warning hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-warning a,
  .alert-warning .alert-link {
    color: inherit; }

.alert-danger {
  background-color: #c72b29;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .alert-danger hr {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .alert-danger a,
  .alert-danger .alert-link {
    color: inherit; }

button,
.btn {
  display: block;
  position: relative;
  text-align: center;
  transition: all 0.25s; }
  button:focus,
  .btn:focus {
    outline: 2px solid #e55f5f;
    outline-offset: -2px; }

ul,
ol,
dl {
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0; }
  ul ul,
  ul ol,
  ul dl,
  ol ul,
  ol ol,
  ol dl,
  dl ul,
  dl ol,
  dl dl {
    margin-bottom: 1rem;
    margin-top: 1rem; }

ul li,
ol li {
  position: relative; }
  ul li + li,
  ol li + li {
    margin-top: 0.5rem; }

ul {
  padding-left: 1.5em; }
  ul li::before {
    background-color: #e55f5f;
    border-radius: 100%;
    content: "";
    /* stylelint-disable-line string-quotes */
    height: 0.25rem;
    left: -1.5em;
    overflow: hidden;
    position: absolute;
    top: 0.6666666667rem;
    width: 0.25rem; }

ol {
  counter-reset: ordered-list; }
  ol > li {
    padding-left: 2.75em; }
    ol > li::before {
      color: #e55f5f;
      content: counters(ordered-list, ".") " ";
      /* stylelint-disable-line string-quotes */
      counter-increment: ordered-list;
      display: inline-block;
      left: 0;
      position: absolute;
      text-align: right;
      top: 0;
      width: 2em; }

dl {
  padding-left: 0; }
  dl dd {
    margin: 0.25em 0 0; }
    dl dd + dt {
      margin-top: 1em; }
  @media (min-width: 576px) {
    dl.-inline::after {
      clear: both;
      content: "";
      /* stylelint-disable-line string-quotes */
      display: table; }
    dl.-inline dt {
      float: left;
      width: 10rem; }
    dl.-inline dd {
      margin-left: 10rem; }
      dl.-inline dd + dt + dd {
        margin-top: 1em; } }

.list-bullets {
  padding-left: 1.33333rem; }
  .list-bullets ul {
    margin-top: 1rem; }
  .list-bullets li {
    position: relative; }
    .list-bullets li::before {
      background-color: inherit;
      border-radius: 100%;
      content: "";
      /* stylelint-disable-line string-quotes */
      height: 0.4rem;
      left: -1.33333rem;
      overflow: hidden;
      position: absolute;
      top: 0.6666666667rem;
      width: 0.4rem; }
    .list-bullets li + li {
      margin-top: 1rem; }
  @media (min-width: 1024px) {
    .list-bullets {
      padding-left: 1.25rem; }
      .list-bullets::before {
        left: -1.25rem;
        top: 0.625rem; } }

.list-decimal > li,
.list-roman > li {
  padding: 0;
  position: static; }
  .list-decimal > li::before,
  .list-roman > li::before {
    color: inherit;
    content: counters(ordered-list, ".") " ";
    /* stylelint-disable-line string-quotes */
    counter-increment: ordered-list;
    display: block;
    font: bold 1.8666666666rem/1.25 "Times", serif;
    /* stylelint-disable-line string-quotes */
    left: 0;
    position: static;
    top: 0; }
  .list-decimal > li + li,
  .list-roman > li + li {
    margin-top: 2rem; }

.list-decimal ol,
.list-roman ol {
  margin-top: 2rem; }
  .list-decimal ol > li,
  .list-roman ol > li {
    padding-left: 2.6666666666rem;
    position: relative; }
    .list-decimal ol > li::before,
    .list-roman ol > li::before {
      color: inherit;
      content: counters(ordered-list, ".") " ";
      /* stylelint-disable-line string-quotes */
      counter-increment: ordered-list;
      font: inherit;
      left: 0;
      position: absolute;
      top: 0; }
    .list-decimal ol > li + li,
    .list-roman ol > li + li {
      margin-top: 2rem; }
  .list-decimal ol li li,
  .list-roman ol li li {
    padding-left: 3.6666666667rem; }

.list-roman > li::before {
  content: counter(ordered-list, upper-roman); }

.list-roman ol > li::before {
  color: inherit;
  content: counters(ordered-list, ".") " ";
  /* stylelint-disable-line string-quotes */
  counter-increment: ordered-list;
  font: inherit;
  left: 0;
  position: absolute;
  top: 0; }

.list-tags {
  margin-top: -0.5rem; }
  .list-tags > li {
    margin: 0.5rem 1rem 0.5rem 0;
    display: inline-block; }
  .aside .list-tags + * {
    margin-top: 2rem; }

.list-links li {
  padding-left: 0;
  overflow: hidden; }
  .list-links li:last-child {
    margin-bottom: 0; }
  .list-links li::before {
    display: none; }
  .list-links li + li {
    margin-top: 0.875rem; }

.list-data > * {
  margin: 0;
  padding: 0.8rem 1.66667rem;
  background-color: #fff;
  border-left: 4px solid inherit;
  position: relative; }
  .list-data > * > *:first-child {
    margin-top: 0; }
  .list-data > * > *:last-child {
    margin-bottom: 0; }
  .list-data > * ul li {
    display: inline; }
    .list-data > * ul li + li::before {
      content: ",\002008";
      /* stylelint-disable-line string-quotes */ }
  @media (min-width: 1024px) {
    .list-data > * {
      padding: 1.5rem 3rem; } }

.list-data > li + li,
.list-data > dd + dt {
  margin-top: 2px; }

.list-data > dd {
  font-weight: 700; }

.list-data > dt {
  font-weight: 400; }
  .list-data > dt + dd {
    padding-top: 0; }

.list-data.-at-a-glance dd {
  font-size: 1.2rem; }
  @media (min-width: 1024px) {
    .list-data.-at-a-glance dd {
      font-size: 1.375rem; } }

.skip-to {
  font-size: 0.875rem;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 99999; }
  .skip-to, .skip-to__link {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    width: 1px; }
  .skip-to__link {
    padding: 0.125rem 1rem;
    background-color: #fff;
    border: 0;
    color: #2e2c2d;
    position: absolute; }
    .skip-to__link:active, .skip-to__link:focus {
      background-color: #2e2c2d;
      clip: auto;
      color: #fff;
      display: inline-block;
      height: auto;
      overflow: visible;
      position: static;
      width: auto;
      outline: none; }
  .skip-to:focus-within {
    clip: auto;
    height: auto;
    overflow: visible;
    white-space: normal;
    width: 100%; }
    @media (min-width: 768px) {
      .skip-to:focus-within {
        left: 50%;
        transform: translateX(-50%);
        width: auto; } }

[data-viewport] {
  position: relative; }
  [data-viewport] [data-viewport-layer] {
    background-color: rgba(255, 255, 255, 0.95);
    height: 100%;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10; }
    [data-viewport] [data-viewport-layer]::after {
      font-size: 0.875rem;
      animation: rotate 2s linear infinite;
      content: "\f110";
      /* stylelint-disable-line string-quotes */
      font-family: 'Font Awesome 5 Pro';
      /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      font-size: 2rem;
      font-weight: 300;
      height: 1em;
      left: 50%;
      line-height: 1;
      margin: -0.5em 0 0 -0.5em;
      position: absolute;
      top: 50%;
      width: 1em;
      z-index: 11; }
  [data-viewport].-viewport-in [data-viewport-layer] {
    display: none; }

[data-viewport="in"] [data-viewport-layer] {
  /* stylelint-disable-line string-quotes */
  display: none; }

legend {
  font-size: "s";
  color: #7f7f7f; }

label,
.col-form-label {
  font-size: 0.875rem;
  color: #7f7f7f;
  margin-bottom: 0.25rem; }
  label:focus, label.-focus,
  .col-form-label:focus,
  .col-form-label.-focus {
    outline: 2px solid #e55f5f;
    outline-offset: -2px; }
  @media (min-width: 768px) {
    label,
    .col-form-label {
      font-size: 1rem; } }

.form-control {
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 0;
  color: #000;
  height: calc(26px + 1rem);
  line-height: 26px; }
  .form-control:focus {
    background-color: #fff;
    border-color: #e55f5f;
    color: #000;
    box-shadow: none; }
  .form-control::placeholder {
    color: #b3b3b3; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f5f5f5;
    border-color: #dbdbdb;
    color: #dbdbdb; }

.custom-select {
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 0;
  color: #000;
  height: calc(26px + 1rem);
  line-height: 26px; }
  .custom-select:focus {
    background-color: #fff;
    border-color: #e55f5f;
    color: #000;
    box-shadow: none; }
    .custom-select:focus::-ms-value {
      background-color: #fff;
      color: #000; }
  .custom-select:disabled {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: #dbdbdb; }
  .custom-select:-moz-focusring {
    text-shadow: 0 0 0 #000; }

.input-group > label {
  display: none; }

.input-group > .form-control-clearable {
  position: relative;
  flex: 1 1 0;
  min-width: 0;
  margin-bottom: 0; }

.figure {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  display: block;
  padding: 0;
  position: relative;
  width: 100%; }
  .figure img {
    max-width: none;
    width: 100%; }
  .figure__media {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1; }
    .figure__media > *:not(script) {
      border: 0;
      display: block;
      height: auto;
      line-height: 1;
      overflow: hidden;
      width: 100%; }
    .figure__media + *:not(script):not(.figure__controls) {
      margin-top: 0.5rem; }
  .figure a.figure__media > img {
    transition: transform 0.5s ease-in-out; }
  .figure a.figure__media::after {
    color: #fff;
    content: "\f065";
    /* stylelint-disable-line string-quotes */
    font-weight: 300;
    font-size: 2rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.35s ease;
    z-index: 2; }
  .figure a.figure__media:hover > img, .figure a.figure__media.-hover > img {
    transform: scale(1.025); }
  .figure a.figure__media:hover::after, .figure a.figure__media.-hover::after, .figure a.figure__media:focus::after, .figure a.figure__media.-focus::after {
    opacity: 0.8; }
  .figure__caption {
    font-size: 0.875rem;
    align-items: stretch;
    color: #000;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; }
    .figure__caption > * {
      flex: 0 1 auto; }
    .figure__caption-text {
      order: 1; }
    .figure__caption-copyright {
      order: 2; }
      .figure__caption-copyright[data-label]:not([data-label=''])::before {
        content: "(" attr(data-label) " ";
        /* stylelint-disable-line string-quotes */ }
      .figure__caption-copyright[data-label]:not([data-label=''])::after {
        content: ")";
        /* stylelint-disable-line string-quotes */ }
  .figure__controls {
    background-color: #fff;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: flex-start;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 5; }
    .figure__controls > [data-control] {
      font-size: 0.875rem;
      margin: 0 0.25rem;
      padding: 0;
      background-color: transparent;
      color: #e55f5f;
      flex: 0 1 auto;
      height: 2rem;
      line-height: 2;
      order: 2;
      text-align: center;
      white-space: nowrap;
      width: 2rem;
      /* stylelint-disable string-quotes */
      /* stylelint-enable */ }
      .figure__controls > [data-control][href]::after {
        content: "";
        display: none; }
      .figure__controls > [data-control]:first-child {
        margin-left: 0; }
      .figure__controls > [data-control]:last-child {
        margin-right: 0; }
      .figure__controls > [data-control][data-control='zoom'] {
        order: 1; }
      .figure__controls > [data-control]:hover {
        background-color: #2e2c2d;
        color: #e55f5f; }

.video {
  position: relative; }
  .video[data-privacy] .video__media {
    display: none; }
  .video.-is-initialized [data-viewport-layer] {
    display: none; }

@keyframes nav-fade-in {
  from {
    opacity: 0;
    transform: translate3d(-50%, -2rem, 0); }
  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); } }

.nav {
  font-weight: 400;
  color: currentColor; }
  .nav,
  .nav ul,
  .nav li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .nav__item::before {
    content: "";
    /* stylelint-disable-line string-quotes */
    display: none; }
  .nav__item .nav {
    position: absolute;
    left: 0;
    margin: 0;
    top: 100%; }
  .nav__item:hover > .nav, .nav__item.focus-within > .nav, .nav__item.-touched > .nav {
    animation: nav-fade-in 0.15s linear;
    animation-fill-mode: forwards;
    display: block; }
  .nav__item:focus-within > .nav {
    animation: nav-fade-in 0.15s linear;
    animation-fill-mode: forwards;
    display: block; }
  .nav__link {
    padding: 0.5rem 1rem;
    color: currentColor;
    display: block;
    position: relative;
    text-decoration: none; }
    .nav__link-icon {
      display: none;
      height: 1em;
      width: auto; }
    .nav__link:hover, .nav__link:focus, .nav__link.-touched {
      color: #000;
      text-decoration: none; }
      .nav__link:hover + .nav, .nav__link:focus + .nav, .nav__link.-touched + .nav {
        animation: nav-fade-in 0.15s linear;
        animation-fill-mode: forwards;
        display: block; }
    .nav__link:active, .nav__link.-active {
      color: #e55f5f;
      text-decoration: none; }
    .nav__link:disabled, .nav__link.-disabled {
      color: #cccccc;
      text-decoration: none; }
  .nav .nav {
    position: absolute;
    display: none;
    font-size: 1rem;
    left: 50%;
    margin: 0;
    min-width: 100%;
    opacity: 0;
    top: 100%;
    transform: translate3d(-50%, -2rem, 0);
    transition: transform 0.3s, opacity 0.1s;
    white-space: nowrap; }

.top-of-page {
  background-color: transparent;
  color: #2e2c2d;
  display: block;
  font-size: 1rem;
  margin: 1em auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%; }
  .top-of-page:last-child {
    margin-bottom: 0; }
  .top-of-page > a {
    background-color: transparent;
    border: 0 none;
    color: #2e2c2d;
    display: inline-block;
    font-size: inherit;
    padding: 1em;
    position: relative;
    transition: all 0.15s; }
    .top-of-page > a span::before {
      content: "\f077";
      /* stylelint-disable-line string-quotes */
      font-weight: 900; }
    .top-of-page > a::before, .top-of-page > a::after {
      border-top: 1px solid #e6e6e6;
      content: "";
      position: absolute;
      top: 50%;
      width: 100vw; }
    .top-of-page > a::before {
      margin-right: 1em;
      right: 100%; }
    .top-of-page > a::after {
      left: 100%;
      margin-left: 1em; }
    .top-of-page > a:hover, .top-of-page > a.-hover, .top-of-page > a:active, .top-of-page > a.-active, .top-of-page > a:focus, .top-of-page > a.-focus {
      background-color: transparent;
      color: #e55f5f; }

.jump-to-top {
  background-color: #2e2c2d;
  border: 0.125rem solid rgba(255, 255, 255, 0.1);
  bottom: 1rem;
  color: #fff;
  display: none;
  font-size: 2.25rem;
  height: 1em;
  overflow: hidden;
  position: fixed;
  right: 1rem;
  text-decoration: none;
  transition: color 0.15s, background-color 0.15s;
  width: 1em;
  z-index: 550; }
  .jump-to-top::before {
    content: "\f077";
    /* stylelint-disable-line string-quotes */
    font-size: 0.75rem;
    font-weight: 900;
    left: 50%;
    position: absolute;
    text-indent: initial;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .jump-to-top:hover, .jump-to-top.-hover, .jump-to-top:active, .jump-to-top.-active, .jump-to-top:focus, .jump-to-top.-focus {
    background-color: #e55f5f;
    color: #fff; }

.pagination {
  font-weight: 400;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: inherit;
  justify-content: center;
  margin: 0;
  padding: 0; }
  .pagination__link {
    padding: 0 0.5rem;
    background-color: #fff;
    display: block;
    color: currentColor;
    line-height: inherit;
    text-decoration: none;
    padding: 0.375rem 0.75rem; }
    .pagination__link:hover, .pagination__link.-hover, .pagination__link:focus, .pagination__link.-focus {
      color: #000;
      text-decoration: none;
      border-bottom-color: inherit; }
    .pagination__link[aria-current="true"] {
      color: #e55f5f; }
    .pagination__link[disabled] {
      cursor: default;
      opacity: 0.3;
      text-decoration: none; }
  .pagination__page {
    margin: 0;
    padding: 0; }
    .pagination__page::before {
      display: none; }
  .pagination-nav {
    margin: 2rem 0;
    font-size: 1rem;
    color: #2e2c2d; }
    .pagination-nav:last-child {
      margin-bottom: 0; }
    .pagination-nav:first-child {
      margin-top: 0; }
    @media (min-width: 1200px) {
      .pagination-nav {
        font-size: 1.125rem; } }

.breadcrumb {
  font-weight: 400;
  align-items: center;
  background-color: transparent;
  color: currentColor;
  border-radius: 0;
  line-height: 1;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  padding: 0;
  white-space: nowrap;
  width: 100%; }
  .breadcrumb__link {
    padding: 0.5em 0 0.5em 1.25em;
    color: currentColor;
    display: block;
    position: relative;
    text-decoration: none; }
    .breadcrumb__link::before {
      content: "\f105";
      /* stylelint-disable-line string-quotes */
      font-weight: 400;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .breadcrumb__link:hover, .breadcrumb__link:focus {
      color: inherit; }
    .breadcrumb__link[aria-current] {
      color: #e55f5f; }
  .breadcrumb__item {
    padding: 0;
    margin: 0 1rem 0 0; }
    .breadcrumb__item::before {
      display: none; }
    .breadcrumb__item:last-child {
      margin-right: 0; }
    .breadcrumb__item.-root .breadcrumb__link {
      padding-left: 0; }
      .breadcrumb__item.-root .breadcrumb__link::before {
        content: "\f015";
        /* stylelint-disable-line string-quotes */
        font-weight: 900;
        position: relative;
        top: auto;
        left: auto;
        transform: translateY(0); }
  .breadcrumb-nav {
    margin: 2rem 0;
    color: inherit; }
    .breadcrumb-nav:first-child {
      margin-top: 0; }
    .breadcrumb-nav:last-child {
      margin-bottom: 0; }

.header {
  margin: 0 auto;
  color: #2e2c2d; }
  .header:not(:last-child) {
    margin-bottom: 2rem; }
  .header > * {
    color: currentColor;
    margin-bottom: 0; }
    .header > * + * {
      margin-top: 1rem; }
  .header__title {
    margin-bottom: 0; }
  .header__subtitle {
    font-size: 1.125rem;
    font-weight: 700; }
  @media (min-width: 1400px) {
    .header {
      max-width: none;
      text-align: left; } }

.textblock {
  font-size: 1rem; }
  .textblock__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  .textblock__media, .textblock__content {
    flex-basis: 100%;
    width: 100%; }
    .textblock__media > :first-child, .textblock__content > :first-child {
      margin-top: 0; }
    .textblock__media > :last-child, .textblock__content > :last-child {
      margin-bottom: 0; }
  .textblock__media {
    order: 1; }
    .textblock__media + .textblock__content {
      margin-top: 2rem; }
  .textblock__content {
    order: 2; }
  @media (min-width: 768px) {
    .textblock__media {
      flex-basis: 58%;
      width: 58%; }
    .textblock.-media-below .textblock__media, .textblock.-media-right .textblock__media {
      order: 2; }
    .textblock.-media-below .textblock__content, .textblock.-media-right .textblock__content {
      order: 1; }
    .textblock.-media-below .textblock__media {
      margin-top: 1rem; }
    .textblock.-media-below .textblock__content {
      margin-top: 0; }
    .textblock.-media-left .textblock__media, .textblock.-media-right .textblock__media {
      flex-basis: calc(58% - 2rem);
      width: calc(58% - 2rem); }
    .textblock.-media-left .textblock__content, .textblock.-media-right .textblock__content {
      flex-basis: calc(42% - 2rem);
      flex-grow: 666;
      margin-top: 0;
      width: calc(42% - 2rem); }
    .textblock.-media-left .textblock__media {
      margin-right: 2rem; }
    .textblock.-media-left .textblock__content {
      margin-left: 2rem; }
    .textblock.-media-right .textblock__media {
      margin-left: 2rem; }
    .textblock.-media-right .textblock__content {
      margin-right: 2rem; } }
  .aside .textblock__media, .aside .textblock__content {
    flex-basis: 100%;
    width: 100%; }

.card {
  background-color: rgba(0, 0, 0, 0.05);
  border: 0 none;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  position: relative; }
  .card > * {
    flex: 0 0 auto; }
  .card > .figure {
    margin: 0; }
  .card__body {
    border-top: 0 none;
    flex: 1 1 auto;
    padding: 1rem; }
    .card__body > *:first-child {
      margin-top: 0; }
    .card__body > *:last-child {
      margin-bottom: 0; }
    .card__body a.read-on::before {
      background-color: #2e2c2d;
      content: "";
      /* stylelint-disable-line string-quotes */
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.25s;
      width: 100%; }
    .card__body a.read-on:hover::before, .card__body a.read-on.-hover::before, .card__body a.read-on:focus::before, .card__body a.read-on.-focus::before {
      opacity: 0.05; }

.card-collection.-has-pagination {
  padding-bottom: 2rem;
  position: relative;
  scroll-margin-top: var(--offset-scroll-top); }
  .card-collection.-has-pagination .pagination-nav {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    width: 100%; }

.card-collection .card:not(:last-child) {
  margin-bottom: 1rem; }

.card-collection .card > .figure {
  display: none; }

@media (min-width: 420px) {
  .card-collection .card:not(:last-child) {
    margin-bottom: 2rem; } }

@media (min-width: 768px) {
  .card-collection {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -1rem;
    margin-right: -1rem; }
    .card-collection .card {
      margin: 2rem 1rem 0;
      flex: 0 1 calc(50% - 2rem);
      width: calc(50% - 2rem);
      align-self: auto; }
      .card-collection .card:nth-child(-n+2) {
        margin-top: 0; }
      .card-collection .card > .figure {
        display: block; } }

@media (min-width: 1024px) {
  .card-collection {
    /*
			// 3-Spalter
			grid-template-columns : 1fr 1fr 1fr;
			*/ }
    .card-collection .card {
      flex: 0 1 calc(33.3333333333% - 2rem);
      width: calc(33.3333333333% - 2rem); }
      .card-collection .card:nth-child(-n+3) {
        margin-top: 0; } }

@media (min-width: 1200px) {
  .card-collection .card {
    flex: 0 1 calc(25% - 2rem);
    width: calc(25% - 2rem); }
    .card-collection .card:nth-child(-n+4) {
      margin-top: 0; } }

.contact {
  margin: 0 0 1rem;
  background-color: #fff;
  display: grid;
  grid-template-areas: 'media' 'header' 'body' 'footer';
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr auto;
  text-align: center;
  width: 100%;
  word-wrap: break-word; }
  .contact a {
    text-decoration: none; }
  .contact p {
    margin: 0; }
  .contact .figure {
    border-radius: 100%;
    height: 7.5rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 7.5rem; }
    .contact .figure img {
      border: 0;
      display: block;
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .contact__media {
    padding: 0 0 1rem;
    grid-area: media; }
  .contact__header {
    grid-area: header; }
    .contact__header .title {
      font-size: 1.33333em;
      margin: 0;
      text-transform: none; }
      .contact__header .title + * {
        margin-top: 0.5rem; }
  .contact__body {
    padding: 1rem 0 0;
    grid-area: body; }
    .contact__body > *:first-child {
      margin-top: 0; }
    .contact__body > *:last-child {
      margin-bottom: 0; }
  .contact__footer {
    grid-area: footer; }
  @media (min-width: 1024px) {
    .contact {
      grid-template-areas: 'media header' 'media body' 'media footer';
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr auto;
      text-align: left; }
      .contact__media {
        padding-bottom: 0;
        padding-right: 2rem; } }

.contact-collection.-has-pagination {
  padding-bottom: 2rem;
  position: relative; }
  .contact-collection.-has-pagination .pagination-nav {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    width: 100%; }

.contact-collection .contact {
  height: 100%; }
  .contact-collection .contact:not(:last-child) {
    margin-bottom: 1rem; }
  @media (min-width: 420px) {
    .contact-collection .contact:not(:last-child) {
      margin-bottom: 2rem; } }

@media (min-width: 768px) {
  .contact-collection {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -1rem;
    margin-right: -1rem; }
    .contact-collection .contact {
      margin: 2rem 1rem 0;
      flex: 0 1 calc(50% - 2rem);
      width: calc(50% - 2rem);
      align-self: auto; }
      .contact-collection .contact:nth-child(-n+2) {
        margin-top: 0; } }

@media (min-width: 1024px) {
  .contact-collection {
    /*
			// 3-Spalter
			grid-template-columns : 1fr 1fr 1fr;
			*/ }
    .contact-collection .contact {
      flex: 0 1 calc(33.3333333333% - 2rem);
      width: calc(33.3333333333% - 2rem); }
      .contact-collection .contact:nth-child(-n+3) {
        margin-top: 0; } }

@media (min-width: 1200px) {
  .contact-collection .card {
    flex: 0 1 calc(25% - 2rem);
    width: calc(25% - 2rem); }
    .contact-collection .card:nth-child(-n+4) {
      margin-top: 0; } }

.accordion {
  border: 1px solid #e6e6e6;
  font-size: 1rem; }
  .accordion__toggle, .accordion__panel-content {
    margin: 0;
    padding: 0.5rem 1rem; }
    @media (min-width: 1200px) {
      .accordion__toggle, .accordion__panel-content {
        padding: 1.5rem 2rem; } }
  .accordion__toggle {
    font-weight: 400;
    font-size: 1.125rem;
    background-color: #fff;
    border: 0 none;
    color: inherit;
    display: block;
    position: relative;
    scroll-margin-top: var(--offset-scroll-top);
    text-align: left;
    text-transform: none;
    transition: all 0.25s;
    width: 100%; }
    .accordion__toggle::after {
      color: #2e2c2d;
      content: "\f107";
      /* stylelint-disable-line string-quotes */
      font-size: 1em;
      font-weight: 400;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate3d(0, -50%, 0) rotate(180deg);
      transform-origin: center center;
      transition: all 0.25s; }
    .accordion__toggle-title {
      display: block; }
    .accordion__toggle:hover, .accordion__toggle.-hover, .accordion__toggle:focus, .accordion__toggle.-focus {
      color: #2e2c2d; }
    .accordion__toggle[aria-expanded="true"] {
      /* stylelint-disable-line string-quotes */
      color: #2e2c2d; }
      .accordion__toggle[aria-expanded="true"]::after {
        color: #e55f5f; }
    .accordion__toggle.collapsed::after {
      transform: translate3d(0, -50%, 0) rotate(0deg); }
    @media (min-width: 1200px) {
      .accordion__toggle::after {
        right: 2rem; } }
  .accordion__panel-content {
    background-color: #fff;
    padding-top: 0; }
    .accordion__panel-content > :first-child {
      margin-top: 0; }
    .accordion__panel-content > :last-child {
      margin-bottom: 0; }
  .accordion__panel + .accordion__toggle {
    border-top: 1px solid #e6e6e6; }

.tab-nav {
  display: flex;
  flex-wrap: nowrap;
  font-size: 1rem;
  list-style: none;
  margin: 0 0 -1px;
  padding: 0; }
  .tab-nav__item {
    margin: 0;
    padding: 0; }
    .tab-nav__item::before {
      display: none; }

.tab-panels {
  border: 1px solid #e6e6e6; }
  .tab-panels > .tab__panel {
    display: none; }
  .tab-panels > .active {
    display: block; }

.tab__toggle {
  font-weight: 400;
  font-size: 1.125rem;
  margin: 0;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: inherit;
  display: block;
  position: relative;
  scroll-margin-top: var(--offset-scroll-top);
  text-align: left;
  text-transform: none;
  transition: all 0.25s;
  width: 100%; }
  .tab__toggle-title {
    display: block; }
  .tab__toggle:hover, .tab__toggle.-hover, .tab__toggle:focus, .tab__toggle.-focus {
    color: #2e2c2d; }
  .tab__toggle[aria-selected="true"] {
    /* stylelint-disable-line string-quotes */
    border-color: #e6e6e6 #e6e6e6 #fff #e6e6e6;
    color: #2e2c2d; }

.tab__panel-content {
  padding: 1rem; }
  .tab__panel-content > :first-child {
    margin-top: 0; }
  .tab__panel-content > :last-child {
    margin-bottom: 0; }

.form-control-clearable {
  display: block;
  position: relative;
  width: 100%; }
  .form-control-clearable > [data-clearable-trigger] {
    background-color: transparent;
    border: 0 none;
    color: currentColor;
    font-size: 0.9375rem;
    height: auto;
    line-height: 1;
    margin: 0;
    padding: 0.5rem 0.75rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    z-index: 5; }
    .form-control-clearable > [data-clearable-trigger] > * {
      line-height: inherit; }

[data-ajax-content] > * {
  transition: opacity 0.125s; }

[data-ajax-content].-is-waiting {
  position: relative; }
  [data-ajax-content].-is-waiting > *:not(nav) {
    opacity: 0.25; }
  [data-ajax-content].-is-waiting::before {
    content: "";
    /* stylelint-disable-line string-quotes */
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4; }
  [data-ajax-content].-is-waiting::after {
    animation: rotate 2s linear infinite;
    border-radius: 50%;
    box-shadow: 0 -1.125em 0 0, 0.8125em -0.8125em 0 0, 1.125em 0 0 0, 0.8125em 0.8125em 0 0, 0 1.125em 0 0, -0.8125em 0.8125em 0 0, -1.125em 0 0 0, -0.8125em -0.81255em 0 0;
    color: inherit;
    content: "";
    /* stylelint-disable-line string-quotes */
    font-size: 0.75rem;
    height: 0.5em;
    left: 50%;
    margin: -0.25em auto auto -0.25em;
    position: absolute;
    text-indent: -9999em;
    bottom: 1rem;
    transform: translateZ(0);
    width: 0.5em;
    z-index: 5; }

.slider {
  position: relative; }
  .slider .swiper-button-next, .slider .swiper-button-prev, .slider .swiper-custom-fraction {
    display: none; }
  .slider .swiper-pagination {
    bottom: auto;
    left: auto;
    line-height: 1;
    padding-top: 1rem;
    position: relative;
    margin: 0 auto; }
    .slider .swiper-pagination-bullet {
      background: #b3b3b3;
      height: 0.625rem;
      outline: 0;
      transition: all 0.3s;
      width: 0.625rem; }
      .slider .swiper-pagination-bullet-active {
        background: #e55f5f; }
      .slider .swiper-pagination-bullets > .swiper-pagination-bullet {
        margin-left: 0.5rem;
        margin-right: 0.5rem; }
  .slider .swiper-button-next, .slider .swiper-button-prev {
    background-color: #e55f5f;
    bottom: 1rem;
    color: #fff;
    font-size: 2.25rem;
    height: 1em;
    width: 1em; }
    .slider .swiper-button-next::after, .slider .swiper-button-prev::after {
      font-family: 'Font Awesome 5 Pro';
      /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      font-size: 0.75rem;
      font-weight: 900;
      left: 50%;
      position: absolute;
      text-indent: initial;
      top: 50%;
      transform: translate3d(-50%, -50%, 0); }
  .slider .swiper-button-next {
    right: 0; }
    .slider .swiper-button-next::after {
      content: "\f054";
      /* stylelint-disable-line string-quotes */ }
  .slider .swiper-button-prev {
    left: 0; }
    .slider .swiper-button-prev::after {
      content: "\f053";
      /* stylelint-disable-line string-quotes */ }
  @media (min-width: 768px) {
    .slider .swiper-pagination {
      display: none; }
    .slider .swiper-button-next, .slider .swiper-button-prev, .slider .swiper-custom-fraction {
      display: block; } }
  .slider .swiper-slide__inner > :first-child {
    margin-top: 0; }
  .slider .swiper-slide__inner > :last-child {
    margin-bottom: 0; }
  .slider .figure__caption {
    display: none; }
  .slider.-is-initialized [data-viewport-layer] {
    display: none; }

.search-form.-type-box {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding-right: 2.75rem;
  position: relative; }
  .search-form.-type-box .search-form__input {
    border: 0 none; }
  .search-form.-type-box .search-form__submit {
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    padding: 0.5em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s; }
    .search-form.-type-box .search-form__submit > span {
      display: none; }
    .search-form.-type-box .search-form__submit::before {
      content: "\f002";
      /* stylelint-disable-line string-quotes */
      font-family: 'Font Awesome 5 Pro';
      /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      font-weight: 300; }

.search-results {
  scroll-margin-top: var(--offset-scroll-top); }

.login-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem; }
  .login-form input[type="text"],
  .login-form input[type="password"] {
    font-size: 1.125rem;
    background-color: #fff;
    border: 2px solid #e6e6e6;
    border-radius: 0;
    color: #2e2c2d; }
    .login-form input[type="text"]:hover, .login-form input[type="text"].-hover, .login-form input[type="text"]:focus, .login-form input[type="text"].-focus,
    .login-form input[type="password"]:hover,
    .login-form input[type="password"].-hover,
    .login-form input[type="password"]:focus,
    .login-form input[type="password"].-focus {
      background-color: transparent; }
  .login-form label {
    font-size: 0.875rem;
    display: block;
    padding: 0; }
  .login-form button {
    border-radius: 0; }
  .login-form__inner {
    display: grid;
    grid-template-areas: "user" "pass" "perma" "submit" "forgot";
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto; }
    .login-form__inner > * {
      align-self: center;
      margin: 0; }
      .login-form__inner > * + * {
        margin-top: 1rem; }
  .login-form__u {
    grid-area: user; }
  .login-form__p {
    grid-area: pass; }
  .login-form__forgot {
    grid-area: forgot;
    text-align: center; }
  .login-form__perma {
    grid-area: perma;
    text-align: center; }
  .login-form__submit {
    grid-area: submit;
    text-align: right; }
    .login-form__submit > button {
      display: block;
      text-align: center;
      width: 100%; }
  @media (min-width: 768px) {
    .login-form__inner {
      grid-template-areas: "user user pass pass" "forgot perma perma submit";
      grid-template-columns: 1fr auto auto 1fr;
      grid-template-rows: auto auto; }
      .login-form__inner > * {
        margin-top: 0; }
    .login-form__u, .login-form__p {
      margin-bottom: 1rem; }
    .login-form__u {
      margin-right: 1rem; }
    .login-form__p {
      margin-left: 1rem; }
    .login-form__forgot {
      text-align: left; }
    .login-form__perma {
      text-align: left; }
    .login-form__submit {
      text-align: right; }
      .login-form__submit > button {
        display: inline-block;
        width: auto; } }

.logout-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem; }
  .logout-form button {
    display: block;
    text-align: center;
    width: 100%; }

.page-search {
  padding: 0;
  color: #fff;
  align-items: center;
  background-color: #e55f5f;
  display: flex;
  height: var(--header-height);
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translateY(-100%);
  transition: transform 0.3s;
  width: 100%;
  z-index: 55; }
  .page-search .search-form {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin: 0; }
    .page-search .search-form > * {
      color: inherit; }
    .page-search .search-form__input {
      font-weight: 300;
      padding: 0.5rem 0;
      background-color: transparent;
      border: 0 none;
      color: currentColor;
      font-size: inherit;
      height: auto;
      text-align: center; }
      .page-search .search-form__input:hover, .page-search .search-form__input.-hover, .page-search .search-form__input:focus, .page-search .search-form__input.-focus {
        outline: none;
        background-color: rgba(255, 255, 255, 0.1); }
      .page-search .search-form__input::placeholder {
        color: rgba(255, 255, 255, 0.3); }
    @media (min-width: 768px) {
      .page-search .search-form {
        font-size: 1.125rem; } }
  @media (min-width: 1400px) {
    .page-search {
      font-size: 1.5rem; } }
  .show-page-search .page-search {
    transform: translateY(0); }

.page-logo {
  backface-visibility: hidden;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 1em; }
  .page-logo > img {
    height: 100%;
    width: auto; }
  .page-logo__text {
    /* stylelint-disable-line length-zero-no-unit  */
    background: transparent url('../images/logo.svg') no-repeat 0 0;
    background-size: contain;
    display: block;
    font-size: inherit;
    height: 0;
    overflow: hidden;
    padding-top: 1em;
    text-indent: 150%;
    white-space: nowrap;
    width: 2.8125em; }

.privacy-info {
  padding: 1rem;
  background-color: #2e2c2d;
  color: #cccccc;
  font-size: 1rem; }
  .privacy-info__text {
    color: currentColor;
    margin: 0;
    font-size: 0.875em; }
  .privacy-info__btn {
    padding: 0.25rem 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    color: currentColor;
    display: block;
    margin: 1rem auto 0; }
    .privacy-info__btn:hover, .privacy-info__btn.-hover, .privacy-info__btn:focus, .privacy-info__btn.-focus {
      background-color: rgba(255, 255, 255, 0.3); }
  @media (max-width: 575px) {
    .privacy-info__btn {
      display: block;
      width: 100%; } }
  @media (min-width: 768px) {
    .privacy-info {
      padding: 2rem; } }

.main-nav {
  font-size: 1.125rem;
  font-weight: 400;
  color: gray; }
  @media (min-width: 1400px) {
    .main-nav {
      font-size: 1.5rem; } }
  .main-nav > .nav {
    justify-content: center; }
    .main-nav > .nav > .nav__item > .nav__link {
      align-items: center;
      display: flex;
      flex-flow: column;
      height: 4.375rem;
      justify-content: center; }
      .main-nav > .nav > .nav__item > .nav__link > .nav__link-icon {
        display: block;
        opacity: 0.5;
        width: .875em; }
    @media (min-width: 1024px) {
      .main-nav > .nav > .nav__item > .nav__link {
        padding: 0 1rem;
        height: 5.625rem; } }
    @media (min-width: 1400px) {
      .main-nav > .nav > .nav__item {
        min-width: 13%; }
        .main-nav > .nav > .nav__item > .nav__link {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 6.875rem; } }
    .main-nav > .nav .nav {
      max-height: calc(100vh - var(--header-height));
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center; }
      .main-nav > .nav .nav .nav__link {
        padding: 0.5rem 1rem; }
        @media (min-width: 1400px) {
          .main-nav > .nav .nav .nav__link {
            padding-left: 2rem;
            padding-right: 2rem; } }
      .main-nav > .nav .nav .nav__item {
        background-color: #fff;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-top: 1px solid #fff; }
        .main-nav > .nav .nav .nav__item + .nav__item {
          border-top: 0 none; }
        .main-nav > .nav .nav .nav__item:first-child {
          padding-top: 0.5rem; }
        .main-nav > .nav .nav .nav__item:last-child {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 0.5rem; }

.meta-nav {
  color: #fff; }
  .meta-nav .nav {
    align-items: center;
    color: currentColor;
    flex-direction: column;
    justify-content: center; }
    .meta-nav .nav .nav {
      display: none; }
    .meta-nav .nav__link-icon {
      display: none; }
    .meta-nav .nav__link:hover, .meta-nav .nav__link:focus, .meta-nav .nav__link.-touched {
      color: #e55f5f;
      text-decoration: none; }
    .meta-nav .nav__link:active, .meta-nav .nav__link.-active {
      color: #e55f5f;
      text-decoration: none; }
    @media (min-width: 576px) {
      .meta-nav .nav {
        flex-direction: row; } }

.page-header {
  background-color: #fff; }
  .page-header > .container {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .page-header::after {
    border-top: 0.125rem solid #e55f5f;
    bottom: -0.125rem;
    content: "";
    /* stylelint-disable-line string-quotes */
    display: block;
    left: 0;
    position: absolute;
    width: 100%; }
  .page-header .page-logo,
  .page-header [data-trigger="page-menu"],
  .page-header [data-trigger="page-search"] {
    /* stylelint-disable-line string-quotes */
    display: block;
    transition: transform 0.3s; }
  .page-header .page-logo {
    font-size: 2rem;
    order: 2; }
    @media (min-width: 1024px) {
      .page-header .page-logo {
        order: 1; } }
    @media (min-width: 1400px) {
      .page-header .page-logo {
        font-size: 3rem; } }
  .page-header [data-trigger] {
    background-color: transparent;
    border: 0;
    color: inherit;
    display: block;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    padding: .5em;
    white-space: nowrap; }
    .page-header [data-trigger]::before {
      content: "";
      /* stylelint-disable-line string-quotes */
      font-family: 'Font Awesome 5 Pro';
      /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      font-weight: 300; }
    .page-header [data-trigger]:hover, .page-header [data-trigger].-hover, .page-header [data-trigger]:focus, .page-header [data-trigger].-focus {
      color: #2e2c2d; }
    .page-header [data-trigger][aria-expanded="true"] {
      /* stylelint-disable-line string-quotes */
      color: #e55f5f; }
  .page-header [data-trigger="page-search"] {
    /* stylelint-disable-line string-quotes */
    order: 3; }
    .page-header [data-trigger="page-search"]::before {
      content: "\f002";
      /* stylelint-disable-line string-quotes */ }
  .page-header [data-trigger="page-menu"] {
    order: 1; }
    .page-header [data-trigger="page-menu"]::before {
      content: "\f0c9"; }
    @media (min-width: 1024px) {
      .page-header [data-trigger="page-menu"] {
        display: none; } }
  .page-header .main-nav {
    display: none; }
    @media (min-width: 1024px) {
      .page-header .main-nav {
        order: 2;
        display: block;
        z-index: 2; } }

.page-footer {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #2e2c2d;
  color: #fff; }

.page-main > * {
  width: 100%; }

.page-main > .section {
  padding-bottom: 1rem;
  padding-top: 1rem; }
  .page-main > .section + .section {
    border-top: 0.125rem solid rgba(0, 0, 0, 0.05); }
  @media (min-width: 420px) {
    .page-main > .section {
      padding-bottom: 2rem;
      padding-top: 2rem; } }
  @media (min-width: 1400px) {
    .page-main > .section {
      padding-bottom: 4rem;
      padding-top: 4rem; } }

.page-main .section-header {
  background-color: #2e2c2d;
  border: 0 none;
  color: #fff; }
  .page-main .section-header * {
    color: currentColor; }
  .page-main .section-header + .section {
    border-top: 0 none; }
  @media (min-width: 420px) {
    .page-main .section-header {
      padding-bottom: 1em;
      padding-top: 1rem; } }
  @media (min-width: 1024px) {
    .page-main .section-header {
      padding-bottom: 2em;
      padding-top: 2rem; } }

.m-events-filter__heading {
  display: block;
  text-align: center; }
  .m-events-filter__heading > .btn::after {
    content: "\f067";
    /* stylelint-disable-line string-quotes */
    font-weight: 300; }
  .m-events-filter__heading > .btn:hover::after, .m-events-filter__heading > .btn.-hover::after, .m-events-filter__heading > .btn:focus::after, .m-events-filter__heading > .btn.-focus::after {
    right: 0.8125em; }
  .m-events-filter__heading > .btn.-active::after {
    content: "\f068";
    /* stylelint-disable-line string-quotes */ }
  @media (min-width: 1024px) {
    .m-events-filter__heading {
      display: none; } }

.m-events-filter form .form-row > [class*="col-"] {
  /* stylelint-disable-line string-quotes */
  margin-bottom: 1rem; }

.m-events-filter form .form-row [class*="col-"] {
  /* stylelint-disable-line string-quotes */
  padding-bottom: 0;
  padding-top: 0; }

.m-events-filter form .form-row > *:last-child,
.m-events-filter form .form-row .form-row .form-row > [class*="col-"] {
  /* stylelint-disable-line string-quotes */
  margin-bottom: 0; }

.m-events-filter form .form-row > .col-lg-9 {
  padding-left: 1rem;
  padding-right: 1rem; }

@media (min-width: 768px) {
  .m-events-filter form .form-row {
    margin-right: -0.0625rem;
    margin-left: -0.0625rem; }
    .m-events-filter form .form-row > [class*="col-"] {
      /* stylelint-disable-line string-quotes */
      padding-right: 0.0625rem;
      padding-left: 0.0625rem; } }

.m-events-filter form .form-control:not(.query) {
  text-align: center;
  text-transform: uppercase; }

.m-events-filter form .form-control.query {
  /* stylelint-disable-line string-quotes */
  border-color: #fff; }
  .m-events-filter form .form-control.query:hover, .m-events-filter form .form-control.query.-hover, .m-events-filter form .form-control.query:focus, .m-events-filter form .form-control.query.-focus {
    border-color: #2e2c2d; }
  @media (min-width: 1024px) {
    .m-events-filter form .form-control.query {
      border-bottom-left-radius: 1.5em;
      border-top-left-radius: 1.5em; } }

.m-events-filter form .btn[type="submit"] {
  /* stylelint-disable-line string-quotes */ }
  @media (max-width: 767px) {
    .m-events-filter form .btn[type="submit"] {
      max-width: none; } }

[data-section-events-filter]:not([data-section-events-filter="opened"]) {
  /* stylelint-disable-line string-quotes */
  display: none; }

.events-filter-open [data-section-events-filter] {
  display: block; }

.account-nav a.active {
  color: #e55f5f; }

.account .article > .account-nav {
  padding: 1rem 1rem;
  background-color: #fff;
  display: block; }
  .account .article > .account-nav + * {
    margin-top: 2rem; }

.account .article .content + .aside {
  display: none; }

@media (min-width: 768px) {
  .account .article > .account-nav > ul {
    column-count: 2;
    column-gap: 2rem; } }

@media (min-width: 1024px) {
  .account .article > .account-nav {
    display: none; }
    .account .article > .account-nav + * {
      margin-top: 0; }
  .account .article .content + .aside {
    display: block; } }

.page-two-column .section-main > .container > .article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0; }
  @media (min-width: 1024px) {
    .page-two-column .section-main > .container > .article {
      flex-direction: row; } }
  .page-two-column .section-main > .container > .article .article__content {
    padding-bottom: 2rem;
    width: 100%; }
    .page-two-column .section-main > .container > .article .article__content:last-child {
      padding-bottom: 0; }
  .page-two-column .section-main > .container > .article .article__aside {
    border-color: rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-width: 0.125rem 0 0 0;
    padding-top: 2rem;
    width: 100%; }
  @media (min-width: 1024px) {
    .page-two-column .section-main > .container > .article .article__content {
      padding-bottom: 0;
      padding-right: 2rem;
      flex-basis: calc(100% - 30%);
      flex-grow: 666;
      min-width: calc(100% - 30%); }
      .page-two-column .section-main > .container > .article .article__content:last-child {
        padding-right: 0; }
    .page-two-column .section-main > .container > .article .article__aside {
      padding-left: 2rem;
      padding-top: 0;
      border-width: 0 0 0 0.125rem;
      flex-basis: 30%;
      width: 30%; }
      @supports (position: sticky) {
        .page-two-column .section-main > .container > .article .article__aside-content {
          position: sticky;
          top: var(--header-height); } } }
  @media (min-width: 1200px) {
    .page-two-column .section-main > .container > .article .article__content {
      padding-right: 4rem; }
    .page-two-column .section-main > .container > .article .article__aside {
      padding-left: 4rem; } }

.page-article .section__content > .article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0; }
  @media (min-width: 1024px) {
    .page-article .section__content > .article {
      flex-direction: row; } }
  .page-article .section__content > .article .article__content {
    padding: 1rem;
    width: 100%; }
  .page-article .section__content > .article .article__aside {
    padding: 1rem;
    border-top: 2px solid #fff;
    width: 100%; }
  @media (min-width: 576px) {
    .page-article .section__content > .article .article__content {
      padding: 2rem; }
    .page-article .section__content > .article .article__aside {
      padding: 2rem; } }
  @media (min-width: 1024px) {
    .page-article .section__content > .article .article__content {
      padding: 3rem 4rem;
      flex-basis: calc(100% - 28rem);
      flex-grow: 666;
      min-width: calc(100% - 28rem); }
    .page-article .section__content > .article .article__aside {
      padding: 3rem 2rem;
      border-left: 2px solid #fff;
      border-top: 0 none;
      flex-basis: 28rem;
      width: 28rem; }
      @supports (position: sticky) {
        .page-article .section__content > .article .article__aside-content {
          position: sticky;
          top: var(--header-height); } } }
  @media (min-width: 1400px) {
    .page-article .section__content > .article .article__content {
      padding: 4rem 6rem; }
    .page-article .section__content > .article .article__aside {
      padding: 4rem 3rem; } }
