////
/// Components / Anchors
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'anchors') {
	a {
		&.hashtag,
		&.back,
		&.external,
		&.interested,
		&.readon,
		&.cancel,
		&.feedback,
		&.share,
		&.ics {
			@include font-weight('bold');

			display        : inline-block;
			letter-spacing : 1px;
			position       : relative;

			&::before,
			&::after {
				@extend %icon-fontawesome;

				color       : color('primary-2');
				font-size   : 1.3125rem;
				font-weight : 900;
				position    : absolute;
				top         : 50%;
				transform   : translateY(-50%);
				transition  : color 0.25s;
			}

			&::before {
				left : 0;
			}

			&::after {
				right : 0;
			}

			@include on-hover-focus() {
				&::before,
				&::after {
					color      : inherit;
					transition : none;
				}
			}
		}

		&.external,
		&.interested,
		&.readon,
		&.cancel,
		&.feedback,
		&.share,
		&.back,
		&.ics {
			padding-right   : 1.75em;
			text-decoration : none;
			text-transform  : uppercase;
		}

		&.external {
			&::after {
				content    : "\f08e"; /* stylelint-disable-line string-quotes */
				font-size  : 1rem;
				margin-top : -#{rem(1px)};
			}
		}

		&.interested {
			&::after {
				content     : "\f005"; /* stylelint-disable-line string-quotes */
				font-size   : 1rem;
				font-weight : 400;
				margin-top  : -#{rem(1px)};
			}
		}

		&.readon {
			padding-right : 1.375em;

			&::after {
				content : "\f105"; /* stylelint-disable-line string-quotes */
			}
		}

		&.feedback {
			&::after {
				content : "\f075"; /* stylelint-disable-line string-quotes */
				font-weight : 400;
			}
		}

		&.share {
			&::after {
				content     : "\f064"; /* stylelint-disable-line string-quotes */
				font-size   : 0.9375rem;
				font-weight : 400;
			}
		}

		&.cancel {
			color         : color('primary-2');
			padding-right : 1.375em;

			&::after {
				content : "\f00d"; /* stylelint-disable-line string-quotes */
			}

			@include on-hover-focus() {
				color : inherit;
			}
		}

		&.back {
			padding-left : 1.375em;

			&::before {
				content : "\f104"; /* stylelint-disable-line string-quotes */
			}
		}

		&.ics {
			&::after {
				// content    : "\f783"; /* stylelint-disable-line string-quotes */
				content     : "\f33d"; /* stylelint-disable-line string-quotes */
				font-weight : 400;
			}
		}

		&.filelink {
			-webkit-hyphens : manual;
			-ms-hyphens     : manual;
			hyphens         : manual;
			overflow-wrap   : break-word;
			padding-right   : 0;
			text-decoration : none;
			word-wrap       : break-word;

			&::after {
				display : none;
			}

			.fileinfo {
				$-img   : image('icons/publications.svg');

				background      : transparent #{$-img} no-repeat left 0.25rem;
				// background-size : contain;
				background-size : auto 2.5rem;
				display         : block;
				line-height     : 1.5;
				min-height      : 3rem;
				padding-left    : 3.75rem;
				padding-right   : 0;
				position        : relative;
				text-decoration : none;

				&::before {
					position  : absolute;
					top       : 50%;
					transform : translateY(-50%);
					content : ""; /* stylelint-disable-line string-quotes */
				}

				&__text {
					text-transform  : uppercase;
					display         : inline-block;
					padding-right   : 2.5rem;
					position        : relative;
					-webkit-hyphens : manual;
					-ms-hyphens     : manual;
					hyphens         : manual;
					overflow-wrap   : break-word;
					word-wrap       : break-word;

					&::after {
						@extend %icon-fontawesome;

						color       : color('primary-2');
						content     : "\f105"; /* stylelint-disable-line string-quotes */
						font-size   : 1.375em;
						font-weight : 900;
						position    : absolute;
						right       : 0.8125em;
						top         : 50%;
						transform   : translateY(-50%);
						transition  : color 0.25s;
					}
				}

				&__size {
					@include font-weight('normal');

					color   : color('black-2');
					display : block;
				}
			}

			@include on-hover-focus() {
				.fileinfo__text::after {
					color : color('black-1');
				}
			}
		}

		&.readon,
		&.filelink {
			@include on-hover-focus() {
				text-decoration : none;
			}
		}
	}

	// Nur in `.page-main` externe Links mit Icon auszeichen!
	.page-main {
		.link-web {
			&::after {
				@extend %icon-fontawesome;

				color       : inherit;
				content     : "\f08e"; /* stylelint-disable-line string-quotes */
				font-size   : 0.625em;
				font-weight : 400;
				margin-left : get-spacing('s', $unit : em);
				position    : relative;
				top         : -0.125em;
			}
		}
	}
}
