////
/// Components / Textblock
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'textblock') {
	//
	// Standard.
	//

	.textblock {
		$-self   : &;
		$-gutter : get-spacing($textblock-gutter);

		font-size : 1rem;

		&__inner {
			display         : flex;
			flex-direction  : row;
			flex-wrap       : wrap;
			justify-content : center;
		}

		&__media,
		&__content {
			flex-basis : 100%;
			width      : 100%;

			> :first-child {
				margin-top : 0;
			}

			> :last-child {
				margin-bottom : 0;
			}
		}

		&__media {
			order : 1;

			+ #{$-self}__content {
				margin-top : $-gutter;
			}
		}

		&__content {
			order : 2;
		}

		@include media-breakpoint-up('m') {
			// Medienbreite zuweisen.
			&__media {
				flex-basis : $textblock-media-width;
				width      : $textblock-media-width;
			}

			// Position, Media kommt an zweiter Stelle, ´umswitchen´.
			&.-media-below,
			&.-media-right {
				#{$-self}__media {
					order : 2;
				}

				#{$-self}__content {
					order : 1;
				}
			}

			// Bei Media unterhalb den Abstand zum Content anpassen.
			&.-media-below {
				#{$-self}__media {
					margin-top : 1rem;
				}

				#{$-self}__content {
					margin-top : 0;
				}
			}

			// Anpassung Breitenangaben für Media ´rechts´ & ´links´.
			&.-media-left,
			&.-media-right {
				$-cw : (100% - $textblock-media-width);

				#{$-self}__media {
					flex-basis : calc(#{$textblock-media-width} - #{$-gutter});
					width      : calc(#{$textblock-media-width} - #{$-gutter});
				}

				#{$-self}__content {
					flex-basis : calc(#{$-cw} - #{$-gutter});
					flex-grow  : 666;
					margin-top : 0;
					width      : calc(#{$-cw} - #{$-gutter});
				}
			}

			// Anpassung Abstände für Media ´links´.
			&.-media-left {
				$-cw : (100% - $textblock-media-width);

				#{$-self}__media {
					margin-right : $-gutter;
				}

				#{$-self}__content {
					margin-left : $-gutter;
				}
			}

			// Anpassung Abstände für Media ´rechts´.
			&.-media-right {
				$-cw : (100% - $textblock-media-width);

				#{$-self}__media {
					margin-left : $-gutter;
				}

				#{$-self}__content {
					margin-right : $-gutter;
				}
			}
		}

		@at-root .aside & {
			&__media,
			&__content {
				flex-basis : 100%;
				width      : 100%;
			}
		}
	}
}
