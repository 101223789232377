////
/// Components / Breadcrumb
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'breadcrumb') {
	.breadcrumb {
		@include font-weight('normal');

		$-self : &;

		align-items      : center;
		background-color : transparent;
		color            : currentColor;
		border-radius    : 0;
		line-height      : 1;
		list-style       : none;
		margin           : 0;
		overflow-x       : auto;
		padding          : 0;
		white-space      : nowrap;
		width            : 100%;

		&__link {
			@include padding(0.5em 0 0.5em 1.25em);

			color           : currentColor;
			display         : block;
			position        : relative;
			text-decoration : none;

			&::before {
				@extend %icon-fontawesome;

				content     : "\f105"; /* stylelint-disable-line string-quotes */
				font-weight : 400; // 300, 400, 900
				left        : 0;
				position    : absolute;
				top         : 50%;
				transform   : translateY(-50%);
			}

			&:hover,
			&:focus {
				color : color($breadcrumb-hover-color);
			}

			&[aria-current] {
				color : color($breadcrumb-current-color);
			}
		}

		&__item {
			@include padding(0);
			@include margin(0 get-spacing('m') 0 0);

			&::before {
				display : none;
			}

			&:last-child {
				margin-right : 0;
			}

			// Home-Iconohne Text
			&.-root #{$-self}__link {
				padding-left : 0;

				> span {
					@include visually-hidden;
				}

				&::before {
					content     : "\f015"; /* stylelint-disable-line string-quotes */
					font-weight : 900;
					position    : relative;
					top         : auto;
					left        : auto;
					transform   : translateY(0);
				}
			}
		}

		@at-root &-nav {
			@include margin(get-spacing('l') 0);

			color : color($breadcrumb-color);

			&:first-child {
				margin-top : 0;
			}

			&:last-child {
				margin-bottom : 0;
			}
		}
	}
}
