////
/// Base - nur Basisdefinitionen, kein Layout, UI etc.
///
/// @author Heiko Pfefferkorn
////

html {
	box-sizing       : border-box;
	// Basis-, Standardschriftgröße setzen
	font-size        : $base-font-size;
	min-height       : 100%;
	scroll-snap-type : y mandatory;

	// @include base-font-sizing($base-font-sizing, 'font-size');
}

body {
	@include font-family('text');
	@include base-font-sizing($base-font-sizing, 'line-height');

	background : {
		color  : color('white');
		repeat : no-repeat;
		size   : cover;
	};

	color        : color(text);
	font-size    : 1rem;
	font-style   : $base-font-style;
	font-variant : $base-font-variant;
	font-weight  : $base-font-weight;
	line-height  : line-height($base-line-height);
	min-height   : 100vh;
	overflow-x   : hidden;
	position     : relative;
}

// Alle Dom-Elemente erben Box-Model von ihren Elternelementen.
// `*` hat eine Spezifität von 0 und überschreibt somit nicht `html`.
// Siehe: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
* {
	&,
	*::before,
	&::after {
		box-sizing : inherit;
	}
}

// IE10 berücksichtigt in einigen Fällen `<meta name="viewport"/>` nicht.
// Wird Bootstrap verwendet, dann braucht diese Anweisung nicht erneut gesetzt
// zu werden!
@if not is-bool($bootstrap-enabled) {
	@at-root {
		@-ms-viewport {
			width : device-width;
		}
	}
}

//
// Page anchors
//

// @keyframes target-anchor {
// 	0% {
// 		background-color: inherit;
// 	}
//
// 	20% {
// 		background-color: color('black-2');
// 	}
//
// 	30% {
// 		background-color: inherit;
// 	}
//
// 	40% {
// 		background-color: color('black-2');
// 	}
//
// 	100% {
// 		background-color: inherit;
// 	}
// }

// :target {
// 	position  : relative;
// 	animation : target-anchor 1s ease-out 0.5s both;
// }

//
// Outline focus
//

@if not is-bool($bootstrap-enabled) {
	:focus {
		@include outlineAccessibility();
	}
} @else {
	:focus,
	button:focus {
		@include outlineAccessibility();
	}
}

//
// SVG.
//

svg:not(:root) {
	overflow : hidden;
}

//
// Maximale Breite der Seite.
//

.limit-max-width {
	@include margin(0 auto);

	@include padding((
		left : get-spacing('m'),
		right : get-spacing('m')
	));

	max-width : $page-max-width;
	width     : $page-width;

	@include media-breakpoint-up('l') {
		@include padding((
			left : get-spacing('l'),
			right : get-spacing('l')
		));
	}
}

//
// Links.
//

a,
.link {
	color           : color($link-color);
	text-decoration : $link-text-decoration;
	transition      : all 0.25s;

	@include on-event() {
		color           : color($link-hover-color);
		text-decoration : $link-hover-text-decoration;
	}

	@include on-disabled() {
		color           : color($link-disabled-color);
		cursor          : not-allowed;
		outline         : 0;
		text-decoration : $link-disabled-text-decoration;
	}
}

//
// Button ´jump top of page´
//

[data-trigger="top-jump"] { /* stylelint-disable-line string-quotes */
	background-color : color('black-1');
	border           : 2px solid color('white');
	bottom           : get-spacing('m');
	color            : color('white');
	display          : none;
	height           : em(50px, $base-font-size);
	overflow         : hidden;
	position         : fixed;
	right            : get-spacing('m');
	text-decoration  : none;
	width            : em(50px, $base-font-size);
	z-index          : 550;
	transition       : none;

	&::before {
		@extend %icon-fontawesome;

		content     : "\f077"; /* stylelint-disable-line string-quotes */
		font-size   : em(20px, $base-font-size);
		font-weight : 900; // 300, 400, 900
		left        : 50%;
		position    : absolute;
		text-indent : initial;
		top         : 50%;
		transform   : translate3d(-50%, -50%, 0);
	}

	@include on-event() {
		background-color : color('black');
		color            : color('white');
	}
}

//
// Horizontal rule.
//

hr {
	@include margin(bottom, get-spacing($hr-margin));
	@include margin(top, get-spacing($hr-margin));

	border-top : $hr-border-width solid color($hr-border-color);
}

//
// Absatz.
//

p {
	// Definition unterschiedlich wenn Bootstrap verwendet wird!
	@if is-bool($bootstrap-enabled) {
		@include margin(bottom, get-spacing($paragraph-margin));
	} @else {
		@include margin(0 0 get-spacing($paragraph-margin));
	}
}

//
// Datum, Zeit
//

.datetime {
	$_self : &;

	&__date + #{$_self}__time {
		&::before {
			content : ",\002008"; /* stylelint-disable-line string-quotes */
		}
	}

	a[class] {
		@include font-weight('normal');

		letter-spacing : normal;
		line-height    : 1;
		padding-right  : 1.5em;
		text-transform : none;
	}
}

//
// Tabellen.
//

table {
	width : 100%;
}

img {
	border    : 0;
	display   : block;
	height    : auto;
	max-width : 100%;
}

//
// Bootstrap :(
//

// `.close`
.close {
	opacity : 0.6;

	&:not(:disabled):not(.disabled) {
		@include on-hover-focus() {
			opacity : 1;
		}
	}
}

// Komponent-, Modulclass
[data-c] + [data-c] {
	margin-top : get-spacing('m');

	@include media-breakpoint-up('m') {
		margin-top : get-spacing('l');
	}
}
