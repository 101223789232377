////
/// Components / Card
///
/// @group Components
/// @author Heiko Pfefferkorn
////

// body {
//	@supports not (display: grid) {
//		background-color : red;
//	}
// }

@if map-get($components-in-use, 'card') {
	//
	// Standard.
	//
	// - Media
	// - Header
	// - Body
	// - Meta
	// - Footer
	//

	.card {
		$-card           : &;
		$-gap            : get-spacing('m');

		background-color : rgba(color('black'), 0.05);
		border           : 0 none;
		border-radius    : 0;
		display          : flex;
		flex-direction   : column;
		position         : relative;

		> * {
			flex : 0 0 auto;
		}

		> .figure {
			margin  : 0;
		}

		&__body {
			border-top : 0 none;
			flex       : 1 1 auto;
			padding    : $-gap;

			> *:first-child {
				margin-top : 0;
			}

			> *:last-child {
				margin-bottom : 0;
			}

			a.read-on {
				&::before {
					background-color : color('secondary');
					content          : ""; /* stylelint-disable-line string-quotes */
					height           : 100%;
					left             : 0;
					opacity          : 0;
					position         : absolute;
					top              : 0;
					transition       : opacity 0.25s;
					width            : 100%;
				}

				@include on-hover-focus() {
					&::before {
						opacity : 0.05;
					}
				}
			}
		}
	}

	.card-collection {
		$-gap      : 2rem;
		$-gap-half : ($-gap / 2);

		&.-has-pagination {
			padding-bottom    : 2rem;
			position          : relative;
			scroll-margin-top : var(--offset-scroll-top);

			.pagination-nav {
				bottom   : 0;
				left     : 0;
				margin   : 0;
				position : absolute;
				width    : 100%;
			}
		}

		.card {
			&:not(:last-child) {
				margin-bottom : 1rem;
			}

			> .figure {
				display : none;
			}
		}

		@include media-breakpoint-up('xs') {
			.card:not(:last-child) {
				margin-bottom : 2rem;
			}
		}

		// 2-spaltige Darstellung
		@include media-breakpoint-up('m') {
			align-content   : stretch;
			align-items     : stretch;
			display         : flex;
			flex-direction  : row;
			flex-wrap       : wrap;
			justify-content : flex-start;
			margin-left     : -#{$-gap-half};
			margin-right    : -#{$-gap-half};

			.card {
				@include margin($-gap $-gap-half 0);

				flex       : 0 1 calc(50% - #{$-gap});
				width      : calc(50% - #{$-gap});
				align-self : auto;

				// `margin-top` der ersten beiden `.card` zurücksetzen
				&:nth-child(-n+2) {
					margin-top : 0;
				}

				> .figure {
					display : block;
				}
			}
		}

		// 3-spaltige Darstellung
		@include media-breakpoint-up('l') {
			.card {
				flex  : 0 1 calc(33.3333333333% - #{$-gap});
				width : calc(33.3333333333% - #{$-gap});

				// `margin-top` der ersten drei `.card` zurücksetzen
				&:nth-child(-n+3) {
					margin-top : 0;
				}
			}

			/*
			// 3-Spalter
			grid-template-columns : 1fr 1fr 1fr;
			*/
		}

		// 4-spaltige Darstellung
		@include media-breakpoint-up('xl') {
			.card {
				flex  : 0 1 calc(25% - #{$-gap});
				width : calc(25% - #{$-gap});

				// `margin-top` der ersten vier `.card` zurücksetzen
				&:nth-child(-n+4) {
					margin-top : 0;
				}
			}
		}
	}
}
