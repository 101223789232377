////
/// Seite: Two Column
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.page-two-column .section-main > .container > .article {
	display        : flex;
	flex-wrap      : wrap;
	flex-direction : column;
	padding        : 0;

	@include media-breakpoint-up('l') {
		flex-direction : row;
	}

	.article__ {
		&content {
			padding-bottom : 2rem;
			width          : 100%;

			&:last-child {
				padding-bottom : 0;
			}
		}

		&aside {
			border-color : rgba(color('black'), 0.05);
			border-style : solid;
			border-width : rem(2px) 0 0 0;
			padding-top  : 2rem;
			width        : 100%;
		}

		@include media-breakpoint-up('l') {
			$-sidebar-width : 30%;

			&content {
				@include padding((
					bottom : 0,
					right  : 2rem
				));

				flex-basis    : calc(100% - #{$-sidebar-width});
				flex-grow     : 666;
				min-width     : calc(100% - #{$-sidebar-width});

				&:last-child {
					padding-right : 0;
				}
			}

			&aside {
				@include padding((
					left : 2rem,
					top  : 0
				));

				border-width : 0 0 0 rem(2px);
				flex-basis   : $-sidebar-width;
				width        : $-sidebar-width;

				&-content {
					@supports (position: sticky) {
						position : sticky;
						top      : var(--header-height);
					}
				}
			}
		}

		@include media-breakpoint-up('xl') {
			&content {
				padding-right : 4rem;
			}

			&aside {
				padding-left : 4rem;
			}
		}
	}
}
