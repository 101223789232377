////
/// Components / Page search
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.page-search {
	@include padding(0);

	color            : color('white');
	align-items      : center;
	background-color : color('primary');
	display          : flex;

	// Höhe des Seitenkopfes
	height           : var(--header-height);
	overflow         : hidden;
	position         : fixed;
	text-align       : center;
	top              : 0;
	transform        : translateY(-100%);
	transition       : transform 0.3s;
	width            : 100%;
	z-index          : 55;

	.search-form {
		background-color : transparent;
		border           : 1px solid rgba(255, 255, 255, 0.15);
		margin           : 0;

		> * {
			color : inherit;
		}

		&__input {
			@include font-weight('light');
			@include padding(get-spacing('s') 0);

			background-color : transparent;
			border           : 0 none;
			color            : currentColor;
			font-size        : inherit;
			height           : auto;
			text-align       : center;

			@include on-hover-focus() {
				outline          : none;
				background-color : rgba(color('white'), 0.1);
			}

			&::placeholder {
				color : rgba(color('white'), 0.3);
			}
		}

		@include media-breakpoint-up('m') {
			@include font-size('l');
		}
	}

	@include media-breakpoint-up('xxl') {
		@include font-size('xl');
	}

	@at-root .show-page-search & {
		transform : translateY(0);
	}
}
