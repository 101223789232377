////
/// Mixins / Spacing
////

/// Außenabstände eines Elementes setzen.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    private
/// @since     1.0.0
/// @group     layout
/// @parameter {string} $type - `padding` oder `margin`
/// @parameter {mixed} $properties
/// @parameter {map | list} $values
/// @require   {function} contains
/// @require   {function} map-get-deep
/// @require   {function} unit-separator
@mixin spacing($type, $properties, $values...) {
	@if not contains('margin' 'padding', $type) {
		@warn 'Ungültige Eigenschaft `#{$type}` (Mixin `space-...`)';
	} @else {
		$pos : ('top' 'right' 'bottom' 'left');

		@if type-of($properties) == 'map' {
			@each $prop in map-keys($properties) {
				$prop : unquote($prop);
				$v    : map-get($properties, $prop);

				#{$type}-#{$prop} : $v;
			}
		} @else if type-of($properties) == 'string' {
			@if contains($pos, $properties) {
				#{$type}-#{$properties} : nth($values, 1);
			} // @else {}
		} @else {
			$result    : ();
			$separator : unit-separator($properties);

			@each $prop in $properties {
				// Einheit bei 0-Werten entfernen.
				@if ($prop == 0) {
					$prop : strip-unit($prop);
				}

				$result : append($result, $prop, 'space');
			}

			#{$type} : $result;
		}
	}
}

/// Innenabstände eines Elementes setzen.
///
/// @example scss
///   .space-inset-left {
///     @include space-inset(left, 8px);
///   }
///
///   .space-inset {
///     @include space-inset(16px 8px);
///   }
///
///   .space-inset-multiple {
///     @include space-inset((
///       left  : 8px,
///       right : 8px
///     ));
///   }
///
/// @example css - Result
///   .space-inset-left {
///     padding-left : 8px;
///   }
///
///   .space-inset {
///     padding : 16px 8px;
///   }
///
///   .space-inset-multiple {
///     padding-left  : 8px;
///     padding-right : 8px;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {string | map | list} $values
/// @require   {mixin} spacing
@mixin space-inset($values...) {
	@include spacing(padding, $values...);
}

/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @alias   space-inset
/// @group   layout
/// @require {mixin} space-inset
@mixin padding($values...) {
	@include space-inset($values...);
}

/// Außenabstände eines Elementes setzen.
///
/// @example scss
///   .space-stack-left {
///     @include space-stack(left, 8px);
///   }
///
///   .space-stack {
///     @include space-stack(16px 8px);
///   }
///
///   .space-stack-multiple {
///     @include space-stack((
///       left  : 8px,
///       right : 8px
///     ));
///   }
///
/// @example css - Result
///   .space-stack-left {
///     margin-left : 8px;
///   }
///
///   .space-stack {
///     margin : 16px 8px;
///   }
///
///   .space-stack-multiple {
///     margin-left  : 8px;
///     margin-right : 8px;
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {string | map | list} $values
/// @require   {mixin} spacing
@mixin space-stack($values...) {
	@include spacing(margin, $values...);
}

/// @author  [Heiko Pfefferkorn](https://ifabrik.de)
/// @access  public
/// @since   1.0.0
/// @alias   space-stack
/// @group   layout
/// @require {mixin} space-stack
@mixin margin($values...) {
	@include space-stack($values...);
}

/// Abstände eines Elementes auf Basis von Breakpoints setzen.
///
/// @example scss
///   $map : (
///     's' : 8px,
///     'l' : 32px
///   );
///
///   .container-p {
///     @include breakpoint-spacing('padding', $map);
///   }
///
///   .container-p-h {
///     @include breakpoint-spacing('padding', $map, true, false);
///   }
///
///   .container-m {
///     @include breakpoint-spacing('margin', $map);
///   }
///
///   .container-m-v {
///     @include breakpoint-spacing('margin', $map, false);
///   }
///
/// @example css - Result
///   .container-p {
///     @media (min-width: 576px)
///       padding : 0.5rem;
///     }
///
///     @media (min-width: 992px)
///       padding : 2rem;
///     }
///   }
///
///   .container-p-h {
///     @media (min-width: 576px)
///       padding-left  : 0.5rem;
///       padding-right : 0.5rem;
///     }
///
///     @media (min-width: 992px)
///       padding-left  : 2rem;
///       padding-right : 2rem;
///     }
///   }
///
///   .container-m {
///     @media (min-width: 576px)
///       margin : 0.5rem;
///     }
///
///     @media (min-width: 992px)
///       margin : 2rem;
///     }
///   }
///
///   .container-m-v {
///     @media (min-width: 576px)
///       margin-bottom : 0.5rem;
///       margin-top    : 0.5rem;
///     }
///
///     @media (min-width: 992px)
///       margin-bottom : 2rem;
///       margin-top    : 2rem;
///     }
///   }
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     layout
/// @parameter {string} $property
/// @parameter {map} $map - (Breakpointkey : Spacing, ...)
/// @parameter {bool} $setX [true] - horizontale Innenabstände einbinden
/// @parameter {bool} $setY [true] - vertikale Innenabstände einbinden
/// @parameter {bool} $mobileFirst [true] - bestimmt die Art der Integration von Breakpoints
/// @require   {mixin} media-breakpoint-up
/// @require   {mixin} media-breakpoint-down
/// @require   {function} contains
/// @require   {function} get-spacing
/// @require   {function} is-map
/// @require   {function} is-bool
@mixin spacing-breakpoints($property, $map, $setX : true, $setY: true, $mobileFirst: true) {
	@if $property and is-map($map) and contains('margin' 'padding', $property) {
		@each $breakpoint, $val in $map {
			$val : get-spacing($val);

			@if $mobileFirst == true {
				@include media-breakpoint-up($breakpoint) {
					@if is-bool($setX) and is-bool($setY) {
						// horizontales und vertikales Spacing
						#{$property} : $val;
					} @else {
						@if is-bool($setX) {
							// horizontales Spacing
							#{$property}-left  : $val;
							#{$property}-right : $val;
						}

						@if is-bool($setY) {
							// vertikales Spacing
							#{$property}-bottom : $val;
							#{$property}-top    : $val;
						}
					}
				}
			} @else {
				@include media-breakpoint-down($breakpoint) {
					@if is-bool($setX) and is-bool($setY) {
						// horizontales und vertikales Spacing
						#{$property} : $val;
					} @else {
						@if is-bool($setX) {
							// horizontales Spacing
							#{$property}-left  : $val;
							#{$property}-right : $val;
						}

						@if is-bool($setY) {
							// vertikales Spacing
							#{$property}-bottom : $val;
							#{$property}-top    : $val;
						}
					}
				}
			}
		}
	}
}
