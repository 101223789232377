////
/// Components / Form / Contrlol (Input, Select, Textarea)
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.form-control {
	@if variable-exists(form-control-font-size) {
		@include font-size($form-control-font-size);
	}

	@if variable-exists(form-control-font-weight) {
		@include font-size($form-control-font-weight);
	}

	background-color : color($form-control-bg-color);
	border-color     : color($form-control-border-color);
	border-radius    : 0;
	color            : color($form-control-color);
	height           : calc(#{$base-line-height} + 1rem);
	line-height      : $base-line-height;

	&:focus {
		background-color : color($form-control-focus-bg-color);
		border-color     : color($form-control-focus-border-color);
		color            : color($form-control-focus-color);
		box-shadow       : none;
	}

	// Placeholder
	&::placeholder {
		color : color($form-control-placeholder-color);
	}

	// Disabled, Read only
	&:disabled,
	&[readonly] {
		background-color : color($form-control-disabled-bg-color);
		border-color     : color($form-control-disabled-border-color);
		color            : color($form-control-disabled-color);
	}
}

