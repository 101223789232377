////
/// Seite: Artikel
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.page-article .section__content > .article {
	display        : flex;
	flex-wrap      : wrap;
	flex-direction : column;
	padding        : 0;

	@include media-breakpoint-up('l') {
		flex-direction : row;
	}

	.article__ {
		&content {
			@include padding(get-spacing('m'));

			width : 100%;
		}

		&aside {
			@include padding(get-spacing('m'));

			border-top : 2px solid color('white');
			width      : 100%;
		}

		@include media-breakpoint-up('s') {
			&content {
				@include padding(2rem);
			}

			&aside {
				@include padding(2rem);
			}
		}

		@include media-breakpoint-up('l') {
			$-sidebar-width : 28rem;

			&content {
				@include padding(3rem 4rem);

				flex-basis : calc(100% - #{$-sidebar-width});
				flex-grow  : 666;
				min-width  : calc(100% - #{$-sidebar-width});
			}

			&aside {
				@include padding(3rem 2rem);

				border-left : 2px solid color('white');
				border-top  : 0 none;
				flex-basis  : $-sidebar-width;
				width       : $-sidebar-width;

				&-content {
					@supports (position: sticky) {
						position : sticky;
						top      : var(--header-height);
					}
				}
			}
		}

		@include media-breakpoint-up('xxl') {
			&content {
				@include padding(4rem 6rem);
			}

			&aside {
				@include padding(4rem 3rem);
			}
		}
	}
}
