////
/// Durchzuführende Aktionen, Definitionen vor allen anderen Ausgaben etc.!
///
/// @author Heiko Pfefferkorn
////

//
// Custom-Variable(n).
//

:root {
	// Angaben aus `$root` in `:root` bereitstellen.
	@each $key, $val in $global-root {
		--#{$key} : #{$val};
	}

	// Header height
	--header-height : 1px;

	// Footer height
	--footer-height : 1px;

	// Basisabstand von Containern.
	--spacing : #{get-spacing('m')};

	// Scroll top offset (benötigt wg. fixed Header).
	--offset-scroll-top : calc(var(--header-height) + var(--spacing));

	@include media-breakpoint-up('xs') {
		// Basisabstand von Containern anpassen.
		--spacing : #{get-spacing('l')};
	}
}

//
// Farbliche Hervorhebung von selektiertem Text.
//

::selection {
	background-color : color('selection-bg-color');
	color            : color('selection-color');
}
