////
/// Mixins / Alert
////

/// Alertvarianten generieren.
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    private
/// @since     1.0.0
/// @group     components
/// @parameter {color | string} $color - Textfarbe
/// @parameter {color | string} $bg - Hintergrundfarbe
/// @parameter {color | string} $border - Rahmenfarbe
/// @require   {function} color
@mixin alert-variant($color, $bg : null, $border: null, $bg-opacity : 0.2, $border-opacity : 0.1) {
	$color  : color($color);

	@if not is-numeric($bg) and not is-null($bg) and not is-bool($bg) {
		$bg : color($bg);
	} @else {
		$bg : rgba($color, $bg-opacity);
	}

	@if not is-numeric($border) and not is-null($border) and not is-bool($border) {
		$border : color($border);
	} @else {
		$border : rgba($color, $border-opacity);
	}

	background-color : $bg;
	border-color     : $border;
	color            : $color;

	hr {
		border-top-color : $border;
	}

	a,
	.alert-link {
		color : inherit;
	}
}
