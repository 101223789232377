////
/// Base - Typography.
///
/// @author Heiko Pfefferkorn
////

//
// Überschriften.
//

// Überschriften von `<h1/>` bis `<h6/>`.
$-sh-all : build-heading-selectors();

#{$-sh-all} {
	@include font-family($heading-font-family);
	@include font-weight($heading-font-weight);
	@include margin(0 0 get-spacing('l'));

	clear     : both;
	color     : color($heading-color);
	display   : block;
	font-size : em($heading-font-size, $base-font-size);
	position  : relative;
	width     : 100%;
}

h1,
.h1 {
	@if variable-exists(h1-font-size) {
		font-size : em($h1-font-size, $base-font-size);

		@include media-breakpoint-up('m') {
			font-size : em(($h1-font-size + 6), $base-font-size);
		}

		@include media-breakpoint-up('l') {
			font-size : em(($h1-font-size + 12), $base-font-size);
		}
	}
}

h2,
.h2 {
	@if variable-exists(h2-font-size) {
		font-size : em($h2-font-size, $base-font-size);

		@include media-breakpoint-up('m') {
			font-size : em(($h2-font-size + 4), $base-font-size);
		}

		@include media-breakpoint-up('l') {
			font-size : em(($h2-font-size + 6), $base-font-size);
		}
	}
}

h3,
.h3 {
	@if variable-exists(h3-font-size) {
		font-size : em($h3-font-size, $base-font-size);

		@include media-breakpoint-up('m') {
			font-size : em(($h3-font-size + 1), $base-font-size);
		}

		@include media-breakpoint-up('l') {
			font-size : em(($h3-font-size + 2), $base-font-size);
		}
	}
}

h4,
.h4 {
	@if variable-exists(h4-font-size) {
		@include font-size($h4-font-size);
	}
}

h5,
.h5 {
	@if variable-exists(h5-font-size) {
		@include font-size($h5-font-size);
	}
}

h6,
.h6 {
	@if variable-exists(h6-font-size) {
		@include font-size($h6-font-size);
	}
}

//
// Emphasis.
//

em,
.em {
	font-style: italic;
}

small,
.small {
	@include font-size($small-font-size);
}

mark,
.mark {
	@include padding(get-spacing('xxxs') get-spacing('xs'));

	@if variable-exists(mark-bg-color) {
		background-color : color($mark-bg-color);
	}

	@if variable-exists(mark-color) {
		color : color($mark-color);
	}
}

b,
strong,
.strong {
	@include font-weight('bold');
}

//
// Textfarben
//

.text {
	&-light {
		color : color('grey-light');
	}

	&-danger {
		color : color('danger');
	}

	&-info {
		color : color('info');
	}

	&-success {
		color : color('success');
	}

	&-warning {
		color : color('warning');
	}
}

.required-sign {
	color : color('danger');
}

//
// Untertitel.
//

.subtext {
	@include font-weight('normal');

	font-size : em(font-size('l'), $base-font-size);
	margin    : 0;
}

//
// Blockquotes.
//

.blockquotes {
	@include margin(0 0 get-spacing($blockquote-margin));

	@if variable-exists(blockquote-font-size) {
		@include font-size($blockquote-font-size);
	}
}

//
// Code.
//

code {
	@if variable-exists(code-font-size) {
		@include font-size($code-font-size);
	}

	background-color : color($code-bg-color);
	color            : color($code-color);
	padding-left     : 0.25em;
	padding-right    : 0.25em;
}

pre {
	@if variable-exists(pre-font-size) {
		@include font-size($pre-font-size);
	}

	@include padding(get-spacing('m'));

	background-color : color($pre-bg-color);
	color            : color($pre-color);
	max-height       : $pre-scrollable-max-height;
	padding          : 1rem;
	width: calc(100vw - 2rem);

	code {
		font-size : inherit;
		color     : inherit;
	}

	@include media-breakpoint-up('xs') {
		width: calc(100vw - 4rem);
	}

	@include media-breakpoint-up('l') {
		@include padding(get-spacing('l'));
	}
}
