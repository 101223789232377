////
/// Components / Slider
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

$-swiper-base-class : swiper;

.slider {
	position: relative;

	.#{$-swiper-base-class} {
		&-button-next,
		&-button-prev,
		&-custom-fraction {
			display : none;
		}

		&-pagination {
			bottom      : auto;
			left        : auto;
			line-height : 1;
			padding-top : 1rem;
			position    : relative;
			margin      : 0 auto;

			&-bullet {
				background : color('black-30');
				height     : rem(10px);
				outline    : 0;
				transition : all 0.3s;
				width      : rem(10px);

				&-active {
					background : color('primary');
				}

				&s > .#{$-swiper-base-class}-pagination-bullet {
					@include margin((
						left : get-spacing('s'),
						right: get-spacing('s')
					));
				}
			}
		}

		&-button- {
			&next,
			&prev {
				background-color : color('primary');
				bottom           : 1rem;
				color            : color('white');
				font-size        : 2.25rem;
				height           : 1em;
				width            : 1em;

				&::after {
					font-family : 'Font Awesome 5 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
					font-size   : 0.75rem;
					font-weight : 900; // 300, 400, 900;
					left        : 50%;
					position    : absolute;
					text-indent : initial;
					top         : 50%;
					transform   : translate3d(-50%, -50%, 0);
				}
			}

			&next {
				right : 0;

				&::after {
					content : "\f054"; /* stylelint-disable-line string-quotes */
				}
			}

			&prev {
				left : 0;

				&::after {
					content : "\f053"; /* stylelint-disable-line string-quotes */
				}
			}
		}

		@include media-breakpoint-up('m') {
			&-pagination {
				display : none;
			}

			&-button-next,
			&-button-prev,
			&-custom-fraction {
				display : block;
			}
		}

		&-slide__inner {
			> :first-child {
				margin-top : 0;
			}

			> :last-child {
				margin-bottom : 0;
			}
		}
	}

	.figure__caption {
		display : none;
	}

	&.-is-initialized [data-viewport-layer] {
		display : none;
	}
}
