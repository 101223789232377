////
/// Components / Nav
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

@keyframes nav-fade-in {
	from {
		opacity   : 0;
		transform : translate3d(-50%, -2rem, 0);
	}

	to {
		opacity   : 1;
		transform : translate3d(-50%, 0, 0);
	}
}

.nav {
	@include font-weight('normal');

	$-nav : &;

	color : $nav-color;

	&,
	ul,
	li {
		padding    : 0;
		margin     : 0;
		list-style : none;

	}

	&__item {
		&::before {
			content : ""; /* stylelint-disable-line string-quotes */
			display : none;
		}

		#{$-nav} {
			@include absolute();

			// display : block;
			left   : 0;
			margin : 0;
			top    : 100%;
		}

		// Untermenü einblenden
		&:hover,
		&.focus-within,
		&.-touched {
			> #{$-nav} {
				animation           : nav-fade-in 0.15s linear;
				animation-fill-mode : forwards;
				display             : block;
			}
		}

		// Untermenü einblenden
		// (Angabe separiert, IE ignoriert sonst obige CSS-Regel komplett!)
		&:focus-within {
			> #{$-nav} {
				animation           : nav-fade-in 0.15s linear;
				animation-fill-mode : forwards;
				display             : block;
			}
		}
	}

	&__link {
		@include padding(get-spacing('s') get-spacing('m'));

		color           : currentColor;
		display         : block;
		position        : relative;
		text-decoration : none;

		&-icon {
			display : none;
			height  : 1em;
			width   : auto;
		}

		&:hover,
		&:focus,
		&.-touched {
			color           : color($nav-hover-color);
			text-decoration : none;

			// Untermenü einblenden
			+ #{$-nav} {
				animation           : nav-fade-in 0.15s linear;
				animation-fill-mode : forwards;
				display             : block;
			}
		}

		@include on-active() {
			color           : color($nav-active-color);
			text-decoration : none;
		}

		@include on-disabled() {
			color           : color($nav-disabled-color);
			text-decoration : none;
		}
	}

	.nav {
		@include absolute();

		display     : none;
		font-size   : 1rem;
		left        : 50%;
		margin      : 0;
		min-width   : 100%;
		opacity     : 0;
		top         : 100%;
		transform   : translate3d(-50%, -2rem, 0);
		transition  : transform 0.3s, opacity 0.1s;
		white-space : nowrap;
	}
}

// @if map-get($components-in-use, 'nav') {
//	.nav {
//		&-nav-item {
//			&::before {
//				content : ""; /* stylelint-disable-line string-quotes */
//				display : none;
//			}
//		}
//
//		&-pills,
//		&-tabs {
//			.nav-link {
//				text-decoration : none;
//			}
//		}
//	}
//
//	.c-nav {
//		// Schriftfamilie?
//		@if variable-exists(c-nav-font-family) {
//			@include font-family($c-nav-font-family);
//		}
//
//		// Schriftgröße?
//		@if variable-exists(c-nav-font-size) {
//			@include font-size($c-nav-font-size);
//		}
//
//		// Schriftstärke?
//		@if variable-exists(c-nav-font-weight) {
//			@include font-weight($c-nav-font-weight);
//		}
//
//		align-content   : stretch;
//		align-items     : stretch;
//		display         : flex;
//		flex-wrap       : wrap;
//		justify-content : flex-start;
//
//		&,
//		&__item {
//			@include margin(0);
//			@include padding(0);
//
//			list-style : none;
//
//			&::before {
//				content : ""; /* stylelint-disable-line string-quotes */
//				display : none;
//			}
//		}
//
//		&__item {
//			@include relative();
//
//			align-self : auto;
//			flex       : 0 1 auto;
//
//			&::before {
//				display : none;
//			}
//
//			ul {
//				display : none;
//			}
//
//			@include on-hover-focus() {
//				> .c-nav__link {
//					color            : color($c-nav-link-hover-color);
//					background-color : color($c-nav-link-hover-bg);
//					text-decoration  : none;
//				}
//			}
//
//			@include on-active() {
//				> .c-nav__link {
//					color            : color($c-nav-link-active-color);
//					background-color : color($c-nav-link-active-bg);
//					text-decoration  : none;
//				}
//			}
//
//			@include on-disabled() {
//				> .c-nav__link {
//					color            : color($c-nav-link-disabled-color);
//					background-color : color($c-nav-link-disabled-bg);
//					text-decoration  : none;
//				}
//			}
//		}
//
//		&__link {
//			@include padding(get-spacing($c-nav-link-padding-y, true, 'em'),  get-spacing($c-nav-link-padding-x, true, 'em'));
//			@include relative();
//
//			color            : color($c-nav-link-color);
//			display          : block;
//			background-color : color($c-nav-link-bg);
//			text-decoration  : none;
//
//			@include on-hover-focus() {
//				color            : color($c-nav-link-hover-color);
//				background-color : color($c-nav-link-hover-bg);
//				text-decoration  : none;
//			}
//
//			@include on-active() {
//				color            : color($c-nav-link-active-color);
//				background-color : color($c-nav-link-active-bg);
//				text-decoration  : none;
//			}
//
//			@include on-disabled() {
//				color            : color($c-nav-link-disabled-color);
//				background-color : color($c-nav-link-disabled-bg);
//				text-decoration  : none;
//			}
//		}
//	}
// }
