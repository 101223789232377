////
/// Components / Figure
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.figure {
	// spätere Speicherung `parent selector` für `__media`
	$-media : null;

	background-color : transparent;
	border           : 0 none;
	border-radius    : 0;
	display          : block;
	padding          : 0;
	position         : relative;
	width            : 100%;

	img {
		max-width : none;
		width     : 100%;
	}

	&__media {
		// `parent selector` speichern
		$-media : &;

		background: {
			position : top center;
			repeat   : no-repeat;
			size     : cover;
		};

		display  : block;
		overflow : hidden;
		position : relative;
		z-index  : 1;

		// Kindelement (kein `<script/>` wie bei Video)!
		> *:not(script) {
			border      : 0;
			display     : block;
			height      : auto;
			line-height : 1;
			overflow    : hidden;
			width       : 100%;
		}

		// Direkt folgendes Element (kein `<script/>` wie bei Video)!
		+ *:not(script):not(.figure__controls) {
			margin-top : get-spacing('s');
		}
	}

	// Wenn `__media` ein Link ist Zoom-Icon einblenden.
	a#{$-media} {
		> img {
			transition : transform 0.5s ease-in-out;
		}

		&::after {
			@extend %icon-fontawesome;

			color       : color('white');
			content     : "\f065"; /* stylelint-disable-line string-quotes */
			font-weight : 300;
			font-size   : 2rem;
			left        : 50%;
			opacity     : 0;
			position    : absolute;
			top         : 50%;
			transform   : translate3d(-50%, -50%, 0);
			transition  : map-get($transitions, 'fade');
			z-index     : 2;
		}

		@include on-hover() {
			> img {
				transform : scale(1.025);
			}
		}

		@include on-hover-focus() {
			&::after {
				opacity : 0.8;
			}
		}
	}

	&__caption {
		// Schriftfamilie?
		@if variable-exists(c-figure-caption-font-family) {
			@include font-family($c-figure-caption-font-family);
		}

		// Schriftgröße?
		@if variable-exists(c-figure-caption-font-size) {
			@include font-size($c-figure-caption-font-size);
		}

		align-items    : stretch;
		color          : color($c-figure-caption-color);
		display        : flex;
		flex-direction : column;
		flex-wrap      : nowrap;

		> * {
			flex : 0 1 auto;
		}

		&-text {
			order : 1;
		}

		&-copyright {
			order : 2;

			&[data-label]:not([data-label='']) {
				&::before {
					content : "(" attr(data-label) " "; /* stylelint-disable-line string-quotes */
				}

				&::after {
					content : ")";  /* stylelint-disable-line string-quotes */
				}
			}
		}
	}

	&__controls {
		background-color           : color('white');
		border-bottom-right-radius : get-border-radius($c-button-border-radius, true, 'em');
		display                    : flex;
		justify-content            : flex-start;
		left                       : 0;
		overflow                   : hidden;
		padding                    : 0;
		position                   : absolute;
		top                        : 0;
		z-index                    : 5;

		> [data-control] {
			@include font-size('s');
			@include margin(0 get-spacing('xs'));
			@include padding(0);

			background-color : transparent;
			color            : color('primary');
			flex             : 0 1 auto;
			height           : 2rem;
			line-height      : 2;
			order            : 2;
			text-align       : center;
			white-space      : nowrap;
			width            : 2rem;

			/* stylelint-disable string-quotes */
			&[href]::after {
				content : "";
				display : none;
			}
			/* stylelint-enable */

			&:first-child {
				margin-left : 0;
			}

			&:last-child {
				margin-right : 0;
			}

			&[data-control='zoom'] {
				order : 1;
			}

			&:hover {
				background-color : color('secondary');
				color            : color('primary');
			}
		}
	}
}
