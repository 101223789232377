////
/// Components / Form Label
///
/// @group Components
/// @author Heiko Pfefferkorn
////

label,
.col-form-label {
	@include on-focus() {
		@include outlineAccessibility();
	}

	// Schriftgröße?
	@if variable-exists(label-font-size) {
		@include font-size($label-font-size);
	}

	color         : color($label-color);
	margin-bottom : get-spacing('xs');

	@include media-breakpoint-up('m') {
		font-size : 1rem;
	}
}
