////
/// Module / Events Filter
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.m-events-filter {
	&__heading {
		display    : block;
		text-align : center;

		> .btn {
			&::after {
				content     : "\f067"; /* stylelint-disable-line string-quotes */
				font-weight : 300;
			}

			@include on-hover-focus() {
				&::after {
					right : 0.8125em;
				}
			}

			&.-active {
				&::after {
					content : "\f068"; /* stylelint-disable-line string-quotes */
				}
			}
		}

		@include media-breakpoint-up('l') {
			display : none;
		}
	}

	// &__container {}

	form {
		.form-row {
			// margin : 0;

			> [class*="col-"] { /* stylelint-disable-line string-quotes */
				margin-bottom : get-spacing('m');
			}

			[class*="col-"] { /* stylelint-disable-line string-quotes */
				padding-bottom : 0;
				padding-top    : 0;
			}

			> *:last-child,
			.form-row .form-row > [class*="col-"] { /* stylelint-disable-line string-quotes */
				margin-bottom : 0;
			}

			> .col-lg-9 {
				padding-left  : get-spacing('m');
				padding-right : get-spacing('m');
			}

			@include media-breakpoint-up('m') {
				margin-right : -0.0625rem;
				margin-left  : -0.0625rem;

				> [class*="col-"] { /* stylelint-disable-line string-quotes */
					padding-right : 0.0625rem;
					padding-left  : 0.0625rem;
				}
			}
		}

		.form-control {
			&:not(.query) {
				text-align     : center;
				text-transform : uppercase;
			}

			&.query { /* stylelint-disable-line string-quotes */
				border-color : color('white');

				@include on-hover-focus() {
					border-color : color('secondary');
				}

				@include media-breakpoint-up('l') {
					border-bottom-left-radius : 1.5em;
					border-top-left-radius    : 1.5em;
				}
			}
		}

		.btn[type="submit"] { /* stylelint-disable-line string-quotes */
			@include media-breakpoint-down('s') {
				max-width : none;
			}
		}
	}
}

[data-section-events-filter]:not([data-section-events-filter="opened"]) { /* stylelint-disable-line string-quotes */
	display : none;
}

.events-filter-open [data-section-events-filter] {
	display : block;
}
