////
/// Layout / Footer
///
/// @author Heiko Pfefferkorn
////

.page-footer {
	@include padding((
		bottom: get-spacing('m'),
		top   : get-spacing('m')
	));

	background-color : color('secondary');
	color            : color('white');


}
