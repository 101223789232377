////
/// Components / Logo (Website Logo)
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.page-logo {
	backface-visibility : hidden;
	display             : inline-block;
	margin              : 0;
	overflow            : hidden;
	position            : relative;
	height              : 1em;

	> img {
		height : 100%;
		width  : auto;
	}



	&__text {
		$-img   : image($page-logo-path);
		$-img-w : (calc-aspect-ratio($page-logo-width, $page-logo-height, 0, $base-font-size) + 0px); /* stylelint-disable-line length-zero-no-unit  */

		background      : transparent #{$-img} no-repeat 0 0;
		background-size : contain;
		display         : block;
		font-size       : inherit;
		height          : 0;
		overflow        : hidden;
		padding-top     : em($base-font-size, $base-font-size);
		text-indent     : 150%;
		white-space     : nowrap;
		width           : em($-img-w, $base-font-size);
	}
}
