////
/// Components / Privacy Info
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.privacy-info {
	@include padding(get-spacing('m'));

	background-color : color('secondary');
	color            : color('black-20');
	font-size        : 1rem;

	&__text {
		color     : currentColor;
		margin    : 0;
		font-size : 0.875em;

		&::before {

		}
	}

	&__btn {
		@include padding(get-spacing('xs') get-spacing('m'));

		background-color : rgba(color('white'), 0.1);
		border           : 0;
		color            : currentColor;
		display          : block;
		margin           : get-spacing('m') auto 0;

		@include on-hover-focus() {
			background-color : rgba(color('white'), 0.3);
		}
	}

	@include media-breakpoint-down('xs') {
		&__btn {
			display : block;
			width   : 100%;
		}
	}

	@include media-breakpoint-up('m') {
		@include padding(get-spacing('l'));
	}
}
