////
/// Components / Clear Input
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.form-control-clearable {
	display  : block;
	position : relative;
	width    : 100%;

	> [data-clearable-trigger] {
		background-color : transparent;
		border           : 0 none;
		color            : currentColor;
		font-size        : 0.9375rem;
		height           : auto;
		line-height      : 1;
		margin           : 0;
		padding          : 0.5rem 0.75rem;
		position         : absolute;
		right            : 0;
		top              : 50%;
		transform        : translateY(-50%);
		width            : auto;
		z-index          : 5;

		> * {
			line-height : inherit;
		}
	}
}
