////
/// Components / Search (Volltextsuche!)
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

//
// Standardsuchformular (integriert auf Suchergebnisseite)
//

// .search-form {}

//
// Suchbox (reduziertes Suchformular, Seitenkopf etc.).
//

.search-form.-type-box {
	background-color : color($form-control-bg-color);
	border           : 1px solid color($form-control-border-color);
	padding-right    : 2.75rem;
	position         : relative;

	.search-form__input {
		border : 0 none;
	}

	.search-form__submit {
		background-color : transparent;
		border           : 0;
		display          : block;
		font-size        : 1.25rem;
		line-height      : 1;
		overflow         : hidden;
		padding          : 0.5em;
		position         : absolute;
		right            : 0;
		top              : 50%;
		transform        : translateY(-50%);
		transition       : transform 0.3s;

		> span {
			display : none;
		}

		&::before {
			content     : "\f002"; /* stylelint-disable-line string-quotes */
			font-family : 'Font Awesome 5 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
			font-weight : 300;
		}
	}
}

//
// Suchergebnis
//

.search-results {
	scroll-margin-top : var(--offset-scroll-top);
}
