////
/// Components / Accordion
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'accordion') {
	.accordion {
		$-self : &;

		border    : 1px solid color('black-10');
		font-size : 1rem;

		&__toggle,
		&__panel-content {
			@include margin(0);
			@include padding(get-spacing('s') get-spacing('m'));

			@include media-breakpoint-up('xl') {
				@include padding((get-spacing('m') + get-spacing('s')) get-spacing('l'));
			}
		}

		&__toggle {
			@include font-weight('normal');
			@include font-size('l');

			background-color  : #fff;
			border            : 0 none;
			color             : inherit;
			display           : block;
			position          : relative;
			scroll-margin-top : var(--offset-scroll-top);
			text-align        : left;
			text-transform    : none;
			transition        : all 0.25s;
			width             : 100%;

			&::after {
				@extend %icon-fontawesome;

				color            : color('tertiary');
				content          : "\f107"; /* stylelint-disable-line string-quotes */
				font-size        : 1em;
				font-weight      : 400;
				position         : absolute;
				right            : get-spacing('m');
				top              : 50%;
				transform        : translate3d(0, -50%, 0) rotate(180deg);
				transform-origin : center center;
				transition       : all 0.25s;
			}

			&-title {
				display   : block;
			}

			@include on-hover-focus() {
				color : color('tertiary');
			}

			&[aria-expanded="true"] { /* stylelint-disable-line string-quotes */
				color : color('tertiary');

				&::after {
					color : color('primary');
				}
			}

			&.collapsed {
				&::after {
					transform : translate3d(0, -50%, 0) rotate(0deg);
				}

				// @include on-hover-focus() {}
			}

			@include media-breakpoint-up('xl') {
				&::after {
					right : get-spacing('l');
				}
			}
		}

		&__panel {
			&-content {
				background-color : color('white');
				padding-top      : 0;

				> :first-child {
					margin-top : 0;
				}

				> :last-child {
					margin-bottom : 0;
				}
			}

			+ #{$-self}__toggle {
				border-top : 1px solid color('black-10');
			}
		}
	}
}
