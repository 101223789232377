////
/// Base / Utilities / Animations
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

@keyframes rotate {
	from {
		transform : rotateZ(0deg);
	}

	to {
		transform : rotateZ(360deg);
	}
}
