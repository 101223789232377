////
/// Vendors - Fonts
///
/// @include font-face(
///   '[font-family-name]',
///   '[path-to-directory]',
///   (
///     woff2 : 'file-name.woff2',
///     woff  : 'file-name.woff',
///     eot   : 'file-name.eot',
///     ttf   : 'file-name.ttf'
///   ),
///   [weight],
///   [style],
///   [display]
/// );
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

@import url(../fonts/fontawesome-pro/css/all.css);

//
// Ubuntu
//

// Light
@include font-face(
	'Ubuntu',
	'ubuntu/',
	(
		woff2 : 'ubuntu-light.woff2',
		woff  : 'ubuntu-light.woff'
	),
	300
);

// Regular
@include font-face(
	'Ubuntu',
	'ubuntu/',
	(
		woff2 : 'ubuntu-regular.woff2',
		woff  : 'ubuntu-regular.woff'
	),
	400
);

// Bold
@include font-face(
	'Ubuntu',
	'ubuntu/',
	(
		woff2 : 'ubuntu-bold.woff2',
		woff  : 'ubuntu-bold.woff'
	),
	700
);

//
// Open Sans
//

// Light
@include font-face(
	'Open Sans',
	'open-sans/',
	(
		woff2 : 'open-sans-light.woff2',
		woff  : 'open-sans-light.woff'
	),
	300
);

// Regular
@include font-face(
	'Open Sans',
	'open-sans/',
	(
		woff2 : 'open-sans-regular.woff2',
		woff  : 'open-sans-regular.woff'
	),
	400
);

// Bold
@include font-face(
	'Open Sans',
	'open-sans/',
	(
		woff2 : 'open-sans-bold.woff2',
		woff  : 'open-sans-bold.woff'
	),
	700
);
