////
/// Components / Skip to
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.skip-to {
	$-self : &;

	&,
	&__link {
		clip        : rect(0, 0, 0, 0);
		height      : 1px;
		overflow    : hidden;
		width       : 1px;
	}

	@include font-size('xs');

	left             : 0;
	position         : absolute;
	text-align       : center;
	top              : 0;
	z-index          : 99999;

	&__link {
		@include padding(get-spacing('xxs') get-spacing('m'));

		background-color : color('white');
		border           : 0;
		color            : color('tertiary');
		position         : absolute;

		&:active,
		&:focus {
			background-color : color('tertiary');
			clip             : auto;
			color            : color('white');
			display          : inline-block;
			height           : auto;
			overflow         : visible;
			position         : static;
			width            : auto;
			outline          : none;
		}
	}

	&:focus-within {
		clip        : auto;
		height      : auto;
		overflow    : visible;
		white-space : normal;
		width       : 100%;

		@include media-breakpoint-up('m') {
			left: 50%;
			transform: translateX(-50%);
			width       : auto;
		}
	}
}
