////
/// Functions / Colors
////

/// Farbe holen
///
/// @example
///   $colors : (
///     "primary"   : #cc0000,
///     "secondary" : #00cc00
///   );
///
///   color('primary');
///   = #cc0000
///
///   $map : (
///     "foo" : #ccc,
///     "bar" : #0cc
///   );
///
///   color('bar', $map);
///   // = #0cc
///
///   // mit Referenzen
///   $map : (
///     "foo"   : #ccc,
///     "bar"   : #0cc,
///     "lorem" : '#foo'
///   );
///
///   color('lorem', $map);
///   // = #ccc
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string} $key[null] - Identifier in `$palette`
/// @parameter {map} $palette[$global-colors] - Map mit Farbangaben
/// @require   {function} is-string
/// @require   {function} is-null
/// @return    {color} [transparent]
@function color($key: null, $palette: $global-colors, $depth : 0) {
	$val : inherit;

	@if $depth < 3 {
		@if contains('inherit' 'initial' 'transparent', $key) {
			$val : $key;
		} @else {
			@if not is-null($key) and not is-color($key) {
				$key   : inspect($key);
				$depth : $depth + 1;
				$val   : map-get($palette, $key) or $key;

				@if not is-null($val) {
					@if not is-color($key) {
						// Farbangabe ist ein String, auf Referenz prüfen!
						$val : color($val, $palette, $depth);
					}
				} @else {
					@warn 'Schlüssel `#{$val}` ist in `$palette` nicht vorhanden. `transparent` wurde gesetzt.';

					$val : transparent;
				}
			} @else {
				@if not is-color($key) {
					$val : inherit;
				} @else {
					$val : $key;
				}
			}
		}
	} @else {
		// Abbruch wenn max. Rekursion erreicht ist!
		// @warn 'Maximale Rekursion von `3` erreicht!';

		@if is-null($key) or not is-color($key) {
			$val : inherit;
		} @else {
			$val : $key;
		}
	}

	@return $val;
}

/// Farbabstufungen generieren.
///
/// @example
///   $var : color-graduate('foo', #000, 20);
///   // = (
///   //   'foo-20' : #ccc,
///   //   'foo-40' : #999,
///   //   'foo-60' : #666,
///   //   'foo-80' : #333
///   // )
///
///   // auslesen der Farbangaben
///   $var : map-get($var, 'foo-60');
///   // = #666
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {string} $key - Namensbereich
/// @parameter {string | color} $color - Referenzschlüssel in `$global-colors` oder eine Hex-Farbangabe
/// @parameter {number} $steps - Schrittbereich (Prozente)
/// @require   {function} is-string
/// @return    {map}
@function color-graduate($key, $color : null, $steps : 5) {
	$data : $key;
	$map  : ();
	$max  : 100;

	// Einzelparameterübergabe, dann nachträglich als Map definieren.
	@if is-string($key) {
		$data : (
			'#{$key}' : ($color, $steps)
		);
	}

	// Alle Mapeinträge durchlaufen.
	@each $ident, $params in $data {
		$cur : 0;
		$c   : color(nth($params, 1));
		$s   : nth($params, 2);
		$m   : ();

		// Passen die Schrittangaben?
		@if $s >= 1 and $s < $max {
			// Einzelne Farbabstufung generieren und in Kontext-Map speichern.
			@while $cur <= $max {
				@if $cur >= $s and $cur <= ($max - $s) {
					$lighten : lighten($c, (($max - $cur) * 1%));
					$new     : ('#{$ident}-#{$cur}' : $lighten);
					$m       : map-merge($m, $new);
				}

				// Aktuellen Schritt setzen.
				$cur : $cur + $s;
			}
		} @else {
			@warn '`$steps` muss im Bereich von 1 - 99 liegen. Aktuell `#{$s}`.';
		}

		// Kontext-Map mergen.
		$map : map-merge($map, $m);
	}

	// TODO : Übergabe per Map entwickeln?!
	// $var : color-graduate((
	//   'foo' : ('#black', 5),
	//   'bar' : (#cc0000, 15),
	// ));

	@return $map;
}

/// Map mit Farbangaben durchlaufen und sämtliche Farbreferenzen parsen.
///
/// @example
///   // Übergabe von Einzelparametern
///   $var : get-colors-parsed((
///     'black'     : #000,
///     'white'     : #fff,
///     'text'      : 'black',
///     'primary'   : #cc0000,
///     'secondary' : 'white',
///   ));
///   // = (
///   //   'black'     : #000,
///   //   'white'     : #fff,
///   //   'text'      : #000,
///   //   'primary'   : #cc0000,
///   //   'secondary' : #fff,
///   // );
///
/// @author    [Heiko Pfefferkorn](https://ifabrik.de)
/// @access    public
/// @since     1.0.0
/// @group     helpers
/// @parameter {map} $palette [$global-colors]
/// @require   {function} color
/// @return    {map}
@function get-colors-parsed($palette: $global-colors) {
	$map : ();

	// Alle Mapeinträge durchlaufen.
	@each $key, $val in $palette {
		$val : color($key);
		$map : map-merge($map, ($key : $val));
	}

	@return $map;
}
