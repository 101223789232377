////
/// Components / Form / Input group
///
/// @group Components
/// @author Heiko Pfefferkorn
////

.input-group {
	> label {
		display : none;
	}

	> .form-control-clearable {
		position      : relative;
		flex          : 1 1 0;
		min-width     : 0;
		margin-bottom : 0;
	}
}
