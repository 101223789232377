////
/// Components / Top of page
///
/// @group Components
/// @author Heiko Pfefferkorn
////

@if map-get($components-in-use, 'top-of-page') {
	//
	// Button im Inhaltsbereich
	//

	.top-of-page {
		background-color : transparent;
		color            : color('text');
		display          : block;
		font-size        : 1rem;
		margin           : 1em auto;
		overflow         : hidden;
		padding          : 0;
		position         : relative;
		text-align       : center;
		width            : 100%;

		&:last-child {
			margin-bottom : 0;
		}

		> a {
			background-color : transparent;
			border           : 0 none;
			color            : color('text');
			display          : inline-block;
			font-size        : inherit;
			padding          : 1em;
			position         : relative;
			transition       : all 0.15s;

			span::before {
				@extend %icon-fontawesome;

				content     : "\f077"; /* stylelint-disable-line string-quotes */
				font-weight : 900; // 300, 400, 900
			}

			&::before,
			&::after {
				border-top : 1px solid color('black-10');
				content    : "";
				position   : absolute;
				top        : 50%;
				width      : 100vw;
			}

			&::before {
				margin-right : 1em;
				right        : 100%;
			}

			&::after {
				left        : 100%;
				margin-left : 1em;
			}

			@include on-event() {
				background-color : transparent;
				color            : color('primary');
			}
		}
	}

	//
	// Button der Seite
	//

	.jump-to-top {
		background-color : color('secondary');
		border           : rem(2px) solid rgba(color('white'), 0.1);
		bottom           : get-spacing('m');
		color            : color('white');
		display          : none;
		font-size        : 2.25rem;
		height           : 1em;
		overflow         : hidden;
		position         : fixed;
		right            : get-spacing('m');
		text-decoration  : none;
		transition       : color 0.15s, background-color 0.15s;
		width            : 1em;
		z-index          : 550;

		&::before {
			@extend %icon-fontawesome;

			content     : "\f077"; /* stylelint-disable-line string-quotes */
			font-size   : 0.75rem;
			font-weight : 900; // 300, 400, 900
			left        : 50%;
			position    : absolute;
			text-indent : initial;
			top         : 50%;
			transform   : translate3d(-50%, -50%, 0);
		}

		@include on-event() {
			background-color : color('primary');
			color            : color('white');
		}
	}
}
