////
/// Layout / Header
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

.page-header {
	background-color : color('white');

	> .container {
		align-items     : center;
		display         : flex;
		justify-content : space-between;
	}

	&::after {
		$-border-width : rem(2px);

		border-top : $-border-width solid color('primary');
		bottom     : -#{$-border-width};
		content    : ""; /* stylelint-disable-line string-quotes */
		display    : block;
		left       : 0;
		position   : absolute;
		width      : 100%;
	}

	.page-logo,
	[data-trigger="page-menu"], /* stylelint-disable-line string-quotes */
	[data-trigger="page-search"] { /* stylelint-disable-line string-quotes */
		display    : block;
		transition : transform 0.3s;
	}

	.page-logo {
		font-size : rem(32px);
		order     : 2;

		@include media-breakpoint-up('l') {
			order : 1;
		}

		@include media-breakpoint-up('xxl') {
			font-size : rem(48px);
		}
	}

	[data-trigger] {
		background-color : transparent;
		border           : 0;
		color            : inherit;
		display          : block;
		font-size        : 1.25rem;
		line-height      : 1;
		overflow         : hidden;
		padding          : .5em;
		white-space      : nowrap;

		&::before {
			content     : ""; /* stylelint-disable-line string-quotes */
			font-family : 'Font Awesome 5 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
			font-weight : 300;
		}

		@include on-hover-focus() {
			color : color('tertiary');
		}

		&[aria-expanded="true"] { /* stylelint-disable-line string-quotes */
			color : color($nav-active-color);
		}
	}

	[data-trigger="page-search"] { /* stylelint-disable-line string-quotes */
		order : 3;

		&::before {
			content : "\f002"; /* stylelint-disable-line string-quotes */
		}
	}

	[data-trigger="page-menu"] {
		order : 1;

		&::before {
			content : "\f0c9";
		}

		@include media-breakpoint-up('l') {
			display : none;
		}
	}

	.main-nav {
		display : none;

		@include media-breakpoint-up('l') {
			order   : 2;
			display : block;
			z-index : 2;
		}
	}
}
