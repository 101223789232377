////
/// Components / List
///
/// @group Components
/// @author Heiko Pfefferkorn
////

//
// Standard.
//

ul,
ol,
dl {
	list-style-type : none;
	margin-left     : 0;
	margin-right    : 0;
	padding         : 0;

	ul,
	ol,
	dl {
		@include margin((
			bottom: rem(get-spacing('m')),
			top   : rem(get-spacing('m'))
		));
	}
}

ul,
ol {
	li {
		position : relative;

		+ li {
			margin-top : rem(get-spacing('s'));
		}
	}
}


ul {
	padding-left: 1.5em;

	li {
		&::before {
			background-color : color('primary');
			border-radius    : 100%;
			content          : ""; /* stylelint-disable-line string-quotes */
			height           : rem(4px);
			left             : -1.5em;
			overflow         : hidden;
			position         : absolute;
			top              : 0.6666666667rem;
			width            : rem(4px);
		}
	}
}


ol {
	counter-reset : ordered-list;

	> li {
		padding-left : 2.75em;

		&::before {
			color             : color('primary');
			content           : counters(ordered-list, ".") "\002008"; /* stylelint-disable-line string-quotes */
			counter-increment : ordered-list;
			display           : inline-block;
			left              : 0;
			position          : absolute;
			text-align        : right;
			top               : 0;
			width             : 2em;
		}
	}
}


// ol {
//	counter-reset: step;
//
//	> li {
//		@include padding(left, (get-spacing($list-margin-x, $unit: em) * 1.5));
//
//		&::before {
//			@include font-family('secondary');
//
//			color             : color('primary');
//			content           : counter(step) ""; /* stylelint-disable-line string-quotes */
//			counter-increment : step;
//			font-size         : 0.8125em;
//			top               : 0.25em;
//		}
//
//		> ol > li {
//			@include padding(left, (get-spacing($list-margin-x, $unit: em) * 1.75));
//
//			&::before {
//				content : counters(step, '.') "\002008"; /* stylelint-disable-line string-quotes */
//			}
//		}
//	}
// }



// ul,
// ol,
// dl {
//	@include margin(0 0 get-spacing($list-margin-y, $unit: em));
//	@include padding(0 0 0 get-spacing($list-margin-x, $unit: em));
//
//	& & {
//		@include margin((
//			bottom : get-spacing('s', $unit: em),
//			top    : get-spacing('s', $unit: em)
//		));
//	}
// }

//
// Ungeordnet, Geordnet.
//

// ul,
// ol {
//	list-style   : none;
//	padding-left : 0;
//
//	> li {
//		@include padding(left, get-spacing($list-margin-x, $unit: em));
//
//		position : relative;
//
//		&::before {
//			position : absolute;
//			left     : 0;
//		}
//	}
// }

// ul > li {
//	&::before {
//		@extend %icon-fontawesome;
//
//		color       : color('primary');
//		content     : "\00203A"; /* stylelint-disable-line string-quotes */
//		font-size   : inherit;
//		line-height : 1;
//		top         : 0;
//	}
// }

// ol {
//	counter-reset: step;
//
//	> li {
//		@include padding(left, (get-spacing($list-margin-x, $unit: em) * 1.5));
//
//		&::before {
//			@include font-family('secondary');
//
//			color             : color('primary');
//			content           : counter(step) ""; /* stylelint-disable-line string-quotes */
//			counter-increment : step;
//			font-size         : 0.8125em;
//			top               : 0.25em;
//		}
//
//		> ol > li {
//			@include padding(left, (get-spacing($list-margin-x, $unit: em) * 1.75));
//
//			&::before {
//				content : counters(step, '.') "\002008"; /* stylelint-disable-line string-quotes */
//			}
//		}
//	}
// }

//
// Definitionsliste.
//

dl {
	padding-left : 0;

	dd {
		@include margin(get-spacing('xs', $unit: em) 0 0);

		+ dt {
			@include margin('top', get-spacing('m', $unit: em));
		}
	}

	&.-inline {
		@include media-breakpoint-up('s') {
			@include clearfix(false);

			dt {
				float : left;
				width : 10rem;
			}

			dd {
				margin-left : 10rem;

				+ dt + dd {
					@include margin('top', get-spacing('m', $unit: em));
				}
			}
		}
	}
}

//
// Bullets (unordered list)
//

.list-bullets {
	$-gap : 1.3333333333rem;

	padding-left: $-gap;

	ul {
		margin-top: 1rem;
	}

	li {
		position : relative;

		&::before {
			background-color : color('primary-2');
			border-radius    : 100%;
			content          : ""; /* stylelint-disable-line string-quotes */
			height           : 0.4rem;
			left             : -#{$-gap};
			overflow         : hidden;
			position         : absolute;
			top              : 0.6666666667rem;
			width            : 0.4rem;
		}

		+ li {
			margin-top : 1rem;
		}
	}

	@include media-breakpoint-up('l') {
		$-gap : 1.25rem;

		padding-left: $-gap;

		&::before {
			left             : -#{$-gap};
			top              : 0.625rem;
		}
	}
}

//
// Decimal & Roman (ordered list)
//

.list-decimal,
.list-roman {
	> li {
		padding  : 0;
		position : static;

		&::before {
			color             : color('primary-2');
			content           : counters(ordered-list, ".") "\002008"; /* stylelint-disable-line string-quotes */
			counter-increment : ordered-list;
			display           : block;
			font              : bold 1.8666666666rem/1.25 "Times", serif; /* stylelint-disable-line string-quotes */
			left              : 0;
			position          : static;
			top               : 0;
		}

		+ li {
			margin-top : 2rem;
		}
	}

	ol {
		margin-top : 2rem;

		> li {
			padding-left : 2.6666666666rem;
			position     : relative;

			&::before {
				color             : inherit;
				content           : counters(ordered-list, ".") "\002008"; /* stylelint-disable-line string-quotes */
				counter-increment : ordered-list;
				font              : inherit;
				left              : 0;
				position          : absolute;
				top               : 0;
			}

			+ li {
				margin-top : 2rem;
			}
		}

		li li {
			padding-left : 3.6666666667rem;
		}
	}
}

.list-roman {
	> li::before {
		content : counter(ordered-list, upper-roman);
	}

	ol {
		> li::before {
			color             : inherit;
			content           : counters(ordered-list, ".") "\002008"; /* stylelint-disable-line string-quotes */
			counter-increment : ordered-list;
			font              : inherit;
			left              : 0;
			position          : absolute;
			top               : 0;
		}
	}
}

//
// `<ul/>` Tagliste
//

.list-tags {
	margin-top : -#{get-spacing('s')};

	> li {
		@include margin(get-spacing('s') get-spacing('m') get-spacing('s') 0);

		display : inline-block;
	}

	@at-root .aside & {
		+ * {
			margin-top : get-spacing('l');
		}
	}
}

//
// `<ul/>` Linkliste
//

.list-links {
	li {
		padding-left : 0;
		overflow     : hidden;

		&:last-child {
			margin-bottom : 0;
		}

		&::before {
			display : none;
		}

		+ li {
			margin-top : 0.875rem;
		}
	}
}

//
// `<ul/>`, `<dl/>` Liste mit Rahmen links und weißem Hintergrund
//
// Liste mit Rahmen links und weißem Hintergrund
// Die Modifier Klasse `-at-a-glance` bewirkt nur bei einer Definitionsliste die
// Erhöhung der Schriftgröße des `<dd/>`.
//
// @example html - Definition list
//   <dl class="list-data">
//     <dt>Lorem</dt>
//     <dd>Ipsum dolor sit amed</dd>
//     <dt>Lorem</dt>
//     <dd>Ipsum dolor sit amed</dd>
//   </dl>
//
// @example html - Unordered list
//   <ul class="list-data">
//     <li>
//       <p>Lorem</p>
//       <p class="strong>Ipsum dolor sit amed</p>
//     </li>
//     <li>
//       <p>Lorem</p>
//       <p class="strong>Ipsum dolor sit amed</p>
//     </li>
//   </ul>
//

.list-data {
	> * {
		@include margin(0);
		@include padding(0.8rem 1.6666666667rem);

		background-color : color('white');
		border-left      : 4px solid color('primary-2');
		position         : relative;

		> *:first-child {
			margin-top : 0;
		}

		> *:last-child {
			margin-bottom : 0;
		}

		ul li {
			display : inline;

			+ li::before {
				content : ",\002008"; /* stylelint-disable-line string-quotes */
			}
		}

		@include media-breakpoint-up('l') {
			@include padding((get-spacing('m') + get-spacing('s')) (get-spacing('l') + get-spacing('m')));
		}
	}

	> li + li,
	> dd + dt {
		margin-top : 2px;
	}

	> dd {
		@include font-weight('bold');
	}

	> dt {
		@include font-weight('normal');

		+ dd {
			padding-top : 0;
		}
	}

	// Nur wenn `<dl/>`
	&.-at-a-glance dd {
		font-size: 1.2rem;

		@include media-breakpoint-up('l') {
			font-size: 1.375rem;
		}
	}
}
