////
/// Placeholders / Icon
////

%icon-fontawesome {
	-moz-osx-font-smoothing : grayscale;
	-webkit-font-smoothing  : antialiased;
	display                 : inline-block;
	font-family             : 'Font Awesome 5 Pro'; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
	font-style              : normal;
	font-variant            : normal;
	text-rendering          : auto;
}
