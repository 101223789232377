////
/// Base / Utilities / Aspect Ratio
///
/// @author [Heiko Pfefferkorn](https://ifabrik.de)
////

[data-aspect-ratio]:not([data-aspect-ratio='']) {
	display  : block;
	overflow : hidden;
	padding  : 0;
	position : relative;
	width    : 100%;

	&::before {
		content : ""; /* stylelint-disable-line string-quotes */
		display : block;
	}

	> * {
		border   : 0;
		height   : auto;
		left     : 0;
		position : absolute;
		right    : 0;
		top      : 0;
		width    : 100%;
	}

	> img {
		height : auto;
		// Bild immer in komplett sichtbaren Dimensionen im Container zentriert.
		// left       : 50%;
		// max-height : 100%;
		// max-width  : 100%;
		// top        : 50%;
		// transform  : translate3d(-50%, -50%, 0);
		// width      : auto;
	}

	> video,
	> iframe,
	> div {
		height : 100%;
	}
}

@each $map in $aspect-ratio {
	$x : nth($map, 1);
	$y : nth($map, 2);

	[data-aspect-ratio='#{$x}by#{$y}'] {
		&::before {
			padding-top : aspect-ratio($x, $y);
		}
	}
}
